import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import ls from "local-storage";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";

import backImage from "../icons/akar-icons_arrow-back-thick-fill.svg";
import heightIcon from "../icons/height_icon.svg";
import weightIcon from "../icons/weight_icon.svg";
import genderIcon from "../icons/gender_icon.svg";
import birthdayIcon from "../icons/birthday_icon.svg";
import calendarImage from "../icons/patient_calendar.svg";
import pushImage from "../icons/send_push.svg";
import zmImage from "../../../assets/images/badges/zmdi_time-restore.svg";

import leftChevronImage from "../icons/left_chevron.svg";

import CommonHelper from "../../../helpers/CommonHelper";
import PatientDayAnalyticsPanel from "./PatientDayAnalyticsPanel";
import NiceModal from "../../modals/NiceModal";
import PushNotificationForm from "../forms/PushNotificationForm";
import VitalParametersSettingsPanel from "../../vital_parameters/panels/VitalParametersSettingsPanel";
import archiveImg from "../../../assets/images/majesticons_archive.svg";
import aiImg from "../../../assets/images/ai-microchip.svg";
import { getLabelsInfoData } from "../../groups/panels/GroupsPanel";
import OptimizedDoctorExportPanel from "../../export/panels/OptimizedDoctorExportPanel";
import PatientComplianceTool from "../tools/PatientComplianceTool";
import { isDebugMode } from "../../../helpers/UiHelper";
import AiAssisstantTool from "../tools/AiAssisstantTool";
import xImage from "../../../assets/images/x.svg";

import infoImage from "./info.svg";
import PatientComplianceTableTool from "../tools/PatientComplianceTableTool";
import InfoToolBadge from "../../info/tools/InfoToolBadge";
import {
  appVersionCheck,
  fwVersionCheck,
} from "../../dashboard/tools/VersionSpan";
import { generateAgoTime } from "../../dashboard/tools/AgoSpan";

const checkExportRanges = (newRanges) => {
  let { from, to } = newRanges;
  let isSpanNegative = +to - +from < 0;
  let isTooBigSpan = +to - +from > 7 * 86400000;
  if (isSpanNegative) {
    return 'Date "To" must be later than date "From"';
  }
  if (isTooBigSpan) {
    return "Please select range less than 7 days.";
  }
};

export const isWiffMode = () => {
  try {
    return window.location.href.indexOf("wiff") > -1;
  } catch (exc) {}
  return false;
};

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function renderNotSpecified(name) {
  return (
    <span>
      <span style={{ fontSize: "12px", lineHeight: "12px" }}>
        <span>{name}</span>
        <br />
        <span>{"Not specified"}</span>
      </span>
    </span>
  );
}

export default function PatientPanel(props) {
  const {
    id,
    tag,
    isHCPAdmin,
    hasBackButton = true,
    hasSendPushButton = false,
    isEmbedMode = false,
  } = props;
  let isDemo = id != undefined && id.indexOf("demo") > -1;

  const [complianceModalVisible, setComplianceModalVisible] = useState(false);

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [aiAssistantVisible, setAiAssistantVisible] = useState(false);
  const [appAndDeviceInfo, setAppAndDeviceInfo] = useState(undefined);

  const [bracelet, setBracelet] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState(undefined);
  const [statisticsSelectedDayTimestamp, setStatisticsSelectedDayTimestamp] =
    useState(+moment());

  const [pushModalOpen, setPushModalOpen] = useState(false);
  const [vitalModalOpen, setVitalModalOpen] = useState(false);

  const [exportRanges, setExportRanges] = useState({
    from: +moment().startOf("day"),
    to: +moment().endOf("day"),
  });
  // console.log("render: exportRanges = ", exportRanges);
  const theme = ls.get("theme");
  const [monthComplianceDataMap, setMonthComplianceDataMap] = useState({});
  const [complianceCurrentMonthTimestamp, setComplianceCurrentMonthTimestamp] =
    useState(+moment().startOf("month").add(2, "days"));

  const [statisticsCalendarOpen, setStatisticsCalendarOpen] = useState(false);

  const [latestData, setLatestData] = useState(undefined);
  const [showComplianceInfo, setShowComplianceInfo] = useState(false);

  const [needUpdate, setNeedUpdate] = useState(false);
  const [showNeedUpdate, setShowNeedUpdate] = useState(true);
  const [updateMessage, setUpdateMessage] = useState("");
  const [officialVersions, setOfficialVersions] = useState({});

  const [externalDeviceMeasurements, setExternalDeviceMeasurements] = useState({
    pulse_rate: [],
    respiration_rate: [],
    spo2: [],
    temperature: [],
    temperature_spots: [],
    blood_pressure: [],
  });

  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    if (!appAndDeviceInfo) {
      return;
    }
    setUpdateMessage("");
    DoctorAPI.getNewestVersions()
      .then((officialLatestVersions) => {
        setOfficialVersions(officialLatestVersions);
        // console.log("newversions", newVersions);
        // console.log("patient", patient);
        const { appInfo = {}, deviceInfo = {} } = appAndDeviceInfo;
        console.log(appInfo);
        // console.log(deviceInfo);
        const { os_name = "", app_version = "" } = appInfo; // from user
        const { firmware_version = "" } = deviceInfo; // from user

        let isNewestApp = appVersionCheck(
          os_name,
          app_version,
          officialLatestVersions
        );
        let isNewestFw = fwVersionCheck(
          os_name,
          app_version,
          firmware_version,
          officialLatestVersions
        );

        if (!isNewestApp || !isNewestFw) {
          setNeedUpdate(true);
        }

        let region = "";
        const bigVersion = +app_version[0];
        if (bigVersion >= 7) {
          region = "US";
        } else {
          region = "EU";
        }
        console.log(
          generateUpdateMessage("both", os_name, region, officialLatestVersions)
        );

        if (!isNewestFw && !isNewestApp) {
          setUpdateMessage(
            generateUpdateMessage(
              "both",
              os_name,
              region,
              officialLatestVersions
            )
          );
          // setUpdateMessage(updateMessageMap["both"]);
        } else if (!isNewestFw && isNewestApp) {
          setUpdateMessage(
            generateUpdateMessage(
              "bracelet",
              os_name,
              region,
              officialLatestVersions
            )
          );
          // setUpdateMessage(updateMessageMap["bracelet"]);
        } else if (isNewestFw && !isNewestApp) {
          setUpdateMessage(
            generateUpdateMessage(
              "app",
              os_name,
              region,
              officialLatestVersions
            )
          );
          // setUpdateMessage(updateMessageMap["app"]);
        } else if (isNewestApp && isNewestApp) {
          setUpdateMessage("");
        } else {
          setUpdateMessage("");
        }

        console.log({ isNewestApp, isNewestFw });
      })
      .catch((e) => console.error(e));
  }, [tag, appAndDeviceInfo]);

  useEffect(() => {
    if (isEmbedMode == true) {
      return;
    }
    setExportRanges({
      from: +moment(+statisticsSelectedDayTimestamp).startOf("day"),
      to: +moment(+statisticsSelectedDayTimestamp).endOf("day"),
    });
  }, [statisticsSelectedDayTimestamp]);

  useEffect(() => {
    if (!id) {
      return;
    }
    DoctorAPI.getExternalPulseRate(id).then((d) => {
      setExternalDeviceMeasurements((measurements) => {
        return { ...measurements, pulse_rate: d };
      });
    });
    DoctorAPI.getExternalSpO2(id).then((d) => {
      setExternalDeviceMeasurements((measurements) => {
        return { ...measurements, spo2: d };
      });
    });
    DoctorAPI.getExternalRespirationRate(id).then((d) => {
      setExternalDeviceMeasurements((measurements) => {
        return { ...measurements, respiration_rate: d };
      });
    });
    DoctorAPI.getExternalTemperature(id).then((d) => {
      setExternalDeviceMeasurements((measurements) => {
        return { ...measurements, temperature: d };
      });
    });
    DoctorAPI.getExternalBloodPressure(id).then((d) => {
      setExternalDeviceMeasurements((measurements) => {
        return { ...measurements, blood_pressure: d };
      });
    });

    DoctorAPI.getTempereatureSpots(id, "2024-01-01", "2025-12-31").then((d) => {
      // console.log(d);
      setExternalDeviceMeasurements((measurements) => {
        return { ...measurements, temperature_spots: d };
      });
      // setTemperatureSpot(d);
    });
  }, [id]);

  useEffect(() => {
    const startTime = performance.now();
    // setLoading(true);
    setLatestData(undefined);
    // DoctorAPI.getPatientBracelet(id)
    //   .then((br) => {
    //     setLoading(false);
    //     const endTime = performance.now();
    //     console.debug(`PatientPanel loaded in ${endTime - startTime} ms`);
    //     setBracelet(br);
    //   })
    //   .catch((e) => console.error(e));

    DoctorAPI.getPatientLatestRawValues(id)
      .then((x) => {
        setLatestData(x);
      })
      .catch((e) => console.error(e));

    DoctorAPI.getPatientDeviceAndAppInfo(id)
      .then((x) => {
        setAppAndDeviceInfo(x);
      })
      .catch((e) => console.error(e));

    DoctorAPI.getPatient(id)
      .then((x) => {
        // console.log("DoctorAPI.getPatient(id).then", x);
        setPatient(x);
        // let {isDemo = false} = x;
        let isDemo = x?.isDemo || false;
        DoctorAPI.getPatientDevice(id)
          .then((d) => {
            // console.log("device = ", d);
            if (isDemo == true) {
              setComplianceCurrentMonthTimestamp(1623736746000);
            }
          })
          .catch((exc) => console.log(exc));
      })
      .catch((e) => console.error(e));
  }, [id]);

  useEffect(() => {
    if (id == undefined) {
      return;
    }
    let month_start_date = moment(complianceCurrentMonthTimestamp)
      .startOf("month")
      .format("YYYY-MM-DD");
    let month_end_date = moment(complianceCurrentMonthTimestamp)
      .endOf("month")
      .format("YYYY-MM-DD");

    const startTime = performance.now();
    setLoading(true);
    setMonthComplianceDataMap({});
    DoctorAPI.getUserCompliance(id, month_start_date, month_end_date)
      .then((m) => {
        setLoading(false);
        const endTime = performance.now();
        console.debug(
          `Compliance loaded in ${(endTime - startTime).toFixed(1)} ms`
        );

        let firstKey =
          m == undefined || Object.keys(m).length == 0
            ? undefined
            : Object.keys(m)[0];
        if (
          firstKey != undefined &&
          +moment(firstKey).startOf("month") ==
            +moment(complianceCurrentMonthTimestamp).startOf("month")
        ) {
          setMonthComplianceDataMap(m);
        }
      })
      .catch((exc) => console.error(exc));
  }, [id, complianceCurrentMonthTimestamp]);

  useEffect(() => {
    let latestDataT = latestData?.raw_data_freshest_timestamp;
    const update = () => {
      const timeAgoString =
        latestDataT === undefined ? "..." : generateAgoTime(latestDataT);
      // const time = new Date()
      setTimeAgo(timeAgoString);
    };
    update();
    const interval = setInterval(update, 1000);
    return () => clearInterval(interval);
  }, [id, patient, latestData]);

  if (loading == true && patient == undefined) {
    return (
      <div>
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  if (patient == undefined) {
    return null;
  }

  const isPortalCardioMood =
    window.location.href.indexOf("portal.cardiomood") > -1;

  let { first_name, last_name, birthday, healthUser } = patient;
  let name = `${first_name == undefined ? "-" : first_name} ${
    last_name == undefined ? "-" : last_name
  }`;
  let weight = healthUser == undefined ? undefined : healthUser.weight;
  let height = healthUser == undefined ? undefined : healthUser.height;
  let gender = healthUser == undefined ? undefined : healthUser.gender;
  let age = undefined;
  if (name == "- -") {
    let { code, email } = patient;
    if (code != undefined) {
      name = code;
    }
    // if (inmate?.name != undefined) {
    //     name = `${name} [${inmate?.name}]`;
    // }
    // console.log("- -", patient);
  }

  if (birthday != undefined && birthday != "") {
    try {
      age = getAge(birthday);
    } catch (e) {}
  }

  // let realName = (id == 'c1ab080b-058a-4ada-958c-b816468ba2ee') ? '' : name;

  // console.log("render: exportRanges = ", exportRanges);
  // console.log("render: latestData = ", latestData);
  // let latestDataT = latestData?.raw_activities?.bpm_timestamp;
  let latestDataT = latestData?.raw_data_freshest_timestamp;

  if (patient?.is_access_denied == true) {
    return (
      <Wrapper>
        <TopPlaceholder>{"Access denied"}</TopPlaceholder>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {needUpdate && showNeedUpdate && (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <ShouldUpdateBox style={{ color: "black", position: "relative" }}>
            {/* <img src={warning_img} style={{ height: 32, marginRight: 15 }} /> */}
            <span>{updateMessage}</span>

            <div style={{ position: "absolute", top: 0, right: 10 }}>
              <img
                src={xImage}
                onClick={() => {
                  setShowNeedUpdate(false);
                }}
              />
            </div>
          </ShouldUpdateBox>
        </div>
      )}
      {isEmbedMode == true ? null : (
        <TopPlaceholder>
          <TopLeftPlaceholder>
            {hasBackButton == false ? null : (
              <BackSquare
                onClick={() => {
                  if (tag == undefined) {
                    CommonHelper.linkTo("/");
                  } else {
                    // if ()
                    const onStudy =
                      window.location.href.includes("study.corsano.");
                    const onPortal =
                      window.location.href.includes("portal.corsano.");

                    if (onPortal) {
                      CommonHelper.linkTo(`/departments/${tag}`);
                    } else if (onStudy) {
                      CommonHelper.linkTo(`/studies/${tag}`);
                    } else {
                      CommonHelper.linkTo(`/studies/${tag}`);
                    }
                  }
                }}
              >
                <BackImage src={backImage} />
              </BackSquare>
            )}
            <PatientName className="patient-name">
              {/*{name}*/}
              {CommonHelper.getPatientHeadingString(patient)}
            </PatientName>
          </TopLeftPlaceholder>
          <TopRightPlaceholder>
            {hasSendPushButton == false ? null : (
              <CGroup>
                <Square
                  onClick={() => {
                    setPushModalOpen(true);
                  }}
                >
                  <CImg src={pushImage} />
                </Square>
              </CGroup>
            )}

            {/*{isCardioMoodMode() == true ? null :*/}
            {/* <CGroup>
                   <Square onClick={() => {
                        setVitalModalOpen(true);
                    }}>
                        <CImg src={vitalSettings}/>
                    </Square>
                </CGroup>  */}
            {/*}*/}

            {isDebugMode() == false ||
            window.location.href.indexOf(".corsano.com") > 0 ? null : (
              <CGroup
                style={{ display: "none" }}
                onClick={() => {
                  setAiAssistantVisible(true);
                }}
              >
                <Square>
                  <CImg src={aiImg} />
                </Square>
                <Label className="chatgpt-label">ChatGPT Assistant</Label>
              </CGroup>
            )}

            {getLabelsInfoData().export_enabled == false ? null : (
              <CGroup
                onClick={() => {
                  setExportModalVisible(true);
                }}
              >
                <Square>
                  <CImg src={archiveImg} />
                </Square>
                <Label className="export-label">Export</Label>
              </CGroup>
            )}

            {getLabelsInfoData().live_enabled == false ? null : (
              <CGroup
                onClick={() => {
                  window.open(`https://live.cardiomood.com/u/${id}`, "_blank");
                }}
              >
                <Label>Live</Label>
              </CGroup>
            )}

            {/* {isHCPAdmin ? ( */}
            {true ? (
              <EditButton
                className="user-edit-button"
                onClick={() => {
                  // setUpdateProfileModalOpen(true);
                  CommonHelper.linkTo(`/user/${id}/edit`);
                }}
              >
                EDIT PROFILE
              </EditButton>
            ) : null}
          </TopRightPlaceholder>
        </TopPlaceholder>
      )}

      {isEmbedMode == true ? null : (
        <InfoPlaceholder className="info-placeholder">
          <InfoTopRow>
            <InfoBadge>
              <InfoItemIcon
                src={zmImage}
                style={{ opacity: 0.99 }}
                className="patient-info-icon"
              />
              <InfoLabel style={{ fontSize: "14px" }}>
                {latestDataT == undefined ? (
                  "..."
                ) : (
                  <div>
                    <div
                      className="info-date"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {moment(latestDataT).format("HH:mm:ss, MMM D")}
                    </div>
                    <div
                      className="info-date"
                      style={{
                        fontSize: "12px",
                        color:
                          +new Date() - +latestDataT > 2 * 3600000
                            ? "red"
                            : theme === "dark"
                            ? "white"
                            : "black",
                      }}
                    >
                      {timeAgo}
                      {/* {latestDataT == undefined
                        ? "..."
                        : // : timeAgo.format(latestDataT)}
                          generateAgoTime(latestDataT)} */}
                    </div>
                  </div>
                )}
              </InfoLabel>
            </InfoBadge>

            <InfoBadge>
              <InfoItemIcon src={birthdayIcon} className="patient-info-icon" />
              <InfoLabel className="info-birth">
                {birthday == undefined || birthday == ""
                  ? renderNotSpecified("Birth Year")
                  : `${birthday}`.split("-")[0] +
                    (age == undefined ? "" : ` (${age} y.o.)`)}
              </InfoLabel>
            </InfoBadge>

            <InfoBadge>
              <InfoItemIcon src={weightIcon} className="patient-info-icon" />
              <InfoLabel className="info-weight">
                {weight == undefined || weight == ""
                  ? renderNotSpecified("Weight")
                  : `${weight} kg`}
              </InfoLabel>
            </InfoBadge>

            <InfoBadge>
              <InfoItemIcon src={heightIcon} className="patient-info-icon" />
              <InfoLabel className="info-height">
                {height == undefined || height == ""
                  ? renderNotSpecified("Height")
                  : `${height} cm`}
              </InfoLabel>
            </InfoBadge>

            <InfoBadge style={{ flex: "none" }}>
              <InfoItemIcon src={genderIcon} className="patient-info-icon" />
              <InfoLabel className="info-gender">
                {gender == undefined || gender == ""
                  ? renderNotSpecified("Gender")
                  : gender}
              </InfoLabel>
            </InfoBadge>
          </InfoTopRow>
        </InfoPlaceholder>
      )}

      <CompliancePlaceholder>
        <ComplianceTopSection>
          <ComplianceHeading className="compliance-heading">
            Compliance
            <ChevronImage
              className="patient-info-icon"
              style={{ marginLeft: 10, height: 16, opacity: 0.7 }}
              src={infoImage}
              onClick={() => {
                setComplianceModalVisible(true);
              }}
            />
          </ComplianceHeading>
          <ComplianceMonthPlaceholder>
            <ChevronImage
              className="chevron-icon"
              src={leftChevronImage}
              onClick={() => {
                setComplianceCurrentMonthTimestamp(
                  +moment(complianceCurrentMonthTimestamp)
                    .add(-1, "months")
                    .startOf("month")
                    .add(2, "days")
                );
              }}
            />
            <MonthSpan>
              {moment(complianceCurrentMonthTimestamp).format("MMMM YYYY")}
            </MonthSpan>
            <ChevronImage
              className="chevron-icon"
              src={leftChevronImage}
              style={{ transform: `rotate(180deg)` }}
              onClick={() => {
                setComplianceCurrentMonthTimestamp(
                  +moment(complianceCurrentMonthTimestamp)
                    .add(1, "months")
                    .startOf("month")
                    .add(2, "days")
                );
              }}
            />
          </ComplianceMonthPlaceholder>
        </ComplianceTopSection>

        {/*<PatientCompliancePanel id={id}*/}
        {/*                        isDemo={isDemo}*/}
        {/*                        monthTimestamp={complianceCurrentMonthTimestamp}*/}
        {/*/>*/}

        {/*<PatientOptimizedCompliancePanel id={id}*/}
        {/*                                 isDemo={isDemo}*/}
        {/*                                 monthTimestamp={complianceCurrentMonthTimestamp}*/}
        {/*                                 onDateClick={t => {*/}
        {/*                                     setStatisticsSelectedDayTimestamp(t);*/}
        {/*                                 }}*/}
        {/*/>*/}

        <PatientComplianceTool
          selectedTimestamp={statisticsSelectedDayTimestamp}
          date_from={moment(complianceCurrentMonthTimestamp)
            .startOf("month")
            .format("YYYY-MM-DD")}
          date_to={moment(complianceCurrentMonthTimestamp)
            .startOf("month")
            .format("YYYY-MM-DD")}
          loading={loading}
          dataMap={monthComplianceDataMap}
          onDateClick={(t) => {
            setStatisticsSelectedDayTimestamp(t);
            // setMode('day');
          }}
        />
      </CompliancePlaceholder>

      <StatisticsPlaceholder>
        <ComplianceTopSection>
          <ComplianceHeading className="statistics-heading">
            {window.location.href.indexOf("af.") > -1
              ? "Atrial Fibrillation"
              : "Statistics"}
          </ComplianceHeading>
          <ComplianceMonthPlaceholder>
            <ChevronImage
              className="chevron-icon"
              src={leftChevronImage}
              onClick={() => {
                setStatisticsSelectedDayTimestamp(
                  +moment(statisticsSelectedDayTimestamp)
                    .add(-1, "days")
                    .startOf("day")
                    .add(12, "hours")
                );
              }}
            />
            <MonthSpan
              style={{
                width: 190,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="calender-image"
                src={calendarImage}
                style={{ height: 25, marginRight: 15 }}
                onClick={() => {
                  setStatisticsCalendarOpen(true);
                }}
              />
              <span>
                {moment(statisticsSelectedDayTimestamp).format("MMMM D")}
              </span>
            </MonthSpan>
            <ChevronImage
              src={leftChevronImage}
              className="chevron-icon"
              style={{ transform: `rotate(180deg)` }}
              onClick={() => {
                setStatisticsSelectedDayTimestamp(
                  +moment(statisticsSelectedDayTimestamp)
                    .add(1, "days")
                    .startOf("day")
                    .add(12, "hours")
                );
              }}
            />
          </ComplianceMonthPlaceholder>
        </ComplianceTopSection>
      </StatisticsPlaceholder>

      <StatisticsContentPlaceholder>
        <PatientDayAnalyticsPanel
          appAndDeviceInfo={appAndDeviceInfo}
          // hasSleepTab={bracelet == undefined || (`${window.location.hash}`.indexOf('50907d67-1575-4599-8693-80b14e68b57b') > -1)}
          hasSleepTab={window.location.href.indexOf("portal") == -1}
          id={id}
          isDemo={isDemo}
          patientName={name}
          date={moment(statisticsSelectedDayTimestamp).format("YYYY-MM-DD")}
          user={patient}
          isEmbedMode={isEmbedMode}
          externalDeviceMeasurements={externalDeviceMeasurements}
          onDaySelect={(t) => {
            setExportModalVisible(t);
          }}
        />
      </StatisticsContentPlaceholder>

      {pushModalOpen == false ? null : (
        <NiceModal
          onClose={() => {
            setPushModalOpen(false);
          }}
        >
          <DialogInner2>
            <ComplianceHeading
              style={{ textAlign: "center", display: "block" }}
            >
              Send push notification
            </ComplianceHeading>

            <PushNotificationForm
              onSave={async (d) => {
                if (isDemo == true) {
                  return window.alert(
                    "This is a demo portal. For full access please contact admin."
                  );
                }
                await DoctorAPI.sendPush(id, d.title, d.body);
                setPushModalOpen(false);
              }}
            />

            {/*<button onClick={async () => {*/}
            {/*    await DoctorAPI.sendPush(id, 'test', 'message sfsdfsf');*/}
            {/*}}>*/}
            {/*    send test*/}
            {/*</button>*/}
          </DialogInner2>
        </NiceModal>
      )}

      {vitalModalOpen == false ? null : (
        <NiceModal
          onClose={() => {
            setVitalModalOpen(false);
          }}
        >
          <VitalModalInner>
            {/*<ComplianceHeading style={{textAlign: 'center', display: 'block'}}>*/}
            {/*    Vital parameters*/}
            {/*</ComplianceHeading>*/}

            <VitalParametersSettingsPanel
              userId={id}
              onSaved={() => {
                setVitalModalOpen(false);
              }}
            />
          </VitalModalInner>
        </NiceModal>
      )}

      {exportModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setExportModalVisible(false);
          }}
        >
          <ExportModalPlaceholder>
            <ComplianceHeading
              style={{
                textAlign: "center",
                display: "block",
                marginBottom: 5,
              }}
            >
              {/*{`Export data`}*/}
              {/*<br/>*/}
              {`${name}`}
            </ComplianceHeading>

            {/*<DoctorExportPanel*/}
            <OptimizedDoctorExportPanel
              isDemo={isDemo}
              patients={[patient]}
              {...exportRanges}
              // exportTimestamp={statisticsSelectedDayTimestamp}
              exportName={`${moment(statisticsSelectedDayTimestamp).format(
                "DD-MM-YYYY"
              )}-${name}.zip`}
              externalDeviceMeasurements={externalDeviceMeasurements}
            />

            {/*<VitalParametersSettingsPanel*/}
            {/*    userId={id}*/}
            {/*    onSaved={() => {*/}
            {/*        setVitalModalOpen(false);*/}
            {/*    }}*/}
            {/*/>*/}
          </ExportModalPlaceholder>
        </NiceModal>
      )}

      {aiAssistantVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setAiAssistantVisible(false);
          }}
        >
          <ExportModalPlaceholder>
            <ComplianceHeading
              style={{
                textAlign: "center",
                display: "block",
                marginBottom: 5,
              }}
            >
              {`AI Assistant`}
            </ComplianceHeading>

            <AiAssisstantTool uuid={id} />
          </ExportModalPlaceholder>
        </NiceModal>
      )}

      {complianceModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setComplianceModalVisible(false);
          }}
        >
          <ExportModalPlaceholder className="modal-placeholder">
            <ComplianceHeading
              style={{
                textAlign: "center",
                display: "block",
                marginBottom: 5,
              }}
              className="heading"
            >
              {`Raw Data Compliance`}
            </ComplianceHeading>

            <PatientComplianceTableTool
              uuid={id}
              date={moment(statisticsSelectedDayTimestamp).format("YYYY-MM-DD")}
            />
          </ExportModalPlaceholder>
          {/* <IconPlaceholder
                        onClick={() => {
                            setShowComplianceInfo(!showComplianceInfo);
                        }}
                    >
                        <Box>
                            <Icon src={infoImg} className="bp-info-icon" />
                        </Box>
                        <InfoSpan className="bp-info-span">Info</InfoSpan>
                    </IconPlaceholder>
                    {showComplianceInfo ? (
                        <div
                            className="normal-text"
                            style={{ maxWidth: "480px" }}
                        >
                            {complianceInfoDoc}
                        </div>
                    ) : null} */}
        </NiceModal>
      )}
    </Wrapper>
  );
}

const ExportModalPlaceholder = styled.div`
  width: 480px;
`;

const DialogInner2 = styled.div`
  width: 420px;
`;

const VitalModalInner = styled.div`
  width: 840px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
`;

const Square = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin-right: 11px;
`;

const CGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;
`;

const CImg = styled.img`
  width: 24px;
  height: 24px;
`;

const Label = styled.div`
  color: #8798ad;
  font-size: 18px;
  line-height: 23px;
`;

const ExportLabel = styled.div`
  color: black;
  font-size: 14px;
  line-height: 23px;
  font-weight: bold;
`;

const InnerPlaceholder = styled.div`
  width: 620px;
  padding: 20px;
  background: white;
`;

const NotesPlaceholder = styled.div`
  margin-top: 40px;
`;

export const EditButton = styled.div`
  background: #147aff;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const MonthSpan = styled.span`
  margin-left: 28px;
  margin-right: 28px;
  font-weight: bold;
  width: 150px;
  text-align: center;
`;

const StatisticsContentPlaceholder = styled.div``;

const StatisticsPlaceholder = styled.div`
  margin-top: 40px;
`;

const ChevronImage = styled.img`
  height: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const ComplianceHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
`;

const CompliancePlaceholder = styled.div`
  margin-top: 50px;
`;

const ComplianceTopSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ComplianceMonthPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8798ad;
  font-size: 18px;
  line-height: 24px;
`;

const InfoBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const InfoLabel = styled.div`
  color: #000f4b;
  font-size: 18px;
  line-height: 18px;
  @media (max-width: 920px) {
    font-size: 14px;
  }
  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

const InfoItemIcon = styled.img`
  margin-right: 17px;
  height: 25px;
  opacity: 0.5;
  @media (max-width: 920px) {
    margin-right: 4px;
    height: 17px;
    padding-right: 4px;
  }
`;

const Wrapper = styled.div``;

const BackSquare = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  width: 40px;
  height: 40px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const PatientName = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
`;

const BackImage = styled.img`
  height: 22px;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const InfoTopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InfoPlaceholder = styled.div`
  padding: 20px;
  background: white;
  margin-top: 25px;
  @media (max-width: 880px) {
    padding: 15px;
  }
`;

const IconPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
const InfoSpan = styled.div`
  color: rgb(20, 122, 255);
  cursor: pointer;
  font-weight: 300;
  margin-right: 15px;
`;

const Box = styled.div`
  border: 1px solid rgba(135, 152, 173, 0.1);
  -webkit-filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const complianceInfoDoc = `Datapoints received for the last day.`;

const ShouldUpdateBox = styled.div`
  border-radius: 6px;
  border: 2px solid #ff0900;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
  margin-bottom: 10px;
`;

const updateMessageMap = {
  app: "Please ask patient to update the App.",
  bracelet: "Please ask patient to update the Firmware.",
  both: "Please ask patient to update the App & Firmware.",
};

const generateUpdateMessage = (
  typeNeedsUpdate,
  os_name,
  region,
  officialLatestVersions
) => {
  // const
  // const part1 = "Latest"
  if (typeNeedsUpdate.length === 0) {
    return "";
  }
  let part1 = "";

  if (typeNeedsUpdate === "both") {
    if (os_name.includes("ios")) {
      part1 = `The latest Corsano App version is ${
        region === "EU"
          ? officialLatestVersions["ios_app_version"]
          : officialLatestVersions["ios_verify_version"]
      }, and the latest Corsano firmware version is ${
        region === "EU"
          ? officialLatestVersions["fw_version"]
          : officialLatestVersions["ios_verify_fw_version"]
      } for iOS.  
      `;
    } else if (os_name.includes("andr")) {
      part1 = `The latest Corsano App version is ${officialLatestVersions["android_app_version"]} and the latest firmware version is ${officialLatestVersions["fw_version"]} for Android. `;
    } else {
    }
  }
  if (typeNeedsUpdate === "bracelet") {
    if (os_name.includes("ios")) {
      part1 = `The latest Corsano firmware version is ${
        region === "EU"
          ? officialLatestVersions["fw_version"]
          : officialLatestVersions["ios_verify_fw_version"]
      } for iOS. `;
    } else if (os_name.includes("andr")) {
      part1 = `The latest Corsano firmware version is ${officialLatestVersions["fw_version"]} for Android. `;
    } else {
    }
  }
  if (typeNeedsUpdate === "app") {
    if (os_name.includes("ios")) {
      part1 = `The latest Corsano App version is ${
        region === "EU"
          ? officialLatestVersions["ios_app_version"]
          : officialLatestVersions["ios_verify_version"]
      } iOS. `;
    } else if (os_name.includes("andr")) {
      part1 = `The latest Corsano App version is ${officialLatestVersions["android_app_version"]} Android. `;
    } else {
    }
  }
  const part2 = updateMessageMap[typeNeedsUpdate];
  return `${part1}${part2}`;
};

// const generateAgoTime = (agoTimestamp) => {
//   let agoString =
//     agoTimestamp == undefined ? "" : moment(agoTimestamp).fromNow();
//   let dt = agoTimestamp == undefined ? undefined : +new Date() - +agoTimestamp;
//   if (dt < 60 * 1000) {
//     let seconds = Math.round(dt / 1000.0);
//     agoString = `${seconds} ${"second"}${seconds < 2 || "s"} ago`;
//   }

//   if (dt < 1 * 1000) {
//     agoString = `1 second ago`;
//   }
//   return agoString;
// };
