import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import ls from "local-storage";

import access_denied from "../../assets/images/warning.svg";

export default function AccessDeniedApp(props) {
  const { email = "" } = props;

  return (
    <Wrapper className="top12">
      <img src={access_denied} style={{ height: 100, marginBottom: 0 }} />

      <MessagePlaceholder className="heading">
        Access Restricted.
      </MessagePlaceholder>

      <SubMessagePlaceholder>
        {/* {`Please contact Corsano Admin for getting access for ${email}`} */}
        <p>{"This Corsano Portal is restricted."}</p>
        <p>
          {`It appears you (`}
          <i>{email}</i>
          {`) do not have access for this portal.`}
        </p>{" "}
        <p>
          {`Please contact your Corsano Account Manager or Atanur Demir at `}
          <i>beatrice.gagliostri@corsano.com</i>
          {" for getting access."}
        </p>
      </SubMessagePlaceholder>

      <SubMessagePlaceholder>
        <LogoutSpan
          className="dt-expception"
          onClick={() => {
            ls.remove("users_cloud_token");
            ls.remove("health_cloud_token");
            window.location.reload();
          }}
        >
          Use a different account
        </LogoutSpan>
      </SubMessagePlaceholder>
    </Wrapper>
  );
}

const LogoutSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
  :hover {
    opacity: 0.75;
  }
`;

const Wrapper = styled.div`
  z-index: 100;
  box-sizing: border-box;
  // width: 100vw;
  // height: 100vh;
  width: 20vw;
  height: 50vh;
  // border: 3px solid red;
  display: flex;
  // position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: rgba(192, 192, 192, 0.9);
`;

const MessagePlaceholder = styled.h2`
  font-size: 32px;
  line-height: 44px;
`;

const SubMessagePlaceholder = styled.div`
  font-size: 16px;
  line-height: 32px;
  margin-top: 10px;
  text-align: center;
`;
