import moment from "moment";
import { Dot } from "recharts";
export const addChargingFlagToData = (data) => {
  if (!data || data.length < 2) {
    return [];
  }
  let dataWithChargingFlag = [];
  data.map((d, i) => {
    let isCharging = false;
    const { bpm, battery = undefined, wearing } = d;
    const prevBattery = data[i - 1]?.battery;
    if (i === 0) {
      isCharging = false;
    }
    if (d?.bpm && d.bpm > 0) {
      isCharging = false;
    }
    if (!bpm && battery >= prevBattery && wearing == 0) {
      isCharging = true;
    }
    // console.log(isCharging, battery, prevBattery, d);
    // if (isCharging) {
    //   console.log(
    //     isCharging,
    //     moment(d.slot).format("HH:mm"),
    //     battery,
    //     prevBattery,
    //     !bpm,
    //     wearing == 0
    //   );
    // }

    dataWithChargingFlag.push({ ...d, isCharging: isCharging });
  });

  return dataWithChargingFlag;
};

export function getChargingSpans(data) {
  if (!data || data.length === 0) {
    return [];
  }
  const chargingSpans = [];
  let currentSpan = null;

  data.forEach((entry) => {
    if (entry.is_charging) {
      if (!currentSpan) {
        currentSpan = { from: entry.slot, reason: "charging" }; // Start of a new charging span
      }
    } else {
      if (currentSpan) {
        currentSpan.to = entry.slot; // End of the current charging span
        chargingSpans.push(currentSpan);
        currentSpan = null;
      }
    }
  });

  // If charging is active at the end of the dataset, close the span
  if (currentSpan) {
    currentSpan.to = data[data.length - 1].slot;
    chargingSpans.push(currentSpan);
  }

  return chargingSpans;
}

export function getLowBatterySpans(data) {
  if (!data || data.length === 0) {
    return [];
  }
  const threshold = 3;
  const chargingSpans = [];
  let currentSpan = null;
  const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

  data.forEach((entry, index) => {
    // const timeDiffPrev = index > 0 ? entry.t - data[index - 1].t : undefined;
    const timeDiffPost =
      index > data.length - 2 ? 10 ** 10 : data[index + 1].t - entry.t;
    if (
      entry.battery <= 5 &&
      !entry.is_charging &&
      timeDiffPost < TEN_MINUTES
    ) {
      if (!currentSpan) {
        // const timeDiffPost =
        //   index === data.length - 2 ? data[index + 1] - entry.t : undefined;
        // console.log({ timeDiffPrev, timeDjiffPost });
        currentSpan = { from: entry.slot, reason: "Battery <= 5" }; // Start of a new charging span
      }
    } else {
      if (currentSpan) {
        currentSpan.to = entry.slot; // End of the current charging span
        chargingSpans.push(currentSpan);
        currentSpan = null;
      }
    }
  });

  // If charging is active at the end of the dataset, close the span
  if (currentSpan) {
    currentSpan.to = data[data.length - 1].slot;
    chargingSpans.push(currentSpan);
  }

  return chargingSpans;
}

export function getNotWearingSpans(data) {
  if (!data || data.length === 0) {
    return [];
  }
  const notWearingSpans = [];
  let currentSpan = null;

  data.forEach((entry) => {
    if (entry.wearing == 0) {
      if (!currentSpan) {
        currentSpan = { from: entry.slot }; // Start of a new not wearing span
      }
    } else {
      if (currentSpan) {
        currentSpan.to = entry.slot; // End of the current not wearing span
        notWearingSpans.push(currentSpan);
        currentSpan = null;
      }
    }
  });

  if (currentSpan) {
    currentSpan.to = data[data.length - 1].slot;
    notWearingSpans.push(currentSpan);
  }

  return notWearingSpans;
}

export function getGapAndNotWearingSpans(data) {
  if (!data || data.length === 0) {
    return [];
  }

  const notWearingSpans = [];
  let currentSpan = null;
  const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds

  data.forEach((entry, index) => {
    if (index > 0) {
      const timeDiff = entry.t - data[index - 1].t;
      if (timeDiff > TEN_MINUTES) {
        if (currentSpan) {
          currentSpan.to = data[index - 1].slot; // End current span before gap
          notWearingSpans.push(currentSpan);
          currentSpan = null;
        }
        // notWearingSpans.push({ from: data[index - 1].slot, to: entry.slot, reason: 'gap' });
        notWearingSpans.push({
          from: data[index - 1].slot,
          to: entry.slot,
          reason: "gap",
        });
      }
    }

    if (entry.wearing === 0 && !entry.is_charging) {
      if (!currentSpan) {
        currentSpan = { from: entry.slot, reason: "not_wearing" }; // Start of a new not wearing span
        // currentSpan = { from: entry.slot }; // Start of a new not wearing span
      }
    } else {
      if (currentSpan) {
        currentSpan.to = entry.slot; // End the current not wearing span
        notWearingSpans.push(currentSpan);
        currentSpan = null;
      }
    }
  });

  // If a "not wearing" span was still open, close it
  if (currentSpan) {
    currentSpan.to = data[data.length - 1].slot;
    notWearingSpans.push(currentSpan);
  }

  const mergedSpans = [];
  for (let i = 0; i < notWearingSpans.length; i++) {
    const span = notWearingSpans[i];
    if (
      mergedSpans.length > 0 &&
      mergedSpans[mergedSpans.length - 1].to === span.from
    ) {
      // Merge with the last span
      mergedSpans[mergedSpans.length - 1].to = span.to;
    } else {
      // Add as a new span
      mergedSpans.push(span);
    }
  }

  return mergedSpans;
}

// export function getChargingSpans

export const CustomizedLabel = (props) => {
  // console.log(props);
  const { x1, x2, offset, visibility, reason = "" } = props;
  if (visibility === "hidden") {
    return null;
  }
  if (!props?.viewBox) {
    return null;
  }
  const { x, y, width, height } = props.viewBox || {};

  return (
    <text
      x={x}
      y={y - 16}
      textAnchor="middle"
      fill="#808080"
      fontSize={12}
      offset={offset}
      className="recharts-text recharts-label"
    >
      <tspan x={x + width / 2} dy="0em">
        {reason}
      </tspan>
      <tspan x={x + width / 2} dy="1.2em">{`(${moment(x1).format(
        "HH:mm"
      )} - ${moment(x2).format("HH:mm")})`}</tspan>
    </text>
  );
};

export function isSameDay(timestamp, portalDate) {
  const spotDate = moment(timestamp);
  const pDate = moment(portalDate, "YYYY-MM-DD");
  const isSame = pDate.isSame(spotDate, "day");
  return isSame;
}

export const RenderDot = ({ cx, cy, r, fill, stroke, strokeWidth }) => {
  return (
    <Dot
      cx={cx}
      cy={cy}
      fill={fill}
      r={r}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  );
};

export function filterListWithMinGap(sortedList, minGap = 2) {
  /**
   * restrict spo2 markers to appear every 2 hours
   *
   * Example usage:
   * const sortedList = [2.3, 3.4, 5.0, 11.3, 13.1];
   * const minGap = 2;
   * const filteredList = filterListWithMinGap(sortedList, minGap);
   */
  if (sortedList.length === 0) return [];

  const filteredList = [sortedList[0]]; // Start with the first object

  for (let i = 1; i < sortedList.length; i++) {
    if (
      sortedList[i].decimalHour -
        filteredList[filteredList.length - 1].decimalHour >=
      minGap
    ) {
      filteredList.push(sortedList[i]);
    }
  }

  return filteredList;
}
