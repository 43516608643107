import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import SabirBarChart from "../../activity/tools/SabirBarChart";
import ActivityGoalsTool from "../../activity/tools/ActivityGoalsTool";
import SleepViewTool from "../../sleep/tools/SleepViewTool";
import SleepGoalsTool from "../../sleep/tools/SleepGoalsTool";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";
import WorkoutViewTool from "../../workouts/tools/WorkoutViewTool";

export default function OverviewTool(props) {
  const {
    summary = undefined,
    goals = undefined,
    loading = false,
    seltT,
    isDemo = false,
    workouts = [],
    min,
    max,
    uuid,
  } = props;

  const [selectedWorkoutId, setSelectedWorkoutId] = useState(undefined);

  // console.log("OverviewTool: summary = ", summary);
  // console.table(summary.heart_rate.slots)

  if (summary == undefined) {
    return <Code />;
  }

  let selectedWorkout =
    selectedWorkoutId == undefined
      ? undefined
      : workouts.find((x) => x._id == selectedWorkoutId);

  return (
    <Wrapper>
      {summary.heart_rate == undefined ||
      summary.heart_rate.slots == undefined ? (
        <Section>
          <ComplianceHeading className="patient-statistics-heading">
            Pulse Rate
          </ComplianceHeading>
        </Section>
      ) : (
        <Section>
          <ComplianceHeading className="patient-statistics-heading">
            Pulse Rate
          </ComplianceHeading>
          <div style={{ width: "100%", height: 300, boxSizing: "border-box" }}>
            <SimpleBpmSlotsChart
              slots={summary.heart_rate.slots.map((x) => ({
                ...x,
                t: x.timestamp,
              }))}
              workouts={workouts}
              onWorkoutClick={(workout) => {
                setSelectedWorkoutId(workout._id);
              }}
              min={min}
              max={max}
            />
          </div>
        </Section>
      )}

      {/*{workouts.length == 0 ? null :*/}
      {/*    <div style={{marginBottom: 15}}>*/}
      {/*        <h2>*/}
      {/*            Workouts*/}
      {/*        </h2>*/}
      {/*        <div>*/}
      {/*            {workouts.map((a, i) => {*/}
      {/*                return (*/}
      {/*                    <div key={`${a._id}`} style={{cursor: 'pointer', marginBottom: 5}} onClick={() => {*/}
      {/*                        setSelectedWorkoutId(a._id);*/}
      {/*                    }}>*/}
      {/*                        {a.start_date}*/}
      {/*                    </div>*/}
      {/*                )*/}
      {/*            })}*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*}*/}

      {summary.activity == undefined ||
      summary.activity.slots == undefined ? null : (
        <Section>
          <ComplianceHeading className="patient-statistics-heading">
            Activity
          </ComplianceHeading>
          <ActivityRow>
            <Box style={{ flex: 1 }} className="activity-box">
              <div
                style={{ width: "100%", height: 300, boxSizing: "border-box" }}
              >
                <SabirBarChart
                  shouldChangeColorOfSelected={false}
                  barColor={"#FF962A"}
                  items={TarnsformerHelper.get24HoursStepsArray(
                    summary.activity.slots
                  )}
                  renderItem={(it) => (
                    <span
                      style={{
                        fontWeight: "normal",
                        color: "grey",
                        fontSize: 8,
                        opacity: "50%",
                      }}
                    >
                      {it.time}
                    </span>
                  )}
                />
              </div>
            </Box>
            <Box
              style={{
                width: 420,
                marginLeft: 20,
              }}
              className="activity-box"
            >
              <div style={{ width: "100%" }}>
                <ActivityGoalsTool {...summary.activity} goals={goals} />
              </div>
            </Box>
          </ActivityRow>
        </Section>
      )}

      {summary.sleep == undefined ||
      summary.sleep.slots == undefined ||
      window.location.href.indexOf("portal") > -1 ? null : (
        <Section>
          <ComplianceHeading className="patient-statistics-heading">
            Sleep
          </ComplianceHeading>
          <ActivityRow>
            <Box
              style={{ width: 420, marginRight: 20 }}
              className="activity-box"
            >
              <SleepGoalsTool goals={goals} {...summary.sleep} />
            </Box>
            <Box style={{ flex: 1 }} className="activity-box">
              <div
                style={{ width: "100%", height: 310, boxSizing: "border-box" }}
              >
                <SleepViewTool
                  slots={summary.sleep.slots}
                  loading={loading}
                  uuid={uuid}
                />
              </div>
            </Box>
          </ActivityRow>
        </Section>
      )}

      {/*<div style={{marginBottom: 20}}>*/}
      {/*    {JSON.stringify(goals)}*/}
      {/*</div>*/}

      {/*<div style={{marginBottom: 20}}>*/}
      {/*    {JSON.stringify(summary)}*/}
      {/*</div>*/}

      <Sidebar
        visible={selectedWorkout != undefined}
        width={Math.min(window.innerWidth, 920)}
        onCloserClick={() => {
          setSelectedWorkoutId(undefined);
        }}
      >
        {selectedWorkout == undefined ? null : (
          <div>
            <h2>{selectedWorkout.start_date}</h2>

            <WorkoutViewTool {...selectedWorkout} />
          </div>
        )}
      </Sidebar>
    </Wrapper>
  );
}

const Box = styled.div`
  box-sizing: border-box;
  padding: 20px;
  background: #ffffff;
  border: 1px solid rgba(48, 48, 48, 0.1);
  border-radius: 4px;
`;

const ActivityRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ComplianceHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Wrapper = styled.div``;
