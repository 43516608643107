import moment from "moment";

export const XAXIS_COLOR = "#1122227a";
export const YAXIS_COLOR = "#112222aa";

export const AXES_COLOR = "#777777";
export const AXES_COLORX = (theme) =>
  // theme === "dark" ? "#fffdffff" : AXES_COLOR;
  theme === "dark" ? "#fffdffff" : AXES_COLOR;
export const AXES_STROKE_WIDTH = "1px";

export const XaxisProps = {};
export const GRID_STROKE_COLOR = "#dddddd";
export const GRID_DASH_ARRAY = "4 4";
export const GRID_THICKNESS = 0.5;

export const GRID_MA_THICKNESS = 2;

export const GRID_GAP_THICHNESS = 2;
export const GRID_GAP_DASH_ARRAY = "3 3";
export const GRID_GAP_COLOR = "#a0a0af90";

export const DOT_STROKE = { stroke: "#ff1f1fcc", strokeWidth: 2 };

export const NOT_WEARING_COLOR = "#faa";
export const CHARGING_COLOR = "#1e7efa"
export const LOW_BATTERY_COLOR = "#FFA500"

export const xaxisTimeFormatter = (ts) => {
  return moment(ts).format("HH:mm");
};

export const xaxisTimeFormatterWithOffset = (ts, tz) => {
  console.log("xaxisTimeFormatterWithOffset", tz);
  return moment(ts).format("HH:mm");
};

// export const DEFAULT_LINE_COLOR = "#ff1f1fcc";
export const DEFAULT_LINE_COLOR = "red";

export const LINE_COLORS = {
  raw: DEFAULT_LINE_COLOR,
  baseline: "#195a5e",
  adaptive: "#389b9e",
  gap: GRID_GAP_COLOR,
  test: "#c1ca11",
  spot: "#1e1"
};
