import testSound from "../../../assets/sounds/medium.wav";

export default function SoundCheckModal(props) {
  const { onModalClose } = props;
  const playSound = () => {
    const audio = new Audio(testSound);
    audio.play();
  };

  return (
    <div
      className="modal-content"
      style={{
        // width: "45vw",
        width: "580px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "1em",
        // border: "1px solid black",
      }}
    >
      <div
        className="modal-title normal-text"
        style={{
          fontSize: 20,
          fontWeight: 600,
          marginBottom: "1em",
        }}
      >
        Patient Vital Signs Overview
      </div>
      <div
        className="modal-text normal-text"
        style={{
          marginBottom: "1em",
          fontWeight: 500,
          fontSize: 18,
          width: "80%",
        }}
      >
        <div>Welcome to the Corsano Vital Signs Patient Overview Screen.</div>
        <br></br>
        <div>
          Please ensure your system volume is set appropriately to guarantee
          that audible alarms are loud and clear.
        </div>
      </div>
      <div
        className="sound-check-area"
        style={{
          display: "flex",
          // justifyContent: "space-between",
          // margin: "0 20px",
          height: "50px",
          alignItems: "center",

        }}
      >
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            padding: "8px 45px",
            width: "160px",
            color: "white",
            fontWeight: "700",
            backgroundColor: buttonColor,
            borderRadius: "30px",
            marginRight: "2em",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={playSound}
          >
            <span style={{ fontSize: "1.6em", marginRight: ".4em" }}>
              &#x25B6;
            </span>{" "}
            SOUND CHECK
          </div>
        </div>
        {detectOS() && (
          <div
            style={{
              textDecoration: "underline",
              fontWeight: 600,
              color: "#53AFF1",
            }}
          >
            <span
              style={{
                cursor: "pointer",
              }}
            >
              <a href={getSoundSettingLink(detectOS())} target="_blank">
                {osMap[detectOS()]
                  ? `Open Sound Settings`
                  : "Check Sound Settigns on Your Device"}
                {/* {" "}
                {`${] ? osMap[detectOS()] : ""}`} */}
              </a>
            </span>
            {/* <br></br>
            <span
              style={{
                cursor: "pointer",
              }}
            >
              <a href="ms-settings:sound" target="_blank">
                Open Sound Settings on Windows
              </a>
            </span> */}
            {/* <span>Checking System Sound Settings on {detectOS()}</span>
          <span>Checking System Sound Settings on {detectOS()}</span> */}
          </div>
        )}
      </div>
      <br></br>
      {/* <br></br> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          margin: "0 auto",
          textAlign: "center",
          padding: "12px 45px",
          height: 30,
          width: "90px",
          color: "white",
          fontWeight: "700",
          backgroundColor: buttonColor,
          borderRadius: "30px",
        }}
        onClick={() => {
          onModalClose();
        }}
      >
        CLOSE
      </div>
    </div>
  );
}

const buttonColor = "#4AAAA2";

const TRIANGLE_UNICODE = `&#x25B6;`;

function detectOS() {
  try {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Mac OS") !== -1) {
      return "Mac";
    } else if (userAgent.indexOf("Windows") !== -1) {
      return "Windows";
    } else if (userAgent.indexOf("Linux") !== -1) {
      return "Linux";
    } else {
      return "Unknown OS";
    }
  } catch (e) {
    console.error(e);
    return "Unknown OS";
  }
}

function getSoundSettingLink(os) {
  const macOSSoundSettingLink =
    "x-apple.systempreferences:com.apple.preference.sound";
  const windowsSoundSettingLink = "ms-settings:sound ";

  if (os === "Mac") {
    return macOSSoundSettingLink;
  } else if (os === "Windoes") {
    return windowsSoundSettingLink;
  } else {
    return "Unknown OS";
  }
}

const osMap = {
  Mac: "Mac",
  Windows: "Windows",
};
