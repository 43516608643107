import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import ls from "local-storage";
import { Code } from "react-content-loader";
import DoctorAPI from "../../api/DoctorAPI";
import { Sidebar } from "arui-feather/sidebar";
import axios from "axios";
import { EditButton } from "../patients/panels/PatientPanel";
import { NOTIFICATION_TO_DETAILS } from "./BpNotifications";
import BpFilterHelper from "../../helpers/BpFilterHelper";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Dot,
  Legend,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Tabs, { TabItem } from "../ui/Tabs";
import UserInitBpMeasurementsTool from "../spo2/tools/UserInitBpMeasurementsTool";
import InfoToolBadge from "../info/tools/InfoToolBadge";
import BpSquareTool from "./tools/BpSquareTool";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import { AXES_COLOR, AXES_COLORX, AXES_STROKE_WIDTH } from "../ui/templates";

const CLOUD = "cloud";
const SHORT_TERM = "short-term";
const LONG_TERM = "long-term";
const CUFF_BP = "cuff-bp";
const COMBINE = "combine";

const BP_Y_DOMAIN = [0, 200];
const BP_COLORS = [
  {
    name: CLOUD,
    sbp: "#3333FF",
    dbp: "#FF4E45",
  },
  {
    name: SHORT_TERM,
    sbp: "#33e3FF",
    dbp: "#aF4E45",
  },
  {
    name: LONG_TERM,
    sbp: "#06402b",
    dbp: "#ff5c00",
  },
  {
    name: CUFF_BP,
    sbp: "#1e1",
    dbp: "#e1e",
  },
  {
    name: COMBINE,
    sbp: "#2ae",
    dbp: "#f1a",
  },
];

// const SBP_KEY = "nibp_systolic_combine";
// const DBP_KEY = "nibp_diastolic_combine";
const SBP_KEY = "nibp_systolic";
const DBP_KEY = "nibp_diastolic";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let data = {
      cuff_sbp: undefined,
      cuff_dbp: undefined,
      [DBP_KEY]: undefined,
      [SBP_KEY]: undefined,
      bpm: undefined,
      isExternal: undefined,
    };

    let dd = payload[0]?.payload || {};
    let t1 = payload[0]?.payload?.t || undefined;
    payload.map((p, i) => {
      if (p?.payload?.bpm) {
        data.bpm = p?.payload?.bpm;
      }
      if (p?.dataKey === "cuffSbp") {
        data.cuff_sbp = p?.value;
      }
      if (p?.dataKey === "cuffDbp") {
        data.cuff_dbp = p?.value;
      }
      if (p?.dataKey === SBP_KEY) {
        data[SBP_KEY] = p?.value;
      }
      if (p?.dataKey === DBP_KEY) {
        data[DBP_KEY] = p?.value;
      }
      if (p?.payload?.isExternalData) {
        data.isExternal = true;
      }
    });
    // console.log("data.", data, payload);

    let bp = dd["blood pressure"];
    let is_init = dd.is_init;
    let tLabel = dd.recorded_t;
    let hr = dd["heart rate"];
    let notifications = dd?._raw_response?.notification_codes;
    // let messages = dd?._raw_response?.messages;
    if (tLabel === "Invalid date") {
      tLabel = ``;
    }
    let quality = dd?._raw_response?.Quality;
    return (
      <TooltipWrapper>
        {tLabel === "" ? null : (
          <>
            <>
              <b>{tLabel}</b>
            </>
            <div>
              {t1 ? moment(t1).format(" HH:mm") : ""}
              {/* <p>{t2 ? moment(t2).format("HH:mm") : ""} </p>
              <p>{t3 ? moment(t3).format("HH:mm") : ""} </p>
              <p>{t4 ? moment(t4).format("HH:mm") : ""} </p> */}
            </div>
            {/* <div>
              {bp === undefined ? null : `SBP/DBP >48hrs: ${bp[1]}/${bp[0]}`}
            </div> */}
            {/* {notifications ? (
              <div style={{ display: "none" }}>
                Errors:{" "}
                {notifications.map((n, i) => {
                  return <span key={i}>{n === "OK" ? "None" : n}</span>;
                })}
              </div>
            ) : null} */}
            {/* {bp && bp[0] !== null ? (
              <div>Q: {quality ? mapPPGQuality(quality) : ""}</div>
            ) : null} */}
            {data[SBP_KEY] && data[DBP_KEY] ? (
              <div>{`SBP/DBP: ${data[SBP_KEY]}/${data[DBP_KEY]}`}</div>
            ) : null}
            {data.cuff_dbp && data.cuff_sbp ? (
              <div>{`${
                data.isExternal ? "Spot Measurement" : "Cuff"
              } SBP/DBP: ${data.cuff_sbp}/${data.cuff_dbp}`}</div>
            ) : null}
            {data.bpm && <div>{`BPM: ${data.bpm}`}</div>}
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

function showBpAMessage(a) {
  try {
    return a?._raw_response == undefined
      ? ""
      : JSON.parse(a?._raw_response)?.Message;
  } catch (exc) {}
  return "";
}

function mapPPGQuality(num) {
  if (num < 0 || num > 100) {
    return "Invalid PPG quality.";
  }
  return Math.floor(num / 25);
}

function getAverageSbpDbp(points) {
  let sbpSum = 0;
  let dbpSum = 0;
  let count = 0;
  points.forEach((x) => {
    if (x.sbp != undefined) {
      sbpSum += +x.sbp;
      count++;
    }
    if (x.dbp != undefined) {
      dbpSum += +x.dbp;
      // count++;
    }
  });
  return {
    sbp: count == 0 ? "" : Math.round((1.0 * +sbpSum) / count),
    dbp: count == 0 ? "" : Math.round((1.0 * +dbpSum) / count),
  };
}

function getUniqueStrings(arr) {
  let map = {};
  for (let i in arr) {
    map[arr[i]] = 1;
  }
  return Object.keys(map);
}

function getBpWarnings(points) {
  let warnings = [];
  for (let i in points) {
    let { _raw_response } = points[i];
    if (_raw_response != undefined) {
      warnings = warnings.concat(_raw_response?.model_warnings || []);
    }
  }
  warnings = warnings.filter((x) => x != undefined && x != "");
  if (warnings.length == 0) {
    return [];
  }
  return getUniqueStrings([warnings[warnings.length - 1]]);
}

function getBpMessages(points) {
  let warnings = [];
  for (let i in points) {
    let { _raw_response } = points[i];
    if (
      _raw_response != undefined &&
      _raw_response.model_message != undefined
    ) {
      warnings.push(_raw_response.model_message);
    }
  }
  warnings = warnings.filter((x) => x != undefined && x != "");
  if (warnings.length == 0) {
    return [];
  }
  return getUniqueStrings([warnings[warnings.length - 1]]);
}

function getEvery30MinHHmm(startTimestamp, mode) {
  let hhmmStrings = [];
  const pointsNum = 24 * 2;
  for (let i = 0; i < pointsNum; i++) {
    let _timeObject = moment(startTimestamp).add(30 * i, "minutes");
    let _string = _timeObject.format("HH:mm");
    let _ts = _timeObject.valueOf();
    hhmmStrings.push([_string, +_ts]);
  }
  if (mode === "day") {
    hhmmStrings.push([
      "24:00",
      +moment(hhmmStrings[pointsNum - 1][1])
        .add(30, "minutes")
        .valueOf(),
    ]);
  } else {
    // hhmmStrings.push([
    //   "12:00",
    //   +moment(hhmmStrings[pointsNum - 1][1])
    //     .add(30, "minutes")
    //     .valueOf(),
    // ]);
  }
  return hhmmStrings;
}

export default function PatientDayBasicBpCombineTool(props) {
  const {
    uuid,
    dayTimestamp,
    min,
    max,
    cuffInit = [],
    activityPoints = [],
    cloudBpPoints = [],
    activityPointsLoading = false,
    mode = "day",
    toggleBpMode = () => {},
    freshestData = [],
    freshestNotification = [],
    externalMeasurements = [],
  } = props;

  const [points, setPoints] = useState([]);
  // console.log("activityPoints", activityPoints);

  // const [mode, setMode] = useState("day");
  const [selectedBar, setSelectedBar] = useState(undefined);
  const [aiWarnings, setAiWarnings] = useState([]);
  const [noWarning, setNoWarning] = useState(false);

  const [extraMissingLinesCombine, setExtraMissingLinesCombine] = useState([]);
  const [bpCombine, setBpCombine] = useState([]);

  const [bpMergedTable, setBpMergedTable] = useState([]);
  const [xDataAi, setXDataAi] = useState([]);

  const [dayCombineStats, setDayCombineStats] = useState([]);
  const [nightCombineStats, setNightCombineStats] = useState([]);

  const [bpFilter, setBpFilter] = useState([CUFF_BP, COMBINE]);
  const [xDomain, setXDomain] = useState([]);
  const [xticks, setXticks] = useState([]);

  const [cuffData, setCuffData] = useState([]);
  // const [init, setInit] = useState([]);

  const theme = ls.get("theme");
  let endOfDay = +moment(+dayTimestamp).endOf("day");
  let startOfDay = +moment(+dayTimestamp).startOf("day");

  let startOfNextDay = moment(startOfDay).add(1, "day").valueOf();

  let startOfDay12 = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
  let endOfDay12 = +moment(+dayTimestamp).startOf("day").add(12, "hours");

  useEffect(() => {
    let xticks = [];
    let tickNum = 24;
    let _min;
    let _max;
    if (mode === "day") {
      _min = min;
      _max = max;
      setXDomain([_min, _max]);
      for (let i = 0; i < tickNum; i++) {
        xticks.push(moment(min).add(i, "hour").valueOf());
      }
      setXticks(xticks);
    } else {
      for (let i = 0; i < tickNum; i++) {
        _min = moment(min).subtract(12, "hours");
        _max = moment(max).subtract(12, "hours");
        setXDomain([_min, _max]);
        xticks.push(
          moment(min)
            .add(i - 12, "hour")
            .valueOf()
        );
        setXticks(xticks);
      }
    }
  }, [uuid, dayTimestamp, mode]);

  useEffect(() => {
    setCuffData([]);
    setBpMergedTable([]);
    DoctorAPI.getPatientsRawBPSpotsEnhancedFromZero(uuid, dayTimestamp).then(
      (pld) => {
        // console.log("getPatientsRawBPSpotsEnhancedForDay: raw pld = ", pld);
        if (!pld || pld.length === 0) {
          return;
        }

        const bpMeasurementsSuccess = [];
        const allGroupedCuff = pld.reduce((acc, item) => {
          if (!acc[item.session_code]) {
            acc[item.session_code] = [];
          }
          acc[item.session_code].push(item);
          return acc;
        }, {});
        // console.log("allgc", allGroupedCuff);
        // console.log("cuffInit", cuffInit);

        let _measurements = Object.entries(allGroupedCuff).map(
          ([session_code, session]) => {
            let _inits_success = [];
            const sessionSize = session.length;
            session.map((x) => {
              const braceletAccuracy = x.bracelet_accuracy;
              const t = x.client_timestamp;
              const _t = x.timestamp;
              if (braceletAccuracy !== null) {
                _inits_success.push(braceletAccuracy === 1 ? true : false);
                return;
              }
              const _init = cuffInit.find(
                (x) => x.start_timestamp === _t
                // (x) => {
                //   let t0 = moment(t);
                //   let start_ts = moment(x.start_timestamp);
                // console.log(x, t);
                // console.log(start_ts.year());
                // console.log(start_ts.month(), t0.month());
                // console.log(start_ts.date(), t0.date());
                // console.log(start_ts.minute(), t0.minute());
                // console.log(start_ts.seconds(), t0.seconds());
                // console.log(start_ts.hours(), t0.hours());
                // let isSame =
                //   start_ts.date() === t0.date() &&
                //   start_ts.month() === t0.month() &&
                //   start_ts.year() === t0.year() &&
                //   start_ts.minute() === t0.minute() &&
                //   start_ts.seconds() === t0.seconds();
                // return isSame;
                // }
              );
              // console.log("_init", _init, _init?.success);
              // console.log("TTTTTT", t, x.created_at, x.updated_at);
              // console.log(cuffInit);

              const _raw_response =
                _init?.success || _init?.status === "finished";
              // console.log(_init?.success, _init?.status);
              // console.log(_raw_response);
              _inits_success.push(_raw_response);
            });
            const badNum = _inits_success.filter((x) => !x).length;
            // console.log(_inits_success);

            let hasBadCuff = badNum === sessionSize;
            if (hasBadCuff) {
              return false;
            }

            return true;
          },
          {}
        );
        console.log(_measurements);

        let mostRecentCuff = _measurements[_measurements.length - 1];
        let secondMostRecentCuff = _measurements[_measurements.length - 2];
        let thirdMostRecentCuff = _measurements[_measurements.length - 3];
        let numberOfSuccess = [
          mostRecentCuff,
          secondMostRecentCuff,
          thirdMostRecentCuff,
        ].filter((x) => x).length;
        console.log("numberOfSuccess", numberOfSuccess);
        let warning = undefined;
        if (numberOfSuccess === 3) {
          // no error at all
        } else if (numberOfSuccess === 2) {
          if (thirdMostRecentCuff === false) {
            // BP Cuff Measurement Error: Please redo measurement.
            warning = NOTIFICATION_TO_DETAILS["BP_Cuff_Measurement_Error"];
          }
          if (secondMostRecentCuff === false) {
            // BP Cuff Measurement Error: Please redo measurement.
            warning = NOTIFICATION_TO_DETAILS["BP_Cuff_Measurement_Error"];
          }
          if (mostRecentCuff === false) {
            // BP Cuff Measurement Error: Please redo measurement.
            warning = NOTIFICATION_TO_DETAILS["BP_Cuff_Measurement_Error"];
          }
        } else if (numberOfSuccess === 1) {
          if (thirdMostRecentCuff === true) {
            // Continue BP Cuff Measurements: First initialization BP Cuff measurement success. Please do second and third measurement in next 24 hours. Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations.
            warning = NOTIFICATION_TO_DETAILS["Redo_23"];
          }
          if (secondMostRecentCuff === true) {
            // Continue BP Cuff Measurements: First and second initializations BP Cuff measurement success. Please do third measurement in next 24 hours. Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations.
            warning = NOTIFICATION_TO_DETAILS["Redo_3"];
          }
          if (mostRecentCuff === true) {
          }
        } else if (numberOfSuccess === 0 || _measurements.length === 0) {
          warning =
            NOTIFICATION_TO_DETAILS["Insufficient_BP_Cuff_Measurements"];
          // Insufficient BP Cuff Measurements: Continuous Blood Pressure requires cuff measurement at 3 different periods. The accuracy of the continuous blood pressure improves with multiple calibrations. Please perform first BP Cuff measurement.
        }
        console.log("WARNING", warning);

        let currentDay = +moment(+dayTimestamp).date();
        let currentMonth = +moment(+dayTimestamp).month() + 1;
        let currentYear = +moment(+dayTimestamp).year();
        let _pld = pld.filter((x) => {
          let t = moment(x.timestamp);
          // let currentD = currentDay.day();
          let _currentDay = +moment(+t).date();
          let _currentMonth = +moment(+t).month() + 1;
          let _currentYear = +moment(+t).year();
          if (
            _currentDay === currentDay &&
            _currentMonth === currentMonth &&
            _currentYear === currentYear
          ) {
            // console.log("currentDay is", currentDay, currentMonth, currentYear);
            return true;
          }
        });

        const groupedCuff = _pld.reduce((acc, item) => {
          if (!acc[item.session_code]) {
            acc[item.session_code] = [];
          }
          acc[item.session_code].push(item);
          return acc;
        }, {});

        let calculatedMeans = Object.entries(groupedCuff).map(
          ([session_code, session]) => {
            let _inits_success = [];
            const sessionSize = session.length;

            session.map((x) => {
              const braceletAccuracy = x.bracelet_accuracy;
              const t = x.client_timestamp;
              if (braceletAccuracy !== null) {
                _inits_success.push(braceletAccuracy === 1 ? true : false);
                return;
              }
              const _init = cuffInit.find(
                (x) => x.client_start_timestamp === t
              );
              // console.log("_init", _init, cuffInit);

              const _raw_response = _init?._raw_response;
              _inits_success.push(_raw_response ? _raw_response.Success : true);
            });

            // let hasBadCuff = _inits_success.some((x) => !x);
            // const goodNum = _raw_res.filter((x) => x.success).length;
            const badNum = _inits_success.filter((x) => !x).length;
            let hasBadCuff = badNum === sessionSize;
            // console.log("badNum", badNum);

            // console.log("t, _init", _inits_success, hasBadCuff);
            if (hasBadCuff) {
              return {
                cuffSbp: null,
                cuffDbp: null,
                timestamp: null,
                isSuccessfulCuff: false,
              };
            }

            // const sessionInit = init.filter(
            //   (x) => x.client_start_timestamp === session
            // );

            let meanSbp =
              session.reduce((sum, session) => sum + session.sbp, 0) /
              session.length;
            let meanDbp =
              session.reduce((sum, session) => sum + session.dbp, 0) /
              session.length;

            meanSbp = meanSbp.toFixed(0);
            meanDbp = meanDbp.toFixed(0);

            return {
              cuffSD: [+meanDbp, +meanSbp],
              cuffSbp: +meanSbp,
              cuffDbp: +meanDbp,
              t: +session[0].timestamp,
              timestamp: +session[0].timestamp,
              isSuccessfulCuff: true,
            };
            // return acc;
            // return [];
          },
          {}
        );
        // console.log("gourpedCuff", groupedCuff);
        // console.log("gourpedCuff calculatedMeans", calculatedMeans);
        // console.log("_pld", _pld);

        _pld = _pld.map((x) => {
          return {
            // ...x,
            t: +x.timestamp,
            cuffSbp: +x.sbp,
            cuffDbp: +x.dbp,
            cuffSD: [+x.dbp, +x.sbp],
          };
        });
        // console.log("_pld", _pld);

        setCuffData(calculatedMeans);
      }
    );
  }, [uuid, dayTimestamp, mode]);

  useEffect(() => {
    // getting cloud
    // setPoints([]);
    // setBpMergedTable([]);
    // DoctorAP I.getAIBpData(uuid, from, to).then((arr) => {
    let arr = cloudBpPoints;
    setPoints(arr);
    // console.log({arr});

    let AIwarning = arr.slice(0, arr.length).map((x) => {
      let notification = x?._raw_response?.notification_codes;
      let aiModelSuccess = x?._raw_response?.Success;
      return {
        notification: notification,
        ts: x?.client_start_timestamp,
        aiModelSuccess: aiModelSuccess,
      };
    });
    AIwarning = AIwarning.filter((a) => a.aiModelSuccess);
    let checkAllWarnings = arr.map((x) => {
      let notification = x?._raw_response?.notification_codes;
      if (notification === undefined || notification[0] === "OK") {
        return null;
      }
      return notification;
    });

    // setAiWarnings(AIwarning);
    setAiWarnings([]);
    setNoWarning(checkAllWarnings.every((x) => x === null));

    // });
  }, [uuid, dayTimestamp, mode, cloudBpPoints]);

  let messages = getBpMessages(points);
  let warnings = getBpWarnings(points);

  // useEffect(() => {
  //   let n = 10;
  //   setFreshestData([]);
  //   setFreshestNotification([]);
  //   DoctorAPI.getFreshestAIBpData(uuid, n).then((arr) => {
  //     if (arr.length === 0) {
  //     } else {
  //       let _freshestNotification = arr[0].notification_codes;
  //       setFreshestData(arr[0]);
  //       setFreshestNotification(_freshestNotification || []);
  //     }
  //   });
  // }, [dayTimestamp]);

  useEffect(() => {
    let from = +moment(+dayTimestamp).startOf("day");
    let to = +moment(+dayTimestamp).endOf("day");
    if (mode != "day") {
      from = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      to = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }

    // setLoading(true);
    setBpCombine([]);
    setBpMergedTable([]);
    setExtraMissingLinesCombine([]);

    // DoctorAPI.getActivityPoints(uuid, from, to).then((arr) => {
    if (activityPoints) {
      let arr = activityPoints;
      // console.log(
      //   "checking ActivityPoints",
      //   arr.map((x) => moment(x.t).format("DD HH:mm"))
      // );
      // setLoading(false);

      let noNullArrCombine = BpFilterHelper.removeNullPoinst(
        arr,
        SBP_KEY,
        DBP_KEY
      );

      let allDayPoints = getEvery30MinHHmm(from, mode);

      let _combine = BpFilterHelper.alignToHalfHour(
        noNullArrCombine,
        SBP_KEY,
        DBP_KEY
      );

      console.log("allDayPoints", allDayPoints);
      // console.log(_combine);

      let _filledCombine = [];
      // console.log({noNullArrCombine});
      // console.log({_combine});
      // console.log(allDayPoints);

      allDayPoints.map((slot, i) => {
        let date = slot[0];
        let ts = slot[1];
        let pointCb = _combine.find((x) => {
          let xdate = x.date;
          const diff = +ts - +x.t;
          return xdate === date && Math.abs(diff) < 60 * 1000 * 10;
        });
        // let pointCbs = _combine.filter((x) => x.date === date);
        // console.log(pointCbs.length, date);
        // console.log(pointCb, date);

        if (pointCb === undefined) {
          _filledCombine.push({
            [DBP_KEY]: null,
            [SBP_KEY]: null,
            t: ts,
            date: date,
          });
        } else {
          _filledCombine.push({
            [DBP_KEY]: pointCb?.[DBP_KEY],
            [SBP_KEY]: pointCb?.[SBP_KEY],
            t: ts,
            date: date,
          });
        }
      });
      // console.table(_filledCombine);
      setBpCombine(_filledCombine);
    }
  }, [uuid, dayTimestamp, mode, activityPoints]);

  useEffect(() => {
    setBpMergedTable([]);

    const _start = performance.now();

    // let gridPoints = BpFilterHelper.calculateGridPoints(
    //   points,
    //   mode,
    //   dayTimestamp
    // );
    // console.log("points", points);
    // console.log("bpCombine", bpCombine);
    let gridPoints = BpFilterHelper.calculateGridPoints(
      bpCombine,
      mode,
      dayTimestamp
    );
    // console.log("bpCombine gridPoints", gridPoints);
    let xData = BpFilterHelper.calculateXData(gridPoints, SBP_KEY, DBP_KEY);
    // console.log(gridPoints);

    let xDataAi = BpFilterHelper.calculateXDataAiForCombine(
      gridPoints,
      SBP_KEY,
      DBP_KEY
    );
    setXDataAi(xDataAi);

    let nightXDataAi = xDataAi.filter((x) => x.date <= "07:00");
    // let dayXDataAi = xDataAi.filter((x) => x.date > "07:00");
    let dayXDataAi = xDataAi.filter(
      (x) => x.date >= "10:00" && x.date <= "21:00"
    );
    // console.table(nightXDataAi, ["date", "dbp", "sbp"]);
    // console.table(dayXDataAi, ["date", "dbp", "sbp"]);
    // let d = nightXDataAi.reduce((x, cur)=>{return x + cur.sbp}, 0)
    // console.log(d / nightXDataAi.length);

    setDayCombineStats(getAverageSbpDbp(dayXDataAi));
    setNightCombineStats(getAverageSbpDbp(nightXDataAi));
    // console.log("day", getAverageSbpDbp(dayXDataAi));
    // console.table(dayXDataAi);
    // console.log("night", getAverageSbpDbp(nightXDataAi));
    // console.table(nightXDataAi);

    // console.table(xData);

    let fPoints = BpFilterHelper.calculateFPoints(xDataAi, mode, startOfDay);

    let combinePoints = bpCombine;
    // console.log({combinePoints});

    combinePoints = BpFilterHelper.calculateFPoints(
      combinePoints,
      mode,
      startOfDay
    );
    combinePoints = BpFilterHelper.mapNullValuesToUndefined(
      combinePoints,
      SBP_KEY,
      DBP_KEY
    );

    console.log({ combinePoints, bpCombine });

    let extraLines = [];
    extraLines = BpFilterHelper.calculateExtralinesFromFPoints(
      fPoints,
      "sbp",
      "dbp"
    );

    let extraLinesCombine = [];
    extraLinesCombine = BpFilterHelper.calculateExtralinesFromFPoints(
      combinePoints,
      SBP_KEY,
      DBP_KEY
    );

    const cbPoints = combinePoints;
    const endTime = performance.now();
    // console.debug(`bpfilter calculated in ${(endTime - _start).toFixed(1)} ms`);
    let merged = [];
    let _12fixed = false;
    // console.log({fPoints, cbPoints});

    fPoints.map((point, i) => {
      let _date = point.date;
      let combinedSameDate = cbPoints.find((x) => {
        let __date = x.date;
        if (__date === _date) {
          // console.log({__date, _date, x});
          return x;
        }
      });
      if (mode !== "day" && _date === "12:00") {
        _12fixed = true;
      }

      if (combinedSameDate) {
        if (_date === "12:00" && _12fixed) {
          merged.push({
            ...point,
            merged: 1,
          });
        }
        merged.push({
          ...combinedSameDate,
          ...point,
          merged: 2,
        });
      } else {
        merged.push({
          ...point,
          merged: 1,
        });
      }
    });
    if (mode === "day") {
      merged.push(...cuffData);
    }
    if (mode === "day") {
      const _externalMeasurements = externalMeasurements.map((x) => {
        return {
          ...x,
          cuffSbp: x.bp_sys,
          cuffDbp: x.bp_dia,
          t: x.timestamp,
          isSuccessfulCuff: true,
          isExternalData: true,
        };
      });
      merged.push(..._externalMeasurements);
    }

    setExtraMissingLinesCombine(extraLinesCombine);
    // console.log("extraLinesCombine", extraLinesCombine);

    setBpMergedTable(merged);
  }, [
    points,
    bpCombine,
    dayTimestamp,
    activityPoints,
    cuffData,
    externalMeasurements,
  ]);

  let hideErrorIfThereIs = !moment(endOfDay).isAfter(
    freshestData.start_timestamp
  );

  //   console.log("bpMergedTable", bpMergedTable);
  // console.log(
  //   "bpMergedTable.filter((x) => x.cuffSD).length > 0 ",
  //   bpMergedTable.filter((x) => x.cuffSD)
  // );

  if (activityPoints.length == 0 && activityPointsLoading == true) {
    return (
      <div style={{ marginBottom: "1em" }}>
        <TabHeading className="patient-statistics-heading">
          <div>Blood Pressure</div>
        </TabHeading>
        <Code />
      </div>
    );
    // return <Code color="#1ae" />;
  }

  if (activityPoints.length == 0 && activityPointsLoading == false) {
    if (
      !freshestNotification[0] ||
      freshestNotification.length === 0 ||
      !freshestData ||
      hideErrorIfThereIs
    ) {
      // console.log("daytimestamp RETURN early");

      return (
        <div style={{ marginBottom: "1em" }}>
          <TabHeading className="patient-statistics-heading">
            <div>Blood Pressure</div>
          </TabHeading>
          <SimpleNoDataMessageTool
            // loading={loading}
            message={"Sorry, there is no blood pressure data for this day."}
          />
        </div>
      );
    }
    // console.log("freshestNotification", freshestNotification);

    return (
      <div style={{ marginBottom: "1em" }}>
        <TabHeading className="patient-statistics-heading">
          <div>Blood Pressure</div>
        </TabHeading>
        <SimpleNoDataMessageTool
          // loading={loading}
          message={"Sorry, there is no blood pressure data for this day."}
        />
      </div>
    );
  }

  return (
    <Wrapper>
      {/* <Heading> */}
      {/* Experimental AI Spo2 */}
      {/* </Heading> */}
      <TabHeading className="patient-statistics-heading">
        <div style={{ width: "200px" }}>Blood Pressure</div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontStyle: "normal",
              fontWeight: "normal",
              marginLeft: 20,
              fontSize: "14px",
              alignItems: "center",
            }}
          >
            <strong>Mode:</strong>
            <ModeSwitcherItem
              selected={mode == "day"}
              onClick={() => {
                // setMode("day");
                toggleBpMode("day");
              }}
            >
              00:00-24:00
            </ModeSwitcherItem>
            <ModeSwitcherItem
              selected={mode == "12-12"}
              onClick={() => {
                // setMode("12-12");
                toggleBpMode("12-12");
              }}
            >
              12:00-12:00
            </ModeSwitcherItem>
          </div>
          <div style={{ fontSize: 16, fontWeight: 500, letterSpacing: 0 }}>
            <InfoToolBadge type={"BLOOD_PRESSURE"} />
          </div>
        </div>
      </TabHeading>
      <TopDayNight
        style={{ flexDirection: "flex-start", justifyContent: "space-between" }}
      >
        <div style={{ paddingLeft: 20 }}>
          <div
            style={{
              // display: "grid",
              // gridTemplateColumns: "repeat(5, 1fr)",
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            {bpFilter.includes(COMBINE) ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[0].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  Systolic
                </span>
                <SysDiaColorSpan color={BP_COLORS[0].dbp} />
                <span style={{ fontSize: "13px" }}>Diastolic</span>
              </SysDiaLegendBox>
            ) : null}
            {bpFilter.includes(CUFF_BP) &&
            bpMergedTable.filter((x) => x.cuffSD).length > 0 ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[3].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  Cuff Systolic
                </span>
                <SysDiaColorSpan color={BP_COLORS[3].dbp} />
                <span style={{ fontSize: "13px" }}>Cuff Diastolic</span>
              </SysDiaLegendBox>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
        </div>

        <div className="bp-daynight">
          <div style={{ width: "max-content" }}>{`Night: ${
            nightCombineStats.sbp ? nightCombineStats.sbp : ""
          }/${nightCombineStats.dbp ? nightCombineStats.dbp : ""}`}</div>
          <div style={{ width: "max-content" }}>{`Day: ${
            dayCombineStats.sbp ? dayCombineStats.sbp : ""
          }/${dayCombineStats.dbp ? dayCombineStats.dbp : ""}`}</div>
        </div>
      </TopDayNight>
      <ChartPlaceholder>
        <ResponsiveContainer height={320}>
          {/* <ComposedChart data={fPoints}> */}
          <ComposedChart data={bpMergedTable}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              // xAxisId={1}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              tickCount={24}
              dataKey={"t"}
              ticks={xticks}
              interval={0}
              // domain={[_min, _max]}
              domain={xDomain}
              tickFormatter={xFormatter}
              overflow={false}
              // allowDataOverflow={true}
            />

            <YAxis
              type="number"
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              domain={([dataMin, dataMax]) => {
                const _max = dataMax > 200 ? dataMax : 200;
                return [0, _max];
              }}
            />
            {extraMissingLinesCombine.map((l, i) => {
              return (
                <React.Fragment key={i}>
                  <Line
                    hide={!bpFilter.includes(COMBINE)}
                    type={"monotone"}
                    dataKey={`${DBP_KEY}_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[0].dbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                  />
                  <Line
                    hide={!bpFilter.includes(COMBINE)} // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`${SBP_KEY}_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[0].sbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                  />
                </React.Fragment>
              );
            })}

            {/* <Line
              dataKey={"cuffDbp"}
              name={"cuff_dbp"}
              stroke={"#e1e"}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes(CUFF_BP)}
              // hide={false}
              isAnimationActive={false}
            />
            <Line
              dataKey={"cuffSbp"}
              name={"cuff_sbp"}
              stroke={"#1e1"}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes(CUFF_BP)}
              // hide={false}
              isAnimationActive={false}
            /> */}
            <Scatter
              dataKey={"cuffSbp"}
              name={"cuff_sbp"}
              isAnimationActive={false}
              // fill="#fff"
              shape={
                <RenderBpDot
                  fill="#fff"
                  stroke="#1e1"
                  strokeWidth={2}
                  r={3.2}
                />
              }
              activeShape={
                <RenderBpDot fill="#1e1" stroke="#1e1" strokeWidth={2} r={2} />
              }
            />
            <Scatter
              dataKey={"cuffDbp"}
              name={"cuff_dbp"}
              strokeWidth={2}
              isAnimationActive={false}
              // fill="#fff"
              shape={
                <RenderBpDot
                  fill="#fff"
                  stroke="#e1e"
                  strokeWidth={2}
                  r={3.2}
                />
              }
              activeShape={
                <RenderBpDot fill="#e1e" stroke="#e1e" strokeWidth={2} r={2} />
              }
            />
            <Line
              dataKey={SBP_KEY}
              stroke={BP_COLORS[0].sbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(COMBINE)}
              isAnimationActive={false}
            />
            <Line
              dataKey={DBP_KEY}
              stroke={BP_COLORS[0].dbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(COMBINE)}
              isAnimationActive={false}
            />
            <Line dataKey={"bpm"} hide={true} isAnimationActive={false} />

            <Tooltip
              content={<CustomTooltip />}
              labelFormatter={(t) => moment(t).format("HH:mm")}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartPlaceholder>
      <div style={{ display: "none", fontSize: "12px", textAlign: "center" }}>
        {messages.length == 0 ? null : (
          <span style={{ marginRight: 20 }}>{`Messages: ${messages.join(
            ", "
          )}`}</span>
        )}
        {warnings.length == 0 ? null : (
          <span>{`Warnings: ${warnings.join(", ")}`}</span>
        )}
      </div>
      {noWarning || hideErrorIfThereIs ? (
        <WarningArea>
          <div style={{ fontWeight: "700", fontSize: "20px" }}>{""}</div>
        </WarningArea>
      ) : (
        <WarningArea>
          {aiWarnings
            .slice(aiWarnings.length - 1, aiWarnings.length)
            .map((x, i) => {
              let nfs = x.notification;
              if (!nfs) return null;

              return (
                <div key={i} style={{ width: "80%", marginBottom: "1rem" }}>
                  <div style={{ fontWeight: "500", fontSize: "20px" }}>
                    Warnings:
                  </div>
                  {/* <TimeText>{moment(x.ts).format("HH:mm")}</TimeText> */}
                  <ul>
                    {nfs
                      .filter((m) => NOTIFICATION_TO_DETAILS[m])
                      .map((n, _i) => {
                        return (
                          <li key={_i}>
                            <strong>{NOTIFICATION_TO_DETAILS[n].title}</strong>
                            {": "}
                            {NOTIFICATION_TO_DETAILS[n].verbal}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </WarningArea>
      )}
      <BpSquareTool
        items={bpCombine.map((x) => {
          // console.log("combin", x?.[SBP_KEY], x?.[DBP_KEY]);
          return {
            s: x?.[SBP_KEY],
            // s: 90,
            d: x?.[DBP_KEY],
          };
        })}
      />
      {/* <>
        <TabHeading className="patient-statistics-heading">BP AI</TabHeading>

        <TopDayNight>
          <div className="bp-daynight">
            <div>{`Night: ${nightCombineStats.sbp}/${nightCombineStats.dbp}`}</div>
            <div>{`Day: ${dayCombineStats.sbp}/${dayCombineStats.dbp}`}</div>
          </div>
        </TopDayNight>
        <ChartPlaceholder>
          <ResponsiveContainer height={320}>
            <ComposedChart
              data={xDataAi}
              onClick={(x) => {
                // console.log("ComposedChart: onClick: x = ", x);
                let d = (x?.activePayload || []).find(
                  (x) => x.dataKey == "blood_pressure"
                );
                setSelectedBar(d?.payload);
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="t"
                stroke={AXES_COLORX(theme)}
                tickFormatter={(a) => {
                  return moment(a).format("HH:mm");
                }}
              />
              <YAxis stroke={AXES_COLORX(theme)} />
              <Tooltip labelFormatter={(t) => moment(t).format("HH:mm")} />
              <Legend />
              <Bar
                name="Blood Pressure"
                dataKey="blood_pressure"
                fill="#8884d8"
              />
              <Line
                name="Heart Rate"
                type="monotone"
                dataKey="heart rate"
                stroke="#ff7300"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </ChartPlaceholder>
      </> */}
      <Sidebar
        visible={selectedBar != undefined}
        width={Math.min(window.innerWidth, 820)}
        onCloserClick={() => {
          setSelectedBar(undefined);
        }}
      >
        {selectedBar == undefined ? null : (
          <div style={{ zIndex: 1 }}>
            <pre
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(fixSelectedBar(selectedBar), null, 2),
              }}
            ></pre>
          </div>
        )}
      </Sidebar>
    </Wrapper>
  );
}
function xFormatter(a) {
  let ss = moment(a).format("HH:mm");
  if (ss == "Invalid date") {
    return "";
  }
  return ss;
}

function fixSelectedBar(d) {
  let res = { ...d };
  if (
    d != undefined &&
    d._raw_response != undefined &&
    typeof d._raw_response == "string"
  ) {
    res._raw_response = JSON.parse(d._raw_response);
  }
  return res;
}

export function alignTimeToHalfHour(ts, cond) {
  /**
   * align timestamp to nearest :00 or :30 before
   */

  let timeObject;
  if (cond) {
    timeObject = moment(ts);
    let minute = timeObject.minutes();
    if (minute >= 30) {
      timeObject.minutes(30);
      timeObject.seconds(0);
      timeObject.millisecond(0);
    } else {
      timeObject.minutes(0);
      timeObject.seconds(0);
      timeObject.millisecond(0);
    }
  } else {
    timeObject = null;
  }
  return timeObject;
}

const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
`;

const ModeSwitcherItem = styled.div`
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  margin-left: 5px;
  margin-right: 5px;
  width: max-content;
`;

const TopDayNight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const RowItem = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowDownload = styled.a`
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const RowDownload2 = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

const BottomDiv = styled.div`
  text-align: right;
`;

const RecordsSpan = styled.span`
  font-style: italic;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  .sidebar__inner {
    background: white;
  }
`;

const TooltipWrapper = styled.div`
  background: #ffffffea;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  width: max-content;
`;

const BpFilterContainer = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 8px;
  align-items: center;

  font-weight: normal;
  font-size: 14px;
  margin-left: 20px;
  // line-height: 28px;
  // border-left: 1px solid grey;

  &:before {
    content: "";
    border: 1px solid #17f;
    margin-right: 1vw;
    align-self: stretch;
  }
`;

const BpFilterItem = styled.div`
  display: flex;
  align-items: baseline;
  // border: 1px solid red;
  // box-sizing: content-box;
  width: max-content;
  max-width: 200px;
`;

const BpFilterCheckbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
const ChartPlaceholder = styled.div`
  height: 320px;
  width: 100%;
`;

const Heading = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 10px;
  font-size: 12px;
  opacity: 0.8;
`;

export function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
const WarningArea = styled.div`
  margin-left: 1rem;
`;

const SysDiaLegendBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  // border: 1px solid blue;
`;
const SysDiaColorSpan = styled.span`
  display: inline-block;
  height: 4px;
  opacity: 0.8;
  width: 40px;
  background-color: ${(props) => props.color} !important;
  margin-right: 10px;
`;

export const RenderBpDot = ({ cx, cy, r, fill, stroke, strokeWidth }) => {
  return (
    <Dot
      cx={cx}
      cy={cy}
      fill={fill}
      r={r}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  );
};
