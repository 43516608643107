import { useState, useEffect } from "react";
import styled from "styled-components";

import ch_not_sel from "../../../assets/images/checkbox.svg";
import ch_sel from "../../../assets/images/chbx.svg";
import { Input } from "../../bracelets/forms/UpdateCorsanoPatientForm";
import CommonHelper from "../../../helpers/CommonHelper";

export default function ExportSpotMeasurements(props) {
  const {
    onChange = (type, deliveryEmail) => {},
    patients = [],
    externalDeviceMeasurements,
  } = props;

  // console.log({ externalDeviceMeasurements });

  // const [email, setEmail] = useState(props.defaultEmail || "");
  const [type, setType] = useState(props.type || "archive");
  const [name, setName] = useState("");

  useEffect(() => {
    if (!patients || patients.length === 0) {
      return;
    }
    let { code, email, uuid, first_name, last_name } = patients[0];
    if (
      first_name != undefined &&
      first_name != "" &&
      last_name != undefined &&
      last_name != ""
    ) {
      setName(`${first_name} ${last_name}`);
    }
    // setName(`${first_name} ${last_name}`);
  }, [patients]);

  const hasExternalTempData =
    externalDeviceMeasurements["temperature"].length > 0;
  const hasSpotTempData =
    externalDeviceMeasurements["temperature_spots"].length > 0;

  if (!hasExternalTempData && !hasSpotTempData) {
    return null;
  }

  return (
    <Wrapper>
      <TopPlaceholder>
        <SectionHeading>Spot Measurement (CSV)</SectionHeading>
        <InnerWrapper>
          <Item
            style={{
              marginLeft: 4,
              cursor: "pointer",
              display: hasSpotTempData ? "" : "none",
            }}
            onClick={() => {
              const temperature_spots =
                externalDeviceMeasurements["temperature_spots"];
              // const temperature_external =
              //   externalDeviceMeasurements["temperature"];
              if (!temperature_spots || temperature_spots.length === 0) {
                return null;
              }
              let head = `client_timestamp;created_at;has_fever;temperature_unit;temperature_value;thermometer_name;timestamp;timezone;updated_at;user_id;with_medication`;
              let csv = `${head}\n${temperature_spots
                .map(
                  (x, i) =>
                    `${x?.client_timestamp};${x?.created_at};${x?.has_fever};${x?.temperature_unit};${x?.temperature_value};${x?.thermometer_name};${x?.timestamp};${x?.timezone};${x?.updated_at};${x?.user_id};${x?.with_medication}`
                )
                .join("\n")}`;
              CommonHelper.downloadFile(`temperature-spots-${name}.csv`, csv);
            }}
          >
            <ChImg
              src={type == "archive" ? ch_sel : ch_not_sel}
              style={{ display: "none" }}
              onClick={() => {
                setType("archive");
              }}
            />
            <span>{"Temperature (spot)"}</span>
          </Item>

          <RItem>
            <Item
              style={{
                marginLeft: 4,
                cursor: "pointer",
                display: hasExternalTempData ? "" : "none",
              }}
              onClick={() => {
                const temperature_external =
                  externalDeviceMeasurements["temperature"];
                if (
                  !temperature_external ||
                  temperature_external.length === 0
                ) {
                  return null;
                }
                console.log(temperature_external);

                let head = `client_timestamp;created_at;is_celsius;temperature;timestamp;timezone;updated_at`;
                let csv = `${head}\n${temperature_external
                  .map(
                    (x, i) =>
                      `${x.client_timestamp};${x.created_at};${x.is_celsius};${x.temperature};${x.timestamp};${x.timezone};${x.updated_at}`
                  )
                  .join("\n")}`;
                CommonHelper.downloadFile(`temperature-external-${name}.csv`, csv);
              }}
            >
              <ChImg
                src={type == "archive" ? ch_sel : ch_not_sel}
                style={{ display: "none" }}
                onClick={() => {
                  setType("archive");
                }}
              />
              <span>{"Temperature (external)"}</span>
            </Item>
          </RItem>
        </InnerWrapper>
      </TopPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const SectionHeading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 15px;
`;

const RItem = styled.div`
  flex: 1;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  flex: 1;
`;

const EmItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
`;

const ChImg = styled.img`
  width: 16px;
  right: 16px;
  margin-right: 5px;
  cursor: pointer;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${(props) => (props.loading == true ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
