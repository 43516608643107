import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import PatientDayTemperatureBaselineTool from "../../../baselines/PatientDayTemperatureBaselineTool";
import { isDebugMode } from "../../../../helpers/UiHelper";
import VitalTrendsChartTool from "../../../trends/tools/VitalTrendsChartTool";
import SimpleBpmSlotsChart from "../../../heart_rate/tools/SimpleBpmSlotsChart";
import SimpleTemperatureDeviationSlotsChart from "../../../heart_rate/tools/SimpleTemperatureDeviationSlotsChart";
import {
  DimsPlaceholder,
  ComplianceHeading,
  TabHeading,
} from "../UiComponents";
import { isSameDay } from "../../../baselines/BaselineUtils";
import moment from "moment";

export default function CbtPanel(props) {
  const {
    id,
    date,
    unit,
    theme,
    tz,
    hasNoTempData,
    loading,
    baselineWindow,
    tempPoints,
    tempPointsLoading,
    summary,
    vitalSummaryInRange,
    newTempPoints,
    allTypeBaselineMap,
    isDemo,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    temperatureSpot = [],
    lowBatterySpans = [],
    externalMeasurements = [],
  } = props;

  // console.log({temperatureSpot});

  return (
    <div className="temperature-tab-container">
      {hasNoTempData ? (
        <SimpleNoDataMessageTool
          loading={loading}
          message={"Sorry, there is no temperature data for this day."}
        />
      ) : (
        <div>
          {/* <TabHeading className="patient-statistics-heading">
                Temperature
              </TabHeading> */}

          {hasNoTempData ? (
            <SimpleNoDataMessageTool
              loading={loading}
              message={"Sorry, there is no temperature data for this day."}
            />
          ) : (
            <div
              className="temperature-baseline-area"
              style={{ height: 380, position: "relative" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ComplianceHeading className="patient-statistics-heading">
                  Temperature
                </ComplianceHeading>
                <InfoToolBadge type={"CBT"} />
              </div>
              <DimsPlaceholder className="celsius-char" style={{ top: 35 }}>
                {unit === "F" ? "F" : "°C"}
              </DimsPlaceholder>
              <PatientDayTemperatureBaselineTool
                baselineWindow={baselineWindow}
                uuid={id}
                date={date}
                theme={theme}
                unit={unit}
                tz={tz}
                // tempPoints={tempPoints}
                tempPoints={allTypeBaselineMap["temperature"]}
                loading={tempPointsLoading}
                hasNoData={
                  summary === undefined ||
                  summary.temperature === undefined ||
                  summary.temperature.slots === undefined
                }
                gapAndNotWearingSpans={gapAndNotWearingSpans}
                chargingSpans={chargingSpans}
                temperatureSpot={temperatureSpot}
                lowBatterySpans={lowBatterySpans}
                externalMeasurements={externalMeasurements.filter((x) =>
                  isSameDay(x.timestamp, date)
                )}
              />
            </div>
          )}

          {isDebugMode() === false || hasNoTempData ? null : (
            <div
              className="temperature-test-area"
              style={{
                height: 300,
                marginTop: "2rem",
                position: "relative",
              }}
            >
              <DimsPlaceholder className="celsius-char">
                {unit === "F" ? "F" : "°C"}
              </DimsPlaceholder>
              <SimpleBpmSlotsChart
                yDoman={[33, 42]}
                hasTooltip={true}
                unit={unit}
                tz={tz}
                slots={newTempPoints
                  .map((x) => ({
                    ...x,
                    t: x.timestamp,
                  }))
                  .map((x) => ({
                    ...x,
                    temperature:
                      x.temperature === 0 || x.temperature === undefined
                        ? null
                        : (+x.temperature).toFixed(1),
                  }))}
                dataKey={"temperature"}
                min={+moment(date).startOf("day")}
                max={+moment(date).startOf("day").add(1, "day").valueOf()}
              />
            </div>
          )}

          {isDemo === true || isDebugMode() === false ? null : (
            <div
              className="temperature-deviation-area"
              style={{ marginTop: "1rem" }}
            >
              <TabHeading className="patient-statistics-heading">
                Temperature Deviation
              </TabHeading>

              <div
                style={{
                  width: "100%",
                  height: 260,
                  position: "relative",
                }}
              >
                <DimsPlaceholder>{unit === "F" ? "F" : "°C"}</DimsPlaceholder>
                <SimpleTemperatureDeviationSlotsChart
                  {...summary.temperature}
                  slots={summary.temperature.slots.map((x) => ({
                    ...x,
                    t: x.timestamp,
                  }))}
                  dataKey={"temp_sk1"}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="temperature-trend-area">
        <ComplianceHeading className="patient-statistics-heading">
          Trends
        </ComplianceHeading>
        <div
          style={{
            width: "100%",
            height: 360,
          }}
        >
          <VitalTrendsChartTool
            uuid={id}
            dataType={"temperature"}
            points={vitalSummaryInRange}
          />
        </div>
      </div>
    </div>
  );
}
