import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TemplatesAPI from "../../../api/TemplatesAPI";
import { toast } from "react-hot-toast";
import QRCode from "react-qr-code";
import { Button } from "../../ui/templates";
import GroupsAPI from "../../../api/GroupsAPI";
import DoctorAPI from "../../../api/DoctorAPI";
import moment from "moment";

function getContentForCode(template = "", code = "", shouldBreak = true) {
  if (template == "") {
    return "";
  }
  let s = template;
  if (s.indexOf("[QR CODE]") == -1) {
    s = `${s}\n\n[QR CODE]\n\n`;
  }
  let component = null;
  if (s.indexOf("[QR CODE]") != -1) {
    let beforeCode = s.split("[QR CODE]")[0];
    let afterCode = s.split("[QR CODE]")[1];
    component = (
      <div
        style={{
          pageBreakAfter: shouldBreak == true ? "always" : undefined,
          padding: "60px",
          paddingTop: "80px",
          boxSizing: "border-box",
        }}
      >
        <div>
          <div
            style={{ fontSize: "15px" }}
            dangerouslySetInnerHTML={{
              __html: beforeCode.replace(/\n/g, "<br/>"),
            }}
          ></div>
        </div>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <QRCode
            value={`https://install.corsano.com/?voucher=${code}`}
            size={120}
          />
        </div>
        <div style={{ fontStyle: "italic", fontSize: "12px" }}>{`${code}`}</div>
        <div>
          <div
            style={{ fontSize: "15px" }}
            dangerouslySetInnerHTML={{
              __html: afterCode.replace(/\n/g, "<br/>"),
            }}
          ></div>
        </div>
      </div>
    );
  }
  return component;
}

export default function PrintVoucherLetterTool(props) {
  const { code } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [hideSelector, setHideSelector] = useState(false);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    if (code == undefined) {
      return;
    }
    setLoading(true);
    TemplatesAPI.getPrintInfo(code)
      .then((d) => {
        // console.log({ d });

        try {
          document.title = `Corsano | ${d?.group?.name ? d?.group?.name : ""} | ${
            d?.group?.code ? d?.group?.code : ""
          }`;
        } catch (error) {}
        setData(d);
        setLoading(false);
        setTimeout(() => {
          // window.print();
        }, 100);
      })
      .catch((exc) => {
        setLoading(false);
        toast.error("Failed to load print info");
      });
    DoctorAPI.getPatientsByTag(code).then((d) => {
      // console.log(d);
      setPatients(d);
    });
  }, [code]);

  useEffect(() => {
    const _codes = data?.codes || [];
    const codesItems = _codes.map((x, i) => {
      return {
        code: x,
        index: i,
        checked: true,
      };
    });
    // console.log(data);

    const reversedItems = codesItems.reverse(); // so freshest vouchers on top
    setSelectedCodes(reversedItems);
  }, [data]);

  let codes = data?.codes || [];
  let template = data?.template?.content || "";

  if (codes.length == 0) {
    return <div>...</div>;
  }

  return (
    <Wrapper className="print-voucher-page">
      <div
        className="patients-letter-filter"
        style={{
          display: hideSelector ? "none" : "block",
          width: "600px",
          margin: "0 auto",
          paddingTop: "1rem",
        }}
      >
        <div style={{ fontSize: "2em", marginBottom: "1rem" }}>
          Print Voucher Codes for:
        </div>
        {
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              marginBottom: "1rem",
            }}
          >
            <div key={"a"}>
              <label style={{ fontWeight: 600 }}>
                <input
                  type="checkbox"
                  name="voucher-checkbox"
                  checked={
                    selectedCodes.filter((x) => x.checked).length ===
                    codes.length
                  }
                  onChange={(evt) => {
                    setSelectedCodes((prevItems) =>
                      prevItems.map((item) => {
                        return { ...item, checked: true };
                      })
                    );
                  }}
                />
                {"Select All"}
              </label>
            </div>
            <div key={"b"}>
              <label style={{ fontWeight: 600 }}>
                <input
                  type="checkbox"
                  name="voucher-checkbox"
                  checked={
                    selectedCodes.filter((x) => !x.checked).length ===
                    codes.length
                  }
                  onChange={() => {
                    setSelectedCodes((prevItems) =>
                      prevItems.map((item) => {
                        return { ...item, checked: false };
                      })
                    );
                  }}
                />
                {"Unselect All"}
              </label>
            </div>
          </div>
        }

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                Created At
              </th>
              <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                Voucher Code
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedCodes.map((c, index) => {
              const p = patients.find((x) => x.code === c.code) || {}; 
              // console.log(patients);
              const { first_name = "", last_name = "", state, created_at } = p;

              const fullName = `${first_name ?? ""} ${last_name ?? ""}`;
              const activated =
                state === "pre_created" ? "Not activated" : "Activated";
              const createdAtString =
                moment(created_at).format("YYYY-MM-DD HH:mm");
              return (
                <tr key={index}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {createdAtString}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    <label>
                      <input
                        type="checkbox"
                        name="voucher-checkbox"
                        checked={c.checked}
                        onChange={(evt) => {
                          setSelectedCodes((prevItems) =>
                            prevItems.map((item) =>
                              item.code === c.code
                                ? { ...item, checked: !item.checked }
                                : item
                            )
                          );
                        }}
                      />
                      {c.code}
                      {fullName.trim() === "" ? "" : ` <${fullName}>`}
                      {/* {` <${activated}>`} */}
                    </label>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* {selectedCodes.map((c, i) => {
          // console.log(c);

          const p = patients.find((x) => x.code === c.code);
          const { first_name = "", last_name = "", state } = p;
          const fullName = `${first_name ?? ""} ${last_name ?? ""}`;
          const activated =
            state === "pre_created" ? "Not activated" : "Activated";

          return (
            <div key={i}>
              <label>
                <input
                  type="checkbox"
                  name="voucher-checkbox"
                  checked={c.checked}
                  onChange={(evt) => {
                    setSelectedCodes((prevItems) =>
                      prevItems.map((item) =>
                        item.code === c.code
                          ? { ...item, checked: !item.checked }
                          : item
                      )
                    );
                  }}
                />
                {c.code}
                {fullName.trim() === "" ? "" : ` <${fullName}>`}
                {` <${activated}>`}
              </label>
            </div>
          );
        })} */}
        <Button
          style={{ margin: 0, marginTop: "1rem" }}
          onClick={async () => {
            setHideSelector(true);
            // console.log(selectedCodes);
            // window.print();
            setTimeout(() => {
              window.print();
            }, 500);
          }}
        >
          Print
        </Button>
      </div>
      {selectedCodes
        .filter((c) => c.checked)
        .map((a, i) => {
          return (
            <React.Fragment key={`code_${a}_${i}`}>
              {getContentForCode(template, a.code, !(i == codes.length - 1))}
            </React.Fragment>
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
`;

/*
Dear Patient,

Thank you for your participation in the study.

Please find instructions to download the Corsano App and register your anonymized voucher code. Please follow these steps:

1) Take your mobile phone and scan the QR Code with your camera:

[QR CODE]

2) You will be directed to the Corsano App on the store. Please download and install the Corsano App on your phone.

3) Open the Corsano App on your mobile phone and press the [VOUCHER] button. You can now scan the QR Code again and your voucher code will be registered.

4) Please follow all instructions your receive in the Corsano App to create a password and then pair the bracelet

Please contact us any time if you are having questions via: https://corsano.com/knowledge-base/

Best regards,

Your Research Team
 */
