import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import moment from "moment";
import SimpleAfNsrChart from "./SimpleAfNsrChart";
import EcgRecordPanel from "../../ecg/panels/EcgRecordPanel";
import Sidebar from "arui-feather/sidebar";
import NiceModal from "../../modals/NiceModal";
import SimplePdfViewer from "./SimplePdfViewer";
import InfoToolBadge from "../../info/tools/InfoToolBadge";
import { isDebugMode } from "../../../helpers/UiHelper";
import CommonHelper from "../../../helpers/CommonHelper";

// const NSR_COLOR = "#FF0000" // old
// const AFIB_COLOR = "#0000FF" // old
const NSR_COLOR = "#0000FF";
const AFIB_COLOR = "#FF0000";

export default function AfNsrTool(props) {
  let { t, uuid } = props;
  // /v2/users/:uuid/af-nsr-points
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [viewId, setViewId] = useState();
  const [selectedAlert, setSelectedAlert] = useState(undefined);
  const inDebugMode = isDebugMode();

  useEffect(() => {
    setLoading(true);
    setData(undefined);
    let from = +moment(+t).add(-30, "days").startOf("day");
    let to = +moment(+t).endOf("day");
    DoctorAPI.getAfNsrData(uuid, from, to).then((x) => {
      // console.log("getAfNsrData: x = ", x);
      setLoading(false);
      setData(x);
    });
  }, [t]);

  let measurements = data?.measurements || [];
  let alerts = data?.alerts || [];
  let activityAfibNsrPoints = data?.activityAfibNsrPoints || [];
  let afibNsrDataHappitechPoints = data?.afibNsrDataHappitech || [];
  // const _afibNsrDataHappitechPoints = afibNsrDataHappitechPoints.map((x) => {
  //   return {
  //     ...x,
  //     t: moment(x.t).format("DD HH:mm"),
  //   };
  // });
  // console.table(
  //   _afibNsrDataHappitechPoints.slice(
  //     _afibNsrDataHappitechPoints.length - 300,
  //     _afibNsrDataHappitechPoints.length
  //   )
  // );

  let points = afibNsrDataHappitechPoints.map((x) => {
    let isNsr = x.nsr == true;
    let isAfib = x.afb == true;
    let color = "rgba(156,163,175,0.57)";
    if (isNsr == true) {
      color = NSR_COLOR;
    }
    if (isAfib == true) {
      color = AFIB_COLOR;
    }
    return {
      isNsr: isNsr,
      isAfib: isAfib,
      t: x.t,
      value: 1,
      color: color,
      stroke: color,
    };
  });

  let afibPoints = points.filter((x) => x.isAfib == true);
  let nsrPoints = points.filter((x) => x.isNsr == true);

  let measurementPoints = measurements.map((x) => {
    return {
      ...x,
      t: +x.start_timestamp,
      value: 0,
      color: "rgba(0,0,0,0.65)",
      // color: '#ffd700',
      stroke: "#030c7c",
      // stroke: '#ffd700',
      isMeasurement: true,
    };
  });

  let alertsPoints = alerts.map((x) => {
    return {
      ...x,
      t: +x.timestamp,
      color: "rgba(0,0,0,0.65)",
      value: 2,
      stroke: "#030c7c",
    };
  });

  // console.log('render: alertsPoints = ', alertsPoints);

  let allPoints = [
    ...afibPoints,
    ...nsrPoints,
    ...measurementPoints,
    ...alertsPoints,
  ].sort((a, b) => a.t - b.t);

  // let from = +moment(+t).add(-30, 'days').startOf('day');
  let to = +moment(+t).endOf("day");
  let from =
    allPoints.length == 0
      ? +moment(+t).add(-30, "days").startOf("day")
      : Math.min(...allPoints.map((x) => x.t));
  // let to = allPoints.length == 0 ? +moment(+t).endOf('day') : Math.max(...allPoints.map(x => x.t));
  // let to = t;

  let middle = from + (to - from) / 2;
  let firstHalf = allPoints.filter((x) => x.t < middle);
  let secondHalf = allPoints.filter((x) => x.t > middle);

  // console.log('measurementPoints = ', measurementPoints);
  // console.log("first", firstHalf);
  // console.log("second", secondHalf);
  // console.log("measurementPoints", measurementPoints);

  let viewItem =
    viewId == undefined
      ? undefined
      : measurements.find((x) => x.uuid == viewId);
  // console.log("viewItem", viewItem);

  if (loading) {
    return (
      <Wrapper>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TabHeading>AF</TabHeading>
          <InfoToolBadge type={"AF"} />
        </div>
        <div style={{ textAlign: "center", padding: "10px" }}>Loading...</div>
      </Wrapper>
    );
  }

  if (loading == false && allPoints.length == 0) {
    return (
      <Wrapper>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TabHeading>AF</TabHeading>
          <InfoToolBadge type={"AF"} />
        </div>
        <div style={{ textAlign: "center", padding: "10px" }}>No data</div>
      </Wrapper>
    );
  }

  let file_ids = selectedAlert?.file_ids || [];
  // console.log("file_ids",file_ids);

  return (
    <Wrapper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TabHeading>AF</TabHeading>
        <InfoToolBadge type={"AF"} />
      </div>

      {firstHalf.length == 0 ? null : (
        <ChartPlaceholder className="af-first-half-area">
          <SimpleAfNsrChart
            points={firstHalf}
            min={from}
            max={middle}
            onMeasurementClick={(mUuid) => {
              // console.log("onMeasurementClick: mUuid = ", mUuid);
              setViewId(mUuid);
            }}
            onAlertClick={async (x) => {
              let n = (x?.file_ids || []).length;
              if (n == 0) {
                return;
              }
              setSelectedAlert(x);
            }}
          />
        </ChartPlaceholder>
      )}

      {secondHalf.length == 0 ? null : (
        <ChartPlaceholder className="af-second-half-area">
          <SimpleAfNsrChart
            points={secondHalf}
            min={middle}
            max={to}
            onMeasurementClick={(mUuid) => {
              setViewId(mUuid);
            }}
            onAlertClick={async (x) => {
              let n = (x?.file_ids || []).length;
              if (n == 0) {
                return;
              }
              setSelectedAlert(x);
            }}
          />
        </ChartPlaceholder>
      )}

      {inDebugMode && (secondHalf.length !== 0 || firstHalf.length !== 0) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            top: -20,
          }}
        >
          <ExportDiv
            onClick={async () => {
              let d = afibNsrDataHappitechPoints;
              let head = `t;nsr;afb`;
              let csv = `${head}\n${d
                .map((x, i) => `${x.t};${x.nsr};${x.afb}`)
                .join("\n")}`;
              CommonHelper.downloadFile(`happitech_${uuid}.csv`, csv);
            }}
          >
            Export CSV
          </ExportDiv>
          <ExportDiv
            onClick={async () => {
              let d = afibNsrDataHappitechPoints;
              let json = JSON.stringify(d);
              CommonHelper.downloadFile(`happitech_${uuid}.json`, json);
            }}
          >
            Export JSON
          </ExportDiv>
        </div>
      )}

      <Sidebar
        visible={viewItem != undefined}
        width={window.innerWidth}
        onCloserClick={() => {
          setViewId(undefined);
        }}
      >
        {viewItem == undefined ? null : (
          <div style={{ width: "100%" }}>
            <h2 style={{ marginTop: -30 }}>
              {moment(viewItem?.start_timestamp).format("DD.MM.YYYY HH:mm")}
            </h2>
            <EcgRecordPanel
              forceUrl={viewItem?.forceUrl}
              userUUID={uuid}
              measurementUUID={viewItem?.uuid}
            />
          </div>
        )}
      </Sidebar>

      {selectedAlert == undefined ? null : (
        <NiceModal
          zIndex={1000000000}
          onClose={() => {
            setSelectedAlert(undefined);
          }}
        >
          <AlertInnerPlaceholder>
            <div>
              <SimplePdfViewer
                isImage={
                  selectedAlert?.isImage == undefined
                    ? false
                    : selectedAlert?.isImage
                }
                uuid={uuid}
                file_ids={file_ids}
              />
            </div>
          </AlertInnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const AlertInnerPlaceholder = styled.div`
  padding: 40px;
  width: 600px;
  max-height: calc(100vh - 160px);
  overflow-y: auto;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 140px;
  box-sizing: border-box;
  padding-left: 45px;
  margin-bottom: 110px;
`;

const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  //background: lightgrey;
  //   margin-top: 10px;
  //   margin-bottom: 10px;
`;
const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
`;

const ExportDiv = styled.span`
  font-size: small;
  color: #333333;
  opacity: 50%;
  // text-align: right;
  // width: 100%;
  cursor: pointer;
`;
