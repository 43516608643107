import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
  GRID_DASH_ARRAY,
  LINE_COLORS,
  CHARGING_COLOR,
  NOT_WEARING_COLOR,
  xaxisTimeFormatter,
  LOW_BATTERY_COLOR,
} from "../ui/templates";
import DoctorAPI from "../../api/DoctorAPI";
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
  Text,
  Label,
  Scatter,
  LineChart,
  ScatterChart,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";
import BaselineToolTipTool from "./BaselineToolTipTool";
import {
  addChargingFlagToData,
  filterListWithMinGap,
  getChargingSpans,
  isSameDay,
  RenderDot,
} from "./BaselineUtils";
import { CustomizedLabel } from "./BaselineUtils";
import marker from "../../assets/images/warning-exclaimation.svg";
import markerBackground from "../../assets/images/warning-triangle-yellow.svg";
import markerBackgroundRed from "../../assets/images/warning-triangle-red.svg";
import sprint from "../../assets/images/confidence-motion.svg";
import venous from "../../assets/images/venous-pooling.svg";
import NiceModal from "../modals/NiceModal";
import { isDebugMode } from "../../helpers/UiHelper";
// const Y_DOMAIN = [80, 100];
// const SPOTS_PER_DAY = 12 * 24;
const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  {
    value: "spot",
    label: "Spot Measurement",
    color: LINE_COLORS.spot,
    width: 2,
    show: true,
  },
];

const fillSpo2MissingSpot = (data, spo2FrequencyValue) => {
  if (!data || data.length === 0) {
    return;
  }
  const minGap = spo2FrequencyValue === 0 ? 31 : 10;
  let emptySpots = [];
  for (let i = 0; i < data.length - 1; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gap = moment(nextPoints).diff(currentPoints, "minutes");
    emptySpots.push(data[i]);
    if (gap >= minGap) {
      let newSlot = moment(currentPoints).add(1, "seconds").valueOf();
      emptySpots.push({
        spo2: null,
        baseline: null,
        t: newSlot,
        from_filled: true,
      });
    } else {
    }
  }
  // console.log({spo2FrequencyValue});

  emptySpots.push(data[data.length - 1]);
  return emptySpots;
};

const fillSpo2MissingSpotWithReason = (
  data,
  originalPoint,
  spo2FrequencyValue
) => {
  if (!data || data.length === 0) {
    return;
  }
  const minGap = spo2FrequencyValue === 0 ? 31 : 10;
  let emptySpots = [];
  for (let i = 0; i < data.length - 1; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gap = moment(nextPoints).diff(currentPoints, "minutes");
    emptySpots.push(data[i]);
    if (gap >= minGap) {
      let newSlot = moment(currentPoints).add(1, "seconds").valueOf();
      const currentPointOriginalIndex = originalPoint.findIndex(
        (x) => x.t === currentPoints
      );
      const nextPointOriginalIndex = originalPoint.findIndex(
        (x) => x.t === nextPoints
      );
      // const startIndex = spo2ProcessedData.indexOf(withGap[0]);
      // const endIndex = spo2ProcessedData.indexOf(withGap[1]);
      const originalGapSlice = originalPoint.slice(
        currentPointOriginalIndex + 1,
        nextPointOriginalIndex
      );
      const originalSliceWearing = originalGapSlice.map((x) => x.wearing);
      const originalSliceSpo2Q = originalGapSlice.map((x) => x.spo2_q);

      const notWearing = originalSliceWearing.filter((x) => x).length === 0;

      const badQuality = originalSliceSpo2Q.filter((x) => x).length === 0;
      console.log({
        currentPointOriginalIndex,
        nextPointOriginalIndex,
        originalGapSlice,
        originalSliceWearing,
        originalSliceSpo2Q,
        badQuality,
        notWearing,
      });

      emptySpots.push({
        spo2: null,
        baseline: null,
        t: newSlot,
        from_filled: true,
        badQuality: badQuality ? true : false,
        notWearing: notWearing ? true : false,
      });
    } else {
    }
  }
  // console.log({spo2FrequencyValue});

  emptySpots.push(data[data.length - 1]);
  return emptySpots;
};

const manipulateFirstFewPoints = (data) => {
  if (!data || data.length < 2) {
    return [];
  }
  let newSpots = [];
  let lastPointsLastDay = undefined;
  let firstPointCurrentDay = [];
  let firstPointCurrentDayIndex = [];
  let lastPointLastDayIndex = undefined;

  for (let i = 0; i < data.length - 1; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let isSameDay = moment(nextPoints).isSame(currentPoints, "day");
    if (!isSameDay) {
      // console.log(data[i]);
      // console.log(data[i - 1]);
      // console.log({
      //   ind: i,
      //   isSameDay,
      //   t0: moment(currentPoints).format("HH:mm"),
      //   t1: moment(nextPoints).format("HH:mm"),
      //   t0m: moment(currentPoints).minute(),
      //   t1m: moment(nextPoints).minute(),
      // });
      lastPointsLastDay = data[i];
      lastPointLastDayIndex = i;
      firstPointCurrentDay.push(data[i + 1]);
      firstPointCurrentDay.push(data[i + 2]);
      firstPointCurrentDayIndex.push(i + 1);
      firstPointCurrentDayIndex.push(i + 2);
      break;
    }
  }

  newSpots = data.map((x, i) => {
    if (!firstPointCurrentDayIndex.includes(i)) {
      return { ...x };
    } else {
      const base = data[lastPointLastDayIndex];
      // console.log(base);
      // console.log(x);

      const newPoint = {
        ...base,
        date: x.date,
        slot: x.slot,
        t: x.t,
        baseline: base.spo2,
        timestamp: x.timestamp,
        alterred: true,
      };
      // console.log(i, newPoint);

      return newPoint;
    }
    // console.log("daoidhosa");

    // return { ...x };
  });

  // console.log(newSpots);

  return newSpots;
};

const PatientDaySpo2BaselineTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    tz = 0,
    baselineWindow = 15,
    points = [],
    loading = false,
    spo2FrequencyValue = undefined,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    lowBatterySpans = [],
    externalMeasurements = [],
  } = props;

  // console.log({ spo2FrequencyValue });

  const [ref, { width, height }] = useDimensions();
  // const [loading, setLoading] = useState(false);
  const [spo2RawData, setSpo2RawData] = useState([]);
  const [spo2ProcessedData, setSpo2ProcessedData] = useState([]);
  // const [batteryData, setBatteryData] = useState([]);
  // const [chargingSpans, setChargingSpans] = useState([]);
  const [visibleLowBatterySpanLabel, setVisibleLowBatterySpanLabel] =
    useState(-1);
  const [visibleChargingSpanLabel, setVisibleChargingSpanLabel] = useState(-1);
  const [visibleNotWearingSpanLabel, setVisibleNotWearingSpanLabel] =
    useState(-1);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
    spot: true,
  });
  const [dataLength, setDataLength] = useState(0);
  const refY = useRef(null);
  const [domainY, setDomainY] = useState(undefined);
  const [refDelta, setRefDelta] = useState(0);
  const [yaxisStart, setYaxisStart] = useState(80);
  // const [showDot, setShowDot] = useState(false)

  const [showConfidenceMessage, setShowConfidenceMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [demoVPMarkerTime, setDemoVPMarkerTime] = useState([]);
  const [motionMarkerTime, setMotionMarkerTime] = useState([]);
  const [lowConfidenceMarkerTime, setLowConfidenceMarkerTime] = useState([]);

  const fillSpo2MissingSpotWithSpans = (data, spo2FrequencyValue) => {
    if (!data || data.length === 0) {
      return;
    }
    const minGap = spo2FrequencyValue === 0 ? 31 : 10;
    let emptySpots = [];
    for (let i = 0; i < data.length - 1; i++) {
      let currentPoints = data[i].t;
      let nextPoints = data[i + 1].t;
      let gap = moment(nextPoints).diff(currentPoints, "minutes");
      emptySpots.push(data[i]);
      if (gap >= minGap) {
        let newSlot = moment(currentPoints).add(1, "seconds").valueOf();

        // Checking if gap is due to not wearing or not
        let notWearing, charging;
        const chargingspan = chargingSpans.map((x, i) => {
          const _from = x?.from;
          const _to = x?.to;
          if (_from >= currentPoints && _to <= nextPoints) {
            charging = true;
          }
          // console.log({ currentPoints, nextPoints, _from, _to });
          return x;
        });
        const notwearingspan = gapAndNotWearingSpans.map((x, i) => {
          const _from = x?.from;
          const _to = x?.to;
          if (_from >= currentPoints && _to <= nextPoints) {
            notWearing = true;
          }
          // console.log({ currentPoints, nextPoints, _from, _to });
          return x;
        });
        // console.log({ notwearingspan, chargingspan, charging, notWearing });

        emptySpots.push({
          spo2: null,
          baseline: null,
          t: newSlot,
          from_filled: true,
          charging: charging,
          notWearing: notWearing,
        });
      } else {
      }
    }
    // console.log({spo2FrequencyValue});

    emptySpots.push(data[data.length - 1]);
    return emptySpots;
  };

  const generateMarkers = (data, field) => {
    const fieldToSpo2Q = {
      venous: 105,
      motion: 104,
      lowConfidence: 103,
    };
    if (!["venous", "motion", "lowConfidence"].includes(field)) {
      return [];
    }
    const availablePoints = data.filter(
      (x) => x?.spo2_q === fieldToSpo2Q[field]
    );
    // console.log(venousPoolingsData);
    const markers = availablePoints
      .map((x, i) => {
        const show = isSameDay(x.t, date);
        if (!show) {
          return null;
        }
        const t = moment(x.t);
        const hour = +t.hours();
        const minute = +t.minutes();
        // console.log({ hour, minute, t: x.t });
        const timeInDecimalhour = hour + minute / 60;
        // console.log(timeInDecimalhour);
        return { decimalHour: timeInDecimalhour, t: x.t };
      })
      .filter((x) => x);

    return markers;
  };

  useEffect(() => {
    if (!points || points.length === 0) {
      return;
    }
    // console.log(points);

    // let _points = points.filter((x) => x?.spo2_q && x?.spo2);
    // let _points = points.filter((x) => x?.spo2_q && x?.spo2);

    let _points = points
      .map((x, i) => {
        // if (x?.spo2)
        return x;
      })
      .filter((x) => x?.spo2_q && x?.spo2);

    // const venousPoolingsData = points.filter((x) => x?.spo2_q === 105);
    // const vpTimes = venousPoolingsData
    //   .map((x, i) => {
    //     const show = isSameDay(x.t, date);
    //     if (!show) {
    //       return null;
    //     }
    //     const t = moment(x.t);
    //     const hour = +t.hours();
    //     const minute = +t.minutes();
    //     const timeInDecimalhour = hour + minute / 60;
    //     return { decimalHour: timeInDecimalhour, t: x.t };
    //   })
    //   .filter((x) => x);
    const vpTimes = generateMarkers(points, "venous");
    const motionTimes = generateMarkers(points, "motion");
    const lowConfidenceTimes = generateMarkers(points, "lowConfidence");

    const minGap = 2;
    const vpTimesPer2Hrs = filterListWithMinGap(vpTimes, minGap);
    const motionPer2Hrs = filterListWithMinGap(motionTimes, minGap);
    const lowConfPer2Hrs = filterListWithMinGap(lowConfidenceTimes, minGap);

    console.log({ motionTimes, motionPer2Hrs });

    // if (isDebugMode()) {
    setMotionMarkerTime(motionPer2Hrs);
    setLowConfidenceMarkerTime(lowConfPer2Hrs);
    setDemoVPMarkerTime(vpTimesPer2Hrs);
    // setMotionMarkerTime(motionTimes);
    // setLowConfidenceMarkerTime(lowConfidenceTimes);
    // setDemoVPMarkerTime(vpTimes);
    // }
    // console.log(_points.length);
    const gaps = _points.slice(1).map((entry, index) => {
      const gapInMinutes = (entry.slot - _points[index].slot) / (1000 * 60);
      return {
        start: _points[index].timestamp,
        end: entry.timestamp,
        gapInMinutes,
        isMoreThan29Minutes: gapInMinutes > 29,
      };
    });

    const showDot =
      gaps.filter((x) => x.isMoreThan29Minutes).length === gaps.length;
    if (!showDot) {
      // _points = fillSpo2MissingSpot(_points, spo2FrequencyValue) || [];
      _points = fillSpo2MissingSpotWithSpans(_points, spo2FrequencyValue) || [];
      // _points =
      // fillSpo2MissingSpotWithReason(_points, points, spo2FrequencyValue) ||
      [];
    } else if (showDot) {
      // _points = fillSpo2MissingSpot(_points, 0) || [];
      _points = fillSpo2MissingSpotWithSpans(_points, 0) || [];
      // _points = fillSpo2MissingSpotWithReason(_points, points, 0) || [];
    }

    // _points = _points.map((x) => {
    //   return {
    //     ...x,
    //     spo2: x.spo2 === 0 ? null : x.spo2,
    //   };
    // });

    _points = manipulateFirstFewPoints(_points); // alter first two points in new day
    // console.log(_points);
    const externalWithNulls = [];

    externalMeasurements.forEach((x, i) => {
      const spotObj = {
        ...x,
        slot: +x.timestamp,
        spo2_spot: x.spo2,
        t: +x.timestamp,
        spo2: null,
      };
      const nullObj = {
        spo2_spot: null,
        t: +x.timestamp + 1,
      };
      externalWithNulls.push(spotObj);
      externalWithNulls.push(nullObj);
    });

    _points = [...externalWithNulls, ..._points];
    console.log(_points);

    setSpo2RawData(_points);
    setSpo2ProcessedData(_points);
    // setDataLength(_points.length);
  }, [date, uuid, points, externalMeasurements]);

  // useEffect(() => {
  //   const y = refY?.current?.state?.yAxisMap["0"]?.domain
  //   console.log("refY" , refY);
  //   console.log("refY y", y)
  //   setDomainY(y)
  // }, [])

  let extraLines = DrawHelper.getExtraDottedChartsData(
    spo2ProcessedData,
    "spo2"
  );
  extraLines = DrawHelper.getExtraDottedChartsData(
    spo2ProcessedData,
    "baseline"
  );

  let spo2WithGaps = spo2ProcessedData;
  for (let sp of spo2WithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`spo2_gap_${el}`] = extraLines[el]["points"][0][`spo2_gap_${el}`];
        sp[`baseline_gap_${el}`] =
          extraLines[el]["points"][0][`baseline_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`spo2_gap_${el}`] = extraLines[el]["points"][1][`spo2_gap_${el}`];
        sp[`baseline_gap_${el}`] =
          extraLines[el]["points"][1][`baseline_gap_${el}`];
      }
    }
  }

  // console.log(extraLines);

  useEffect(() => {
    const yMap = refY.current?.state?.yAxisMap;
    let domain = [];
    let delta = 0.5;
    if (yMap) {
      domain = yMap[0]?.domain;
    } else {
      return;
    }
    if (domain && domain.length == 2) {
      delta = (domain[1] - domain[0]) / 40;
    } else {
      return;
    }
    // console.log({ delta, domain });
    setRefDelta(delta);
    setYaxisStart(domain[0]);
  }, [refY, spo2ProcessedData]);

  // spo2ProcessedData.map((x, i) => {
  //   if (!x.dashed) {
  //     return 0;
  //   }
  //   if (x.dashed && !spo2ProcessedData[i + 1].dashed) {
  //     extraLines.push()
  //   }
  // });
  let xticks = BaselineHelper.getXaxisTicksEachHour(
    moment(date).startOf("day")
  );
  let yticks = [];
  let spo2List = spo2ProcessedData
    .map((x) => x.spo2)
    .filter((x) => x !== null && !isNaN(x));

  let minSpo2 = Math.min(...spo2List);
  let ymin = Math.floor(minSpo2 / 10) * 10;
  if (ymin >= 90) {
    yticks = [80, 85, 90, 95, 100];
  } else if (ymin < 90 && ymin >= 80) {
    yticks = [80, 85, 90, 95, 100];
  } else if (ymin < 80 && ymin >= 70) {
    yticks = [70, 80, 90, 100];
  } else {
    const gap = Math.round((100 - ymin) / 5);
    for (let i = ymin; i <= 100; i += gap) {
      yticks.push(i);
    }
    yticks.push(100);
  }
  // console.log(yticks, ymin);
  // console.log(spo2ProcessedData.map((x) => x.spo2));

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();
  // console.log(refY?.current?.state);
  // console.log(
  //   "current.state.yAxisMap",
  //   refY?.current?.state?.yAxisMap["0"]?.domain
  // );
  // const refDomainY = refY?.current?.state?.yAxisMap["0"]?.domain;
  // const minYForReferenceArea = refDomainY ? refDomainY[0] : yticks[0];
  // const domainY = refY?.current?.state?.yAxisMap["0"]?.domain
  // console.log({ domainY, minYForReferenceArea });

  // console.log("yticks", yticks);
  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }

  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }
  // console.log(spo2ProcessedData);
  // const demoVPMarkerTime = [2];

  return (
    <Wrapper ref={ref}>
      {spo2ProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"Fetching data..."}
          // showTopImg={false}
        />
      ) : (
        <div>
          {/* <divv>LOADING:{loading ? "LOADING" : "loaded"}</divv> */}
          {lowConfidenceMarkerTime.map((x, i) => {
            return (
              <Marker
                decimalHour={x.decimalHour}
                timestamp={x.t}
                type={"marker"}
                key={i}
                width={width}
                showConfidenceMessage={showConfidenceMessage}
                setShowConfidenceMessage={setShowConfidenceMessage}
                setMessageType={setMessageType}
              />
            );
          })}
          {motionMarkerTime.map((x, i) => {
            return (
              <Marker
                decimalHour={x.decimalHour}
                timestamp={x.t}
                type={"motion"}
                key={i}
                width={width}
                showConfidenceMessage={showConfidenceMessage}
                setShowConfidenceMessage={setShowConfidenceMessage}
                setMessageType={setMessageType}
              />
            );
          })}
          {demoVPMarkerTime.map((x, i) => {
            return (
              <Marker
                decimalHour={x.decimalHour}
                timestamp={x.t}
                type={"venous"}
                key={i}
                width={width}
                showConfidenceMessage={showConfidenceMessage}
                setShowConfidenceMessage={setShowConfidenceMessage}
                setMessageType={setMessageType}
              />
            );
          })}
          <ComposedChart
            ref={refY}
            width={width}
            height={height}
            data={spo2ProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              dataKey={"t"}
              ticks={xticks}
              tickSize={10}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"spo2"}
              ticks={yticks}
              domain={([min, max]) => [min < 80 ? min : 80, 100]}
              // domain={([min, max]) => [min, max]}
              // domain={[0,]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
              // allowDataOverflow={true}
            />
            <Line
              type="monotone"
              dataKey="baseline"
              // name="Weighted"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2.5}
              // connectNulls={true}
              connectNulls={false}
              // strokeDasharray="5 5"
              // hide={showLine["adpt"] ? false : true}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              // dot={showDot}

              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="spo2"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              hide={showLine["raw"] ? false : true}
              dot={false}
              isAnimationActive={false}
              // connectNulls={false}
            />
            <Line
              id="scatter"
              type="monotone"
              dataKey="spo2_spot"
              name="spot"
              stroke={LINE_COLORS.spot}
              strokeWidth={0.9}
              connectNulls={false}
              hide={showLine["spot"] ? false : true}
              dot={true}
              activeDot={true}
              isAnimationActive={false}
            />
            {/* <Line
              type="monotone"
              dataKey="steps"
              name="sTEPS"
              stroke={"#112"}
              strokeWidth={1}
              activeDot={true}
              connectNulls={true}
              // hide={showLine["ma"] ? false : true}
              hide={showLine["raw"] ? false : true}
              dot={true}
              isAnimationActive={false}
            /> */}
            {extraLines.map((l, i) => {
              // console.log(l);
              const withGap = spo2ProcessedData.filter(
                (x) => x?.[`baseline_gap_${i}`]
              );
              const startIndex = spo2ProcessedData.indexOf(withGap[0]);
              const endIndex = spo2ProcessedData.indexOf(withGap[1]);

              // console.log({ gapIndex: i, withGap, startIndex, endIndex });
              const slice = spo2ProcessedData.slice(startIndex, endIndex + 1);
              let nullPoint;
              if (slice && slice.length === 3) {
                nullPoint = slice[1];
                // console.log({ nullPoint });
                if (nullPoint?.notWearing || nullPoint?.charging) {
                  // console.log({ falsep: nullPoint });
                  return;
                }
              }

              return (
                <Line
                  key={i}
                  type={"monotone"}
                  // dataKey={`spo2_gap_${i}`}
                  dataKey={`baseline_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={2}
                  strokeDasharray="2 2"
                  stroke={LINE_COLORS.baseline}
                  dot={false}
                  name={"baselineGap"}
                  legendType="none"
                  connectNulls={true}
                  // hide={showLine["raw"] ? false : true}
                  // hide={true}
                />
              );
            })}
            {chargingSpans.map((span, i) => {
              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? yaxisStart - refDelta : yaxisStart - 0.5;
              const y2 = refDelta ? yaxisStart + refDelta : yaxisStart + 0.5;
              if (span.from < startOfDay && x2 < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    // console.log("charign!");
                    if (visibleChargingSpanLabel > -1) {
                      setVisibleChargingSpanLabel(-1);
                    } else {
                      setVisibleNotWearingSpanLabel(-1);
                      setVisibleChargingSpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={CHARGING_COLOR}
                >
                  <Label
                    // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                    //   x2
                    // ).format("HH:mm")})`}
                    content={
                      <CustomizedLabel x1={x1} x2={x2} reason={"Charging"} />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleChargingSpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            {lowBatterySpans.map((span, i) => {
              // console.log({ span });
              const yMap = refY.current?.state?.yAxisMap;
              let domain = [];
              let delta = 0.0;
              if (yMap) {
                domain = yMap[0]?.domain;
              }
              // console.log(yMap);
              // console.log(domain);
              if (domain && domain.length == 2) {
                delta = (domain[1] - domain[0]) / 40;
              }

              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? yaxisStart - refDelta : yaxisStart - 0.5;
              const y2 = refDelta ? yaxisStart + refDelta : yaxisStart + 0.5;

              if (span.from < startOfDay && span.to < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    // console.log("charign!");
                    if (visibleLowBatterySpanLabel > -1) {
                      setVisibleLowBatterySpanLabel(-1);
                    } else {
                      setVisibleNotWearingSpanLabel(-1);
                      setVisibleChargingSpanLabel(-1);
                      setVisibleLowBatterySpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                    setVisibleLowBatterySpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={LOW_BATTERY_COLOR}
                >
                  <Label
                    // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                    //   x2
                    // ).format("HH:mm")})`}
                    content={
                      <CustomizedLabel
                        x1={x1}
                        x2={x2}
                        reason={"Battery <= 5"}
                      />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleLowBatterySpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            {gapAndNotWearingSpans.map((span, i) => {
              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? yaxisStart - refDelta : yaxisStart - 0.5;
              const y2 = refDelta ? yaxisStart + refDelta : yaxisStart + 0.5;
              // console.log({ x1, x2, y1, y2 });

              if (span.from < startOfDay && x2 < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  key={i}
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    if (visibleNotWearingSpanLabel > -1) {
                      setVisibleNotWearingSpanLabel(-1);
                    } else {
                      setVisibleChargingSpanLabel(-1);
                      setVisibleNotWearingSpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={NOT_WEARING_COLOR}
                >
                  <Label
                    // value={`Not wearing (${moment(x1).format(
                    //   "HH:mm"
                    // )} - ${moment(x2).format("HH:mm")})`}
                    content={
                      <CustomizedLabel x1={x1} x2={x2} reason={"Not wearing"} />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleNotWearingSpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            <Tooltip content={<BaselineToolTipTool field={"spo2"} />} />
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              if (externalMeasurements.length === 0 && f.value === "spot") {
                return null;
              }
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel
                    color={f.color}
                    theme={theme}
                    bstyle={f.bstyle ? f.bstyle : "solid"}
                  >
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
      {showConfidenceMessage === false ? null : (
        <NiceModal
          onClose={() => {
            setShowConfidenceMessage(false);
            setMessageType("");
          }}
        >
          {iconMessageMap[messageType]}
        </NiceModal>
      )}
    </Wrapper>
  );
};

export default PatientDaySpo2BaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  // height: 100%;
  height: 300px;
  position: relative;
`;

const Spo2Tooltip = ({ active, payload, label }) => {
  let showSteps = false;
  let showSeconds = false;
  if (window.location.href.includes("localhost")) {
    showSteps = true;
    showSeconds = true;
  }
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          showSeconds ? "HH:mm:ss" : "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Raw SPO2: ${payload[0].payload.spo2}`}</TooltipRow>
        {/* <TooltipRow className="label">{`Baseline: ${payload[0].payload.ma}`}</TooltipRow> */}
        <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${payload[0].payload.spo2_q}`}</TooltipRow>
        {showSteps && (
          <TooltipRow className="label">{`Steps: ${payload[0].payload.steps}`}</TooltipRow>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.color};
    border-style: ${(props) => props.bstyle};
    // border-top: 1rem solid ${(props) => props.color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

const Marker = (props) => {
  const {
    decimalHour,
    timestamp,
    width,
    type = "",
    showConfidenceMessage = () => {},
    setShowConfidenceMessage = () => {},
    setMessageType = () => {},
  } = props;
  // console.log({ timestamp });

  const [isHovered, setIsHovered] = useState(false);

  const iconCap = {
    motion: sprint,
    marker: marker,
    venous: venous,
    yellowBg: markerBackground,
    redBg: markerBackgroundRed,
  };

  const reasonMap = {
    motion: "Motion",
    marker: "Low Confidence",
    venous: "Venous Pooling",
  };
  let bg = "yellowBg";
  if (type === "motion") {
    bg = "redBg";
  }

  const styleMap = {
    motion: {
      position: "absolute",
      width: "60%",
      height: "60%",
      objectFit: "cover",
      top: 10,
      left: 5,
    },
    venous: {
      position: "absolute",
      width: "51%",
      height: "51%",
      objectFit: "cover",
      top: 10,
      left: 7,
    },
    marker: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      top: 0.5,
      left: 0.5,
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "238px",
        // left: `${Math.random() * 200 + 40}px`,
        left: `${25 + decimalHour * ((width - 40) / 24)}px`,
        width: 30,
        height: 30,
        zIndex: 1000,
        cursor: "pointer",
        display: showConfidenceMessage ? "none" : "",
      }}
      onClick={() => {
        // console.log("clicked");
        setShowConfidenceMessage(true);
        setMessageType(type);
      }}
      // onMouseOver={() => setIsHovered(true)}
      // onMouseOut={() => setIsHovered(false)}
      draggable
    >
      {isHovered && (
        <span
          style={{
            fontSize: 12,
            opacity: "50%",
            position: "absolute",
            bottom: 30,
            right: -75,
            width: "max-content",
            display: "none",
          }}
        >{`${reasonMap[type]} at ${moment(timestamp).format("HH:mm")}`}</span>
      )}
      <img
        src={iconCap[bg]}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <img
        src={iconCap[type]}
        style={{
          ...styleMap[type],
        }}
      />
    </div>
  );
};

const iconMessageMap = {
  venous: (
    <>
      <div style={{ margin: "20px", width: "30vw" }}>
        {/* Q=105 */}
        <p style={{ fontSize: "1.3em", fontWeight: 600 }}>
          Venous Pooling Alert
        </p>
        <p>
          We’ve detected possible signs of venous pooling. Venous pooling is the
          physiological condition in which lower oxygenated blood accumulates in
          the veins, particularly in the arm. This may happen if a patient lies
          on their arm, such as while lying on their side or if there’s
          restricted movement.
        </p>
        <p>
          Venous pooling presents a significant challenge for wrist-worn SpO2
          devices in reflective mode due to the anatomical properties of the
          wrist. The increased influence of venous signals on the reflective
          light path creates difficulties in isolating arterial pulsations.{" "}
        </p>
        {/* <p>
          Corsano developed a new algorithm to differentiate between arterial
          and venous signals. If venous pooling is detected, data is discarded.
        </p> */}
      </div>
    </>
  ),
  motion: (
    <>
      <div style={{ margin: "20px", width: "30vw" }}>
        {/* Q=104 */}
        <p style={{ fontSize: "1.3em", fontWeight: 600 }}>Motion Alert</p>
        <p>
          Reliable SpO2 values cannot be obtained during high motion. Excessive
          movement interferes with sensor accuracy.
        </p>
      </div>
    </>
  ),
  marker: (
    <>
      <div style={{ margin: "20px", width: "30vw" }}>
        {/* Q=103 */}
        <p style={{ fontSize: "1.3em", fontWeight: 600 }}>
          Low SpO2 Confidence Alert
        </p>
        <p>
          We have detected an episode of lower SPO2 data-input quality, which
          temporarily reduces our confidence in providing an accurate SPO2
          measurement. This can be caused by factors such as movement, improper
          sensor fit, or environmental conditions.
        </p>
        <p>To ensure accurate readings:</p>
        <ul>
          <li>
            Make sure the sensor is snug, but comfortable, slightly away from
            wrist bone
          </li>
          <li>Remove any jewelry near the bracelet.</li>
        </ul>
        <p>
          If the alert persists, please execute a spot SPO2 measurement in a
          sedentary position.
        </p>
      </div>
    </>
  ),
};
