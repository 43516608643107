import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import UiHelper, {
  isDebugMode,
  isDmytroDebugMode,
  isSleepDebugMode,
  setCorsanoDebug,
  setDmytroDebug,
  setSleepDebug,
  unsetCorsanoDebug,
  unsetDmytroDebug,
  unsetSleepDebug,
} from "../../helpers/UiHelper";

import voidUserImage from "../../assets/images/user_void_0.png";
import CommonHelper from "../../helpers/CommonHelper";
import DoctorAPI from "../../api/DoctorAPI";

import ls from "local-storage";

import { getLabelsInfoData } from "../groups/panels/GroupsPanel";

import lock from "../../assets/images/lock.svg";
import lock_un from "../../assets/images/lock_un.svg";
import offline_img from "../../assets/images/offline-cloud.svg";
import warning_img from "../../assets/images/warning-sign.svg";

import xImage from "../../assets/images/x.svg";

import { Detector } from "react-detect-offline";
import moment from "moment";
import ThemeToggle from "../ui/Toggle";
import access_denied from "../../assets/images/warning.svg";
import LayoutToggle from "../ui/LayoutToggle";
import * as UiActions from "../../redux/actions/UiActions";
import { PrivacyPlaceholder } from "../auth/forms/StyledLoginForm";

const LINKS = [
  {
    value: "index",
    link: "/",
    label: getLabelsInfoData().groups_name,
  },
  window.location.href.indexOf("portal") === -1
    ? undefined
    : {
        value: "alarms",
        link: "/alarms",
        label: "Alarms",
        debug: false,
      },
  {
    value: "bracelets",
    link: "/bracelets",
    label: "Bracelets",
    debug: false,
    cardiomood: false,
    af: false,
  },
  {
    value: "gateways",
    link: "/gateways",
    label: "Gateways",
    debug: false,
    cardiomood: false,
    af: true,
  },
  // {
  //     value: 'patients',
  //     link: '/patients',
  //     label: 'Patients',
  //     debug: false,
  //     cardiomood: false
  // }

  // {
  //     value: 'patients',
  //     link: '/users',
  //     label: 'Patients'
  // },
  // {
  //     value: 'groups',
  //     link: '/groups',
  //     label: 'Studies'
  // }
  // ].filter(x => ((isDebugMode() == true) ? true : (x.debug != true)));
]
  .filter((x) => x != undefined)
  .filter((x) =>
    window.location.href.indexOf("cardiomood") == -1 &&
    window.location.href.indexOf("portal") == -1
      ? true
      : x.cardiomood != false
  )
  .filter((x) =>
    window.location.href.indexOf("af.") == -1 ? true : x.af != false
  );

function hasPaywall() {
  let url = window.location.href;
  let paywallPrefixes = ["study.corsano", "portal.corsano"];
  let f = false;
  for (let i in paywallPrefixes) {
    let p = paywallPrefixes[i];
    if (url.indexOf(p) > -1) {
      f = true;
    }
  }
  return f;
}

export default function DoctorTemplate(props) {
  const { active = "index" } = props;
  // console.debug("optimizing rendering DoctorTemplate");
  const ref = useRef();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [subscription, setSubscription] = useState({
    endTimestamp: +moment().add(12, "months").startOf("day"),
  }); // subscription date control
  const [updT, setUpdT] = useState(0);
  const [subLoading, setSubLoading] = useState(true);
  const location = useLocation();
  const [loginDisallowed, setLoginDisallowed] = useState(false); // domain limitation ([study/portal].corsano.com)

  const dispatch = useDispatch();

  let { currentUserObj, gatewaysLoading, gateways, role } = useMappedState(
    useCallback((state) => {
      return {
        currentUserObj: state.auth.currentUserObj,
        loading: state.auth.loading || state.gateways.loading,
        gateways: state.gateways.gatewaysMap
          .toArray()
          .sort((a, b) => +b.timestamp - +a.timestamp),
        gatewaysLoading: state.gateways.loading,
        role: state.ui.role,
      };
    }, [])
  );

  const [hcpApproved, setHcpApproved] = useState(
    role === "hcp-new" ? false : true
  ); // role control

  useOnClickOutside(ref, () => {
    if (settingsOpen == true) {
      setSettingsOpen(false);
    }
  });

  const isPortalCardioMood =
    window.location.href.indexOf("portal.cardiomood") > -1;
  const isPortal = window.location.href.indexOf("portal.corsano.com") > -1;
  const isStudy = window.location.href.indexOf("study.corsano.com") > -1;
  const portalName = isStudy ? "Research Portal" : "Portal";

  useEffect(() => {
    if (
      currentUserObj == undefined ||
      subscription?.id != undefined ||
      window.location.href.indexOf("embed") > -1
    ) {
      return;
    }
    setSubLoading(true);
    DoctorAPI.getUserRoleStatus1().then((d) => {
      const { role, status } = d;
      // console.log("getUserRoleStatus,d", d);
      let _role;
      if (role === "hcp" && status) {
        _role = [role, status].join("-");
      } else {
        _role = role;
      }

      // console.log("getUserRoleStatus,_role", _role);
      dispatch(UiActions.changeRole(_role));
      // dispatch(UiActions.changeRole("hcp-approved")); // for debug
      setHcpApproved(_role === "hcp-new" ? false : true);
    });

    // DoctorAPI.getUserRoleStatus().then((d) => {
    //   console.log("getUserRoleStatus,d", email, d);
    // });
    DoctorAPI.getSubscription(currentUserObj?.usersUser?.email)
      .then((d) => {
        // setLoginDisallowed(false)
        const currentAddress = window.location.href;
        const inPortalOrStudy =
          currentAddress.includes("portal.corsano.com") ||
          currentAddress.includes("study.corsano.com");
        if (!inPortalOrStudy) {
          console.log("escape");
          return;
        }

        setSubscription(d);
        setSubLoading(false);
        window.currentSubscription = d;

        // check whether current url contains allowed domains
        // for testing: http://localhost:3000/?study.corsano.com.#/
        // http://localhost:3000/?portal.corsano.com.#/
        // const allowedDomain = d?.websites || [
        //   "study.corsano.com",
        //   "portal.corsano.com",
        // ]; // default
        // const allowedDomain = ["portal.corsano.com", "study.corsano.com"]; // default
        const allowedDomain = d?.websites;
        //   "study.corsano.com",
        //   "portal.corsano.com",
        // ]; // default

        let checkAuth = allowedDomain.map((x) =>
          window.location.href.indexOf(x)
        );
        console.log("got sub: d = ", allowedDomain);
        let allDomainNum = Math.max(...checkAuth);
        console.log("checkAuth", checkAuth, allDomainNum);

        if (allDomainNum < 0) {
          setLoginDisallowed(true);
          ls.remove("users_cloud_token");
          ls.remove("health_cloud_token");
        }
      })
      .catch((exc) => {
        setSubscription({
          endTimestamp: +moment().add(12, "months").startOf("day"),
        });
        setSubLoading(false);
      });
    // console.log("optimizing DoctorTemplace re-render");
  }, [currentUserObj]);

  // useEffect(() => {}, [loginDisallowed]);

  if (currentUserObj == undefined) {
    return null;
  }
  const { healthUser, usersUser, dashboardSettings } = currentUserObj;
  if (healthUser == undefined || usersUser == undefined) {
    return null;
  }
  let { first_name, last_name, customer_code, email } = usersUser;
  let isStudyUser =
    customer_code != undefined && customer_code.indexOf("study") > -1;

  let uName = first_name == undefined ? email : `${first_name} ${last_name}`;

  if (isStudyUser == true) {
    if (email != undefined && email.indexOf("@") > -1) {
      uName = email.split("@")[0];
    }
  }
  // console.log("render: currentUserObj = ", currentUserObj);
  let currentUUID = currentUserObj?.healthUser?.uuid;
  let links = LINKS;
  if (gateways.length == 0 && gatewaysLoading == false) {
    links = links.filter((x) => x.value != "bracelets");
  }

  if (isPortalCardioMood == true) {
    links = [];
  }

  // console.log("DoctorTemplate: subscription = ", subscription);

  let isFailedSubscription =
    subscription?.endTimestamp != undefined &&
    subscription?.endTimestamp < +new Date();
  // console.log("isFailedSubscription = ", isFailedSubscription);
  let shouldShowSubscriptionWarning =
    +moment().add(1, "month").startOf("month").startOf("day") ==
    +moment(subscription?.endTimestamp).startOf("month").startOf("day");

  let hasNoSubscription = subscription == undefined && subLoading == false;
  // hasNoSubscription = true;
  let isSubscriptionExpired =
    hasNoSubscription == true ||
    (subscription?.endTimestamp != undefined &&
      +subscription?.endTimestamp < +new Date());

  // console.log(subscription);
  // console.log(
  //   "isSubscriptionExpired = ",
  //   isSubscriptionExpired,
  //   subscription?.endTimestamp
  // );
  // if (subscription?.endTimestamp) {
  //   console.log(moment(subscription?.endTimestamp).format("YYYY MM DD HH:mm"));
  // } else {
  //   console.log("no end timestamp");
  // }
  // console.log("hasPaywall= ", hasPaywall());

  return (
    <Wrapper className="top10">
      <TopPlaceholder className="top11">
        <TopInnerPlaceholder className="top12">
          <TopLeftPlaceholder className="top13">
            <Logo
              src={UiHelper.getTemplateLogoImage()}
              onClick={() => {
                CommonHelper.linkTo("/");
              }}
            />

            <TopLeftLinksPlaceholder className="top14">
              {links.map((link, i) => {
                // if (loginDisallowed) {
                //   return null;
                // }
                // let isSelected = link.value == active;
                let isSelected =
                  link.value == active ||
                  location.pathname.includes(link.label.toLowerCase());
                return (
                  <LinkItem
                    key={i}
                    selected={isSelected}
                    onClick={() => {
                      CommonHelper.linkTo(link.link);
                    }}
                  >
                    {link.label}
                  </LinkItem>
                );
              })}
            </TopLeftLinksPlaceholder>
          </TopLeftPlaceholder>

          <TopRightPlaceholder
            className="top13-right"
            style={
              {
                // display: loginDisallowed ? "none" : "flex",
              }
            }
          >
            {/* {isDebugMode() ? (
              window.location.href.indexOf("af.") > -1 ? null : (
                <LayoutToggle show={true} />
              )
            ) : null} */}
            {/* {isDebugMode() ? <span>{role}</span> : null} */}
            <ThemeToggle />

            <AvaCircle
              onClick={() => {
                if (settingsOpen == false) {
                  setSettingsOpen(true);
                }
              }}
            />
            <CurrentUserName
              onClick={() => {
                if (settingsOpen == false) {
                  setSettingsOpen(true);
                }
              }}
            >
              {uName}
              {dashboardSettings == undefined ||
              dashboardSettings.tag == undefined ||
              dashboardSettings.tag == "" ? null : (
                <span>{`[${dashboardSettings.tag}]`}</span>
              )}
              {settingsOpen == false ? null : (
                <DropdownPlaceholder ref={ref} className="drop-down">
                  {window.location.href.indexOf("portal") > -1 ? null : (
                    <div
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        marginBottom: 10,
                        display: "none", // remove as requested
                      }}
                      onClick={() => {
                        CommonHelper.linkTo(`/user/${currentUUID}`);
                      }}
                    >
                      My Data
                    </div>
                  )}
                  <div
                    className="dropdown-item"
                    style={{ cursor: "pointer", marginBottom: 10 }}
                    onClick={() => {
                      CommonHelper.linkTo(`/settings`);
                    }}
                  >
                    Settings
                  </div>
                  <div
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      DoctorAPI.logout();
                    }}
                  >
                    Sign out
                  </div>
                </DropdownPlaceholder>
              )}
            </CurrentUserName>
          </TopRightPlaceholder>
        </TopInnerPlaceholder>
      </TopPlaceholder>

      <ContentPlaceholder className="content-item">
        <Detector
          polling={{
            url: `https://api.study-integration.corsano.com/ping`,
            interval: 5000,
            timeuot: 5000,
          }}
          render={({ online }) => (
            <div
              style={{ display: !online ? "block" : "none" }}
              className="blink_me"
            >
              <OfflineBox>
                <img
                  src={offline_img}
                  style={{ height: 24, marginRight: 15 }}
                />
                <span>
                  Connection with the Cloud is lost. Please check your internet
                  connection.
                </span>
              </OfflineBox>
            </div>
          )}
        />
        <React.Fragment key={`0_${updT}`}>
          {!hasPaywall() ||
          ls("subscription_dismissed") == "yes" ||
          shouldShowSubscriptionWarning == false ? null : (
            <div style={{ marginTop: 10, marginBottom: 20 }}>
              <OfflineBox style={{ color: "black", position: "relative" }}>
                <img
                  src={warning_img}
                  style={{ height: 32, marginRight: 15 }}
                />
                <span style={{ marginRight: 40 }}>
                  {`In ${moment(subscription?.endTimestamp).format(
                    "MMMM"
                  )}, Corsano will activate subscription for use of the ${portalName}. Your subscription is set to expire: ${moment(
                    subscription?.endTimestamp
                  ).format(
                    "YYYY-MM-DD"
                  )}, please contact beatrice.gagliostri@corsano.com to renew.`}
                </span>

                <div style={{ position: "absolute", top: 12, right: 5 }}>
                  <img
                    src={xImage}
                    style={{ height: 40, cursor: "pointer" }}
                    onClick={() => {
                      ls("subscription_dismissed", "yes");
                      setUpdT(+new Date());
                    }}
                  />
                </div>
              </OfflineBox>
            </div>
          )}
        </React.Fragment>
        <React.Fragment key={updT}>
          {!hasPaywall() || hasNoSubscription == false ? null : (
            <div style={{ marginTop: 10, marginBottom: 20 }}>
              <OfflineBox style={{ color: "black", position: "relative" }}>
                <img
                  src={warning_img}
                  style={{ height: 32, marginRight: 15 }}
                />
                <span>
                  The Corsano {portalName} requires a subscription. Could you
                  please contact{" "}
                  <a href={"mailto:beatrice.gagliostri@corsano.com"}>
                    beatrice.gagliostri@corsano.com
                  </a>{" "}
                  to obtain access
                </span>
              </OfflineBox>
            </div>
          )}
        </React.Fragment>
        {/* {loginDisallowed ? <AccessDeniedApp email={email} /> : props.children} */}
        <React.Fragment key={`${updT}-disallowed-hcp`}>
          {hcpApproved ? null : (
            <FailedSubscriptionOverlay>
              <FailedSubscriptionOverlayInner>
                <FailedBox>
                  <img
                    src={access_denied}
                    style={{ height: 180, marginBottom: 0 }}
                  />

                  <MessagePlaceholder className="heading">
                    You are not yet approved, Access Restricted.
                  </MessagePlaceholder>

                  <SubMessagePlaceholder style={{ fontSize: "20px" }}>
                    <p>{"This Corsano Portal is restricted."}</p>
                    <p>
                      {`It appears you (`}
                      <i>{email}</i>
                      {`) are not yet approved for this portal as health care practitioner (HCP).`}
                    </p>{" "}
                    <p>
                      {`Please contact your HCP administrator `}
                      {`for getting access.`}
                    </p>
                  </SubMessagePlaceholder>
                  <SubMessagePlaceholder>
                    <LogoutSpan
                      className="dt-expception"
                      onClick={() => {
                        ls.remove("users_cloud_token");
                        ls.remove("health_cloud_token");
                        window.location.reload();
                      }}
                    >
                      Use a different account
                    </LogoutSpan>
                  </SubMessagePlaceholder>
                </FailedBox>
              </FailedSubscriptionOverlayInner>
            </FailedSubscriptionOverlay>
          )}
        </React.Fragment>

        <React.Fragment key={`${updT}-disallowed`}>
          {loginDisallowed ? (
            <FailedSubscriptionOverlay>
              <FailedSubscriptionOverlayInner>
                <FailedBox>
                  <img
                    src={access_denied}
                    style={{ height: 100, marginBottom: 0 }}
                  />

                  <MessagePlaceholder className="heading">
                    Access Restricted.
                  </MessagePlaceholder>

                  <SubMessagePlaceholder>
                    <p>{"This Corsano Portal is restricted."}</p>
                    <p>
                      {`It appears you (`}
                      <i>{email}</i>
                      {`) do not have access for this portal.`}
                    </p>{" "}
                    <p>
                      {`Please contact your Corsano Account Manager or Atanur Demir at`}
                      <i>beatrice.gagliostri@corsano.com</i>
                      {" for getting access."}
                    </p>
                  </SubMessagePlaceholder>
                  <SubMessagePlaceholder>
                    <LogoutSpan
                      className="dt-expception"
                      onClick={() => {
                        ls.remove("users_cloud_token");
                        ls.remove("health_cloud_token");
                        window.location.reload();
                      }}
                    >
                      Use a different account
                    </LogoutSpan>
                  </SubMessagePlaceholder>
                </FailedBox>
              </FailedSubscriptionOverlayInner>
            </FailedSubscriptionOverlay>
          ) : null}
        </React.Fragment>
        {props.children}
      </ContentPlaceholder>

      <PrivacyPlaceholder>
        Confidential Personal Health Information
      </PrivacyPlaceholder>

      <FooterPlaceholder>
        <FLeft>
          <DebugSpan
            onClick={() => {
              if (
                isDebugMode() == false &&
                isSleepDebugMode() == false &&
                isDmytroDebugMode() == false
              ) {
                let val = window.prompt("Please enter password.");
                if (val == "341") {
                  setCorsanoDebug();
                  window.location.reload();
                } else {
                }
                if (val == "389") {
                  setSleepDebug();
                  window.location.reload();
                } else {
                }

                if (val == "428") {
                  setDmytroDebug();
                  window.location.reload();
                } else {
                }
              } else {
                unsetDmytroDebug();
                unsetCorsanoDebug();
                unsetSleepDebug();
                window.location.reload();
              }
            }}
          >
            <img
              src={isDebugMode() || isSleepDebugMode() ? lock_un : lock}
              style={{ height: 14 }}
            />
          </DebugSpan>
        </FLeft>

        <FRight></FRight>
      </FooterPlaceholder>

      {isSubscriptionExpired === false || !hasPaywall() ? null : (
        <FailedSubscriptionOverlay>
          <FailedSubscriptionOverlayInner>
            <FailedBox>
              <h1>
                The Corsano {portalName}
                <br />
                requires a subscription
              </h1>
              <p>{`Your subscription ended ${moment(
                subscription?.endTimestamp
              ).format("YYYY-MM-DD")}`}</p>
              <p>
                Could you please contact{" "}
                <a href={"mailto:beatrice.gagliostri@corsano.com"}>
                  beatrice.gagliostri@corsano.com
                </a>
                <br />
                to renew your subscription.
              </p>
              <p>
                <span
                  style={{ cursor: "pointer", fontSize: "12px", opacity: 0.4 }}
                  onClick={() => {
                    DoctorAPI.logout();
                  }}
                >
                  sign out
                </span>
              </p>
            </FailedBox>
          </FailedSubscriptionOverlayInner>
        </FailedSubscriptionOverlay>
      )}

      {/*{isSubscriptionExpired == false || (!hasPaywall()) ? null :*/}
      {/*    <FailedSubscriptionOverlay>*/}
      {/*        <FailedSubscriptionOverlayInner>*/}
      {/*            <FailedBox>*/}
      {/*                <h1>*/}
      {/*                    The Corsano Research Portal*/}
      {/*                    <br/>*/}
      {/*                    requires a subscription*/}
      {/*                </h1>*/}
      {/*                <p>*/}
      {/*                    Could you please contact <a href={'mailto:beatrice.gagliostri@corsano.com'}>beatrice.gagliostri@corsano.com</a>*/}
      {/*                    <br/>*/}
      {/*                    to obtain access*/}
      {/*                </p>*/}
      {/*            </FailedBox>*/}
      {/*        </FailedSubscriptionOverlayInner>*/}
      {/*    </FailedSubscriptionOverlay>*/}
      {/*}*/}
    </Wrapper>
  );
}

const FLeft = styled.div``;

const FailedSubscriptionOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`;

const FailedSubscriptionOverlayInner = styled.div`
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FailedBox = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  width: 680px;
  text-align: center;
`;

const FRight = styled.div``;

const OfflineBox = styled.div`
  border-radius: 6px;
  border: 2px solid #ff0900;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: red;
  // margin-bottom: 40px;
`;

const DebugSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  opacity: 0.5;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  :hover {
    opacity: 1;
  }
`;

const FooterPlaceholder = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 5px;
  }
`;

const DebugSwitcherPlaceholder = styled.div``;

const breakWidth = 1280;

const LinkItem = styled.div`
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  margin-right: 30px;
  color: #000f4b;
  font-size: 16px;
  line-height: 20px;
  border-bottom: 4px solid
    ${(props) =>
      props.selected == true ? getLabelsInfoData().tabs_color : "transparent"};
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentPlaceholder = styled.div`
  width: ${breakWidth}px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const TopLinkItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  margin-left: 30px;
  margin-right: 30px;
  opacity: ${(props) => (props.selected == true ? 1 : 0.4)};
  color: white;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const selectedImageSize = 66;
const notSelectedImageSize = 60;

const ItemImage = styled.img`
  width: ${(props) =>
    props.selected == true ? selectedImageSize : notSelectedImageSize}px;
  height: ${(props) =>
    props.selected == true ? selectedImageSize : notSelectedImageSize}px;
  margin-bottom: 20px;
`;

const ItemText = styled.div`
  text-align: center;
  font-weight: ${(props) => (props.selected == true ? "bold" : "normal")};
`;

const LinksInnerPlaceholder = styled.div`
  width: ${breakWidth}px;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const LinksArea = styled.div`
  background: rgb(34, 42, 50);
  width: 100vw;
  padding: 20px;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  height: calc(100% - 28px);
  margin-right: 70px;
  cursor: pointer;
`;

const CurrentUserName = styled.div`
  position: relative;
`;

const DropdownPlaceholder = styled.div`
  position: absolute;
  top: 40px;
  padding: 20px;
  background: white;
  z-index: 2;
  box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
  border-radius: 4px;
  width: 160px;
  right: 0px;
  text-align: right;
`;

const AvaCircle = styled.div`
  border-radius: 1000px;
  background: lightgrey;
  box-sizing: border-box;
  border: 1px solid grey;
  height: 34px;
  width: 34px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 5px;
  background-image: url(${(props) =>
    props.src == undefined ? voidUserImage : props.src});
`;

const TopRightPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  //
  //:hover {
  //  opacity: 0.5;
  //}
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background: rgb(250, 250, 252);
  font-family: "Avenir Next", "Prompt", sans-serif;
  box-sizing: border-box;
`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const TopLeftLinksPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
`;

const TopInnerPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: ${breakWidth}px;
  box-sizing: border-box;
  @media (max-width: ${breakWidth}px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 60px;
  box-sizing: border-box;
  width: 100vw;
  background: white;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

const MessagePlaceholder = styled.h2`
  font-size: 32px;
  line-height: 44px;
`;

const SubMessagePlaceholder = styled.div`
  font-size: 16px;
  line-height: 32px;
  margin-top: 10px;
  text-align: center;
`;
const LogoutSpan = styled.span`
  cursor: pointer;
  text-decoration: underline;
  :hover {
    opacity: 0.75;
  }
`;
