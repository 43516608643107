import { useRef, useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  xaxisTimeFormatter,
  LINE_COLORS,
  GRID_DASH_ARRAY,
  GRID_THICKNESS,
  NOT_WEARING_COLOR,
  CHARGING_COLOR,
  LOW_BATTERY_COLOR,
} from "../ui/templates";
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceArea,
  Label,
  Scatter,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";
import {
  addChargingFlagToData,
  CustomizedLabel,
  getChargingSpans,
} from "./BaselineUtils";

// const SPOTS_PER_DAY = 12 * 24;
// const MA_SIZE = 3;
const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  {
    value: "spot",
    label: "Spot Measurement",
    color: LINE_COLORS.spot,
    width: 2,
    show: true,
  },
];
const fillBRPMMissingSpot = (data, minutes = 10) => {
  if (!data || data.length === 0) {
    return;
  }
  let emptySpots = [];
  for (let i = 0; i < data.length - 1; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gap = moment(nextPoints).diff(currentPoints, "minutes");
    emptySpots.push(data[i]);
    if (gap >= minutes) {
      let newSlot = moment(currentPoints).add(1, "minutes").valueOf();
      emptySpots.push({
        raw: null,
        baseline: null,
        respiration_rate: null,
        t: newSlot,
      });
    } else {
    }
    // console.log(i);
  }
  emptySpots.push(data[data.length - 1]);
  return emptySpots;
};

const PatientDayBRPMBaselineTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    baselineWindow = 15,
    tz = 0,
    points = [],
    loading = false,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    externalMeasurements = [],
    lowBatterySpans = [],
  } = props;
  // console.log(externalMeasurements);

  const [ref, { width, height }] = useDimensions();
  //   const [loading, setLoading] = useState(false);
  const [RrRawData, setRrRawData] = useState([]);
  const [RrProcessedData, setRrProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
    spot: true,
  });
  const [dataLength, setDataLength] = useState(0);
  // const [batteryData, setBatteryData] = useState([]);
  // const [chargingSpans, setChargingSpans] = useState([]);
  const [visibleChargingSpanLabel, setVisibleChargingSpanLabel] = useState(-1);
  const [visibleLowBatterySpanLabel, setVisibleLowBatterySpanLabel] =
    useState(-1);
  const [visibleNotWearingSpanLabel, setVisibleNotWearingSpanLabel] =
    useState(-1);
  const [visibleMovingSpanLabel, setVisibleMovingSpanLabel] = useState(-1);
  const refY = useRef(null);
  const [domainY, setDomainY] = useState(undefined);
  const [refDelta, setRefDelta] = useState(0);

  // const [extraLines, setExtraLines] = useState([]);

  const fillBRPMMissingSpotWithSpans = (data, spo2FrequencyValue) => {
    if (!data || data.length === 0) {
      return;
    }
    const minGap = spo2FrequencyValue === 0 ? 31 : 10;
    let emptySpots = [];
    for (let i = 0; i < data.length - 1; i++) {
      let currentPoints = data[i].t;
      let nextPoints = data[i + 1].t;
      let gap = moment(nextPoints).diff(currentPoints, "minutes");
      emptySpots.push(data[i]);
      if (gap >= minGap) {
        let newSlot = moment(currentPoints).add(1, "seconds").valueOf();

        // Checking if gap is due to not wearing or not
        let notWearing, charging;
        const chargingspan = chargingSpans.map((x, i) => {
          const _from = x?.from;
          const _to = x?.to;
          if (_from >= currentPoints && _to <= nextPoints) {
            charging = true;
          }
          // console.log({ currentPoints, nextPoints, _from, _to });
          return x;
        });
        const notwearingspan = gapAndNotWearingSpans.map((x, i) => {
          const _from = x?.from;
          const _to = x?.to;
          if (_from >= currentPoints && _to <= nextPoints) {
            notWearing = true;
          }
          // console.log({ currentPoints, nextPoints, _from, _to });
          return x;
        });
        // console.log({ notwearingspan, chargingspan, charging, notWearing });

        emptySpots.push({
          spo2: null,
          baseline: null,
          t: newSlot,
          from_filled: true,
          charging: charging,
          notWearing: notWearing,
        });
      } else {
      }
    }
    // console.log({spo2FrequencyValue});

    emptySpots.push(data[data.length - 1]);
    return emptySpots;
  };

  useEffect(() => {
    // const _points = points.map((s) => {
    //   return {
    //     ...s,
    //     t: moment(s.timestamp).valueOf(),
    //     slot: moment(s.timestamp).valueOf(),
    //     date: moment(s.timestamp).format("HH:mm:ss"),
    //     respiration_rate: s.resp_q > 0 ? s.respiration_rate : null,
    //   };
    // });
    if (!points || points.length === 0) {
      return;
    }

    // let _points = points.filter((x) => x?.spo2_q && x?.spo2);
    // console.log({ spans });
    // setBatteryData(__points.filter((x) => x.isCharging));
    // console.log({ points });

    // let extraLinesList = DrawHelper.getExtraDottedChartsData(
    //   points,
    //   "respiration_rate"
    // );
    // console.log(extraLinesList.length );
    // let RrWithGaps = points;
    // for (let sp of RrWithGaps) {
    //   for (let el in extraLinesList) {
    //     const slot1 = extraLinesList[el]["points"][0].slot;
    //     const slot2 = extraLinesList[el]["points"][1].slot;
    //     if (slot1 === sp.slot) {
    //       sp[`respiration_rate_gap_${el}`] =
    //         extraLinesList[el]["points"][0][`respiration_rate_gap_${el}`];
    //       sp[`baseline_gap_${el}`] =
    //         extraLinesList[el]["points"][0][`baseline_gap_${el}`];
    //     }
    //     if (slot2 === sp.slot) {
    //       sp[`respiration_rate_gap_${el}`] =
    //         extraLinesList[el]["points"][1][`respiration_rate_gap_${el}`];
    //       sp[`baseline_gap_${el}`] =
    //         extraLinesList[el]["points"][1][`baseline_gap_${el}`];
    //     }
    //   }
    // }
    // console.log({ extraLinesList });

    let _points = points.filter(
      // (x) => x.resp_q && x.resp_q > 0 && x.resp_q <= 4
      (x) => x.resp_q && x.respiration_rate
    );
    // console.log("RR", _points);
    // console.log(_points.length);
    const gaps = _points.slice(1).map((entry, index) => {
      const gapInMinutes = (entry.slot - _points[index].slot) / (1000 * 60);
      return {
        start: _points[index].timestamp,
        end: entry.timestamp,
        gapInMinutes,
        isMoreThan29Minutes: gapInMinutes > 29,
      };
    });

    const showDot =
      gaps.filter((x) => x.isMoreThan29Minutes).length === gaps.length;
    // console.log(gaps.filter((x) => x.isMoreThan29Minutes).length, gaps.length);

    // console.log(gaps, showDot);
    // const showDot = false
    // console.log(fillRRPMMissingSpot([]));
    if (!showDot) {
      // _points = fillBRPMMissingSpot(_points) || [];
      _points = fillBRPMMissingSpotWithSpans(_points) || [];
    } else if (showDot) {
      // _points = fillBRPMMissingSpot(_points, 32) || [];
      _points = fillBRPMMissingSpotWithSpans(_points, 32) || [];
    }

    // console.log("RR2", _points);
    let externalWithNulls = [];
    externalMeasurements.forEach((x, i) => {
      const spotObj = {
        ...x,
        brpm: null,
        slot: +x.timestamp,
        respiration_rate_spot: x.brpm,
        t: +x.timestamp,
      };
      const nullObj = {
        respiration_rate_spot: null,
        t: +x.timestamp + 1,
      };
      externalWithNulls.push(spotObj);
      externalWithNulls.push(nullObj);
    });

    _points = [..._points, ...externalWithNulls];
    // console.log({ externalMeasurements });

    setRrRawData(_points);
    setRrProcessedData(_points);

    // console.log("_points", _points);

    // setDataLength(_points.filter((x) => x?.respiration_rate).length);
    // console.log(_points);
  }, [date, uuid, points, externalMeasurements]);

  let extraLines = DrawHelper.getExtraDottedChartsData(
    RrProcessedData,
    "respiration_rate"
  );
  extraLines = DrawHelper.getExtraDottedChartsData(RrProcessedData, "baseline");

  let RrWithGaps = RrProcessedData;

  for (let sp of RrWithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`respiration_rate_gap_${el}`] =
          extraLines[el]["points"][0][`respiration_rate_gap_${el}`];
        sp[`baseline_gap_${el}`] =
          extraLines[el]["points"][0][`baseline_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`respiration_rate_gap_${el}`] =
          extraLines[el]["points"][1][`respiration_rate_gap_${el}`];
        sp[`baseline_gap_${el}`] =
          extraLines[el]["points"][1][`baseline_gap_${el}`];
      }
    }
  }
  // console.log(extraLines);
  // console.log(RrProcessedData);

  useEffect(() => {
    const yMap = refY.current?.state?.yAxisMap;
    let domain = [];
    let delta = 0.575;
    if (yMap) {
      domain = yMap[0]?.domain;
    }
    if (domain && domain.length == 2) {
      delta = (domain[1] - domain[0]) / 40;
    }
    setRefDelta(delta);
  }, [refY, RrProcessedData]);

  let xticks = BaselineHelper.getXaxisTicksEachHour(
    moment(date).startOf("day")
  );
  let yticks = [];
  // let RrList = RrProcessedData.map((x) => x.Rr).filter((x) => x !== null);

  // let maxRr = Math.max(...RrList);
  //   let ymax = Math.floor(maxRr / 10) * 10;
  //   if (ymax >= 30) {
  //     yticks = [0, 8, 16, 24, 30, 30];
  //   } else if (ymax < 90 && ymax >= 80) {
  //     yticks = [80, 85, 90, 95, 100];
  //   } else if (ymax < 80 && ymax >= 70) {
  //     yticks = [70, 80, 90, 100];
  //   } else {
  //     const gap = Math.round((100 - ymax) / 5);
  //     for (let i = ymax; i <= 100; i += gap) {
  //       yticks.push(i);
  //     }
  //     yticks.push(100);
  //   }

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();

  // console.log("RrProcessedData", RrProcessedData);
  // console.table(
  //   RrProcessedData.slice(RrProcessedData.length - 20, RrProcessedData.length),
  //   ["respiration_rate", "baseline", "date", "slot", "steps"]
  // );

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }

  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }

  // console.log({ RrProcessedData });

  return (
    <Wrapper ref={ref}>
      {!RrProcessedData || RrProcessedData.length === 0 ? (
        <></>
      ) : (
        <div>
          <ComposedChart
            ref={refY}
            width={width}
            height={height}
            data={RrProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeDasharray={GRID_DASH_ARRAY}
              strokeWidth={GRID_THICKNESS}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              tickSize={10}
              //   verticalCoordinatesGenerator={(props) => {
              dataKey={"t"}
              ticks={xticks}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"respiration_rate"}
              // ticks={yticks}
              //   domain={([min, max]) => [0, max >= 20 ? max : 20]}
              domain={([min, max]) => [
                0,
                max > 20 ? Math.round(max + 1) + 1 : 20,
              ]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <Tooltip content={<BrpmTooltip />} />
            <Line
              type="monotone"
              dataKey="baseline"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              activeDot={true}
              connectNulls={false}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="respiration_rate"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={0.9}
              connectNulls={false}
              hide={showLine["raw"] ? false : true}
              dot={false}
              activeDot={true}
              isAnimationActive={false}
            />
            <Line
              // shape="cross"
              // points={
              //   externalMeasurements.map((em) => {
              //     return {
              //       cx: 12,
              //       cy: 12,
              //       r: 4,
              //       payload: { x: +em.t, y: +em.respiration_rate_spot, z: 9 },
              //     };
              //   })
              // }
              id="scatter"
              dataKey="respiration_rate_spot"
              name="spot"
              stroke={LINE_COLORS.spot}
              strokeWidth={0.9}
              connectNulls={false}
              hide={showLine["spot"] ? false : true}
              dot={true}
              activeDot={true}
              isAnimationActive={false}
            />
            {extraLines.map((l, i) => {
              // console.log(l);
              const withGap = RrProcessedData.filter(
                (x) => x?.[`baseline_gap_${i}`]
              );
              const startIndex = RrProcessedData.indexOf(withGap[0]);
              const endIndex = RrProcessedData.indexOf(withGap[1]);

              // console.log({ gapIndex: i, withGap, startIndex, endIndex });
              const slice = RrProcessedData.slice(startIndex, endIndex + 1);
              let nullPoint;
              if (slice && slice.length === 3) {
                nullPoint = slice[1];
                // console.log({ nullPoint });
                if (nullPoint?.notWearing || nullPoint?.charging) {
                  // console.log({ falsep: nullPoint });
                  return;
                }
              }

              return (
                <Line
                  key={i}
                  type={"monotone"}
                  // dataKey={`respiration_rate_gap_${i}`} // for raw line
                  dataKey={`baseline_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  stroke={LINE_COLORS.baseline}
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  //   hide={showLine["raw"] ? false : true}
                  // hide={true}
                />
              );
            })}
            {/* <Legend /> */}

            {chargingSpans.map((span, i) => {
              const yMap = refY.current?.state?.yAxisMap;
              let domain = [];
              let delta = 0.0;
              if (yMap) {
                domain = yMap[0]?.domain;
              }
              // console.log(yMap);
              // console.log(domain);
              if (domain && domain.length == 2) {
                delta = (domain[1] - domain[0]) / 40;
              }

              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = delta ? -delta : -0.5;
              const y2 = delta ? +delta : +0.5;
              if (span.from < startOfDay && span.to < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    // console.log("charign!");
                    if (visibleChargingSpanLabel > -1) {
                      setVisibleChargingSpanLabel(-1);
                    } else {
                      setVisibleNotWearingSpanLabel(-1);
                      setVisibleChargingSpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={CHARGING_COLOR}
                >
                  <Label
                    // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                    //   x2
                    // ).format("HH:mm")})`}
                    content={
                      <CustomizedLabel x1={x1} x2={x2} reason={"Charging"} />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleChargingSpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            {lowBatterySpans.map((span, i) => {
              // console.log({ span });

              const yMap = refY.current?.state?.yAxisMap;
              let domain = [];
              let delta = 0.0;
              if (yMap) {
                domain = yMap[0]?.domain;
              }
              // console.log(yMap);
              // console.log(domain);
              if (domain && domain.length == 2) {
                delta = (domain[1] - domain[0]) / 40;
              }

              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = delta ? -delta : -0.5;
              const y2 = delta ? +delta : +0.5;
              if (span.from < startOfDay && span.to < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    // console.log("charign!");
                    if (visibleLowBatterySpanLabel > -1) {
                      setVisibleLowBatterySpanLabel(-1);
                    } else {
                      setVisibleNotWearingSpanLabel(-1);
                      setVisibleChargingSpanLabel(-1);
                      setVisibleLowBatterySpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                    setVisibleLowBatterySpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={LOW_BATTERY_COLOR}
                >
                  <Label
                    // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                    //   x2
                    // ).format("HH:mm")})`}
                    content={
                      <CustomizedLabel
                        x1={x1}
                        x2={x2}
                        reason={"Battery <= 5"}
                      />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleLowBatterySpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            {gapAndNotWearingSpans.map((span, i) => {
              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? -refDelta : -0.5;
              const y2 = refDelta ? +refDelta : +0.5;
              if (span.from < startOfDay && x2 < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  key={i}
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    if (visibleNotWearingSpanLabel > -1) {
                      setVisibleNotWearingSpanLabel(-1);
                    } else {
                      setVisibleChargingSpanLabel(-1);
                      setVisibleNotWearingSpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={NOT_WEARING_COLOR}
                >
                  <Label
                    // value={`Not wearing (${moment(x1).format(
                    //   "HH:mm"
                    // )} - ${moment(x2).format("HH:mm")})`}
                    content={
                      <CustomizedLabel x1={x1} x2={x2} reason={"Not wearing"} />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleNotWearingSpanLabel ? "visible" : "hidden"
                    }
                    // content={() => {
                    //   return <div>Not wearing</div>;
                    // }}
                  ></Label>
                </ReferenceArea>
              );
            })}
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              if (externalMeasurements.length === 0 && f.value === "spot") {
                return null;
              }
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel
                    $color={f.color}
                    $bstyle={f?.bstyle ? f.bstyle : "solid"}
                    $theme={theme}
                  >
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
    </Wrapper>
  );
};

export default PatientDayBRPMBaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const BrpmTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let rr = payload[0]?.payload?.respiration_rate;
    let baseline = payload[0]?.payload?.baseline;
    if (rr) {
      rr = rr.toFixed(0);
    }
    let respiration_rate_spot = payload[0]?.payload?.respiration_rate_spot;
    if (respiration_rate_spot) {
      respiration_rate_spot = respiration_rate_spot.toFixed(0);
    }
    // console.log({ respiration_rate_spot });

    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        {respiration_rate_spot ? (
          <TooltipRow className="label">{`Spot Measurement: ${respiration_rate_spot}`}</TooltipRow>
        ) : (
          <>
            <TooltipRow className="label">{`Raw BRPM: ${rr}`}</TooltipRow>
            <TooltipRow className="label">{`Baseline: ${baseline}`}</TooltipRow>
            <TooltipRow className="label">{`Q: ${payload[0].payload.resp_q}`}</TooltipRow>
            <TooltipRow className="label">{`Baseline Q: ${payload[0].payload.respiration_rate_baseline_q}`}</TooltipRow>
          </>
        )}
        {/* <strong className="label">{`Adaptive: ${payload[0].payload.adpt}`}</strong>
        <br /> */}
      </TooltipWrapper>
    );
  }

  return null;
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.$color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
