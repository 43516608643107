import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import { Code } from "react-content-loader";
import NiceModal from "../../modals/NiceModal";
import GroupsAPI from "../../../api/GroupsAPI";
import UpdateGroupForm from "../forms/UpdateGroupForm";
import CommonHelper from "../../../helpers/CommonHelper";

import pencil from "../../../assets/images/edit_grey.svg";
import trashImage from "../../../assets/images/trash.svg";
import lockImage from "../../../assets/images/key_icon.svg";
import { useMappedState } from "redux-react-hook";
import vitalSettings from "../../../assets/images/vital_settings_2.svg";
import StudyVitalParametersSettingsPanel from "../../vital_parameters/panels/StudyVitalParametersSettingsPanel";
import NiceConfirm from "../../modals/NiceConfirm";
import { toast } from "react-hot-toast";
import FDAMetricsSelectorTool from "../../fake/tools/FDAMetricsSelectorTool";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import { isDebugMode } from "../../../helpers/UiHelper";
import warningImg from "../../../assets/images/warning-sign.svg";
import { Button } from "../../ui/templates";
import ls from "local-storage";

export function getLabelsInfoData() {
  let isCM = false;
  let isAfib = false;
  let isPortal = false;
  try {
    isCM =
      window.location.href.indexOf("teams.cardiomood") > -1 ||
      window.location.href.indexOf("coach.cardiomood") > -1;
    isPortal = window.location.href.indexOf("portal") > -1;
    isAfib = window.location.href.indexOf("af.") > -1;
  } catch (exc) {}
  return {
    group_name: isCM
      ? "Group"
      : isPortal == true || isAfib == true
      ? "Department"
      : "Study",
    groups_name: isCM
      ? "Groups"
      : isPortal == true || isAfib == true
      ? "Departments"
      : "Studies",
    add_group_name: isAfib
      ? "Add department"
      : isCM
      ? "Add group"
      : isPortal == true
      ? "Add"
      : "Add study",
    vital_parameters_enabled: isCM ? false : true,
    vouchers_enabled: isCM ? false : true,
    stats_enabled: isAfib ? false : isCM ? true : true,
    live_enabled: isCM ? true : false,
    bracelets_enabled: isCM || isPortal ? false : true,
    export_enabled: isAfib ? false : isCM || isPortal ? false : true,
    invite_enabled: isCM ? true : false,
    sessions_enabled: isCM ? false : true,
    surveys_enabled: isCM ? false : true,
    bioz_enabled: isCM ? false : true,
    spiro_enabled: isCM ? false : true,
    weight_enabled: isCM ? false : true,
    bp_enabled: isCM ? false : true,
    tabs_color: isCM ? "#C00100" : "rgb(1, 194, 169)",
    health_battery_enabled: isDebugMode() ? true : false,
  };
}
export const GROUP_CACHE_VALID_SECONDS = 60 * 60 * 24; // cache valid for half day
export const GROUP_CACHE_LS_KEY = "grps";
export const GROUP_CACHE_TIMESTAMP_LS_KEY = "grps_ts";
export const PATIENTS_CACHE_VALID_SECONDS = 60 * 60 * 24;
export const PATIENTS_CACHE_LS_KEY = "pts";
export const PATIENTS_CACHE_TIMESTAMP_LS_KEY = "pts_ts";

export default function GroupsPanel(props) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [editId, setEditId] = useState(undefined);
  const [deleteId, setDeleteId] = useState(undefined);
  const [lockId, setLockId] = useState(undefined);
  const [vitalCode, setVitalCode] = useState(undefined);
  const [deleting, setDeleting] = useState(false);
  const [
    showUsingDeprecatedFrequenciesModal,
    setShowUsingDeprecatedFrequenciesModal,
  ] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const { currentUserObj, email, isDemo, theme, role } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com",
        theme: state.ui.theme,
        role: state.ui.role,
      };
    })
  );

  const isHCPAdmin = role === "hcp-admin";

  useEffect(() => {
    const startTime = performance.now();
    if (localStorage.hasOwnProperty(GROUP_CACHE_LS_KEY)) {
      // console.log("has grps key");
      const _time = +ls.get(GROUP_CACHE_TIMESTAMP_LS_KEY);
      const _now = Date.now();
      const cachedDuration = (_now - _time) / 1000;
      // console.log(cachedDuration);
      if (cachedDuration < GROUP_CACHE_VALID_SECONDS) {
        const lastCachedGroup = localStorage.getItem(GROUP_CACHE_LS_KEY);
        const parsedLastCachedGroup = JSON.parse(lastCachedGroup);
        setGroups(parsedLastCachedGroup);
        setLoading(false);
        // console.log("Using grps cache");
        return;
      } else {
        ls.remove(GROUP_CACHE_TIMESTAMP_LS_KEY);
        ls.remove(GROUP_CACHE_LS_KEY);
      }
    }

    // console.log("NOT USING CACHE > LOADING");

    setLoading(true);

    GroupsAPI.getGroups().then((arr) => {
      // console.log("groups:", arr);
      const lastGetGroupsTime = Date.now();
      setLoading(false);
      const endTime = performance.now();
      console.debug(
        `GroupsPanel loaded in ${(endTime - startTime).toFixed(1)} ms`
      );
      const sortedArr = arr.sort((a, b) => +b.timestamp - +a.timestamp);
      setGroups(sortedArr);
      ls.set(GROUP_CACHE_LS_KEY, sortedArr);
      ls.set(GROUP_CACHE_TIMESTAMP_LS_KEY, +lastGetGroupsTime);
    });
  }, []);

  if (loading == true && groups.length == 0) {
    return (
      <div>
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  let editGroup =
    editId == undefined ? undefined : groups.filter((x) => x.id == editId)[0];
  let deletingGroup =
    deleteId == undefined
      ? undefined
      : groups.filter((x) => x.id == deleteId)[0];
  let namingsMap = getLabelsInfoData();
  let vitalGroup =
    vitalCode == undefined
      ? undefined
      : groups.filter((x) => x.code == vitalCode)[0];
  // console.log("ROLES", role);

  return (
    <Wrapper>
      <TopPlaceholder>
        <Heading className="heading">{namingsMap.groups_name}</Heading>
        {isHCPAdmin ? (
          <AddButton
            className="add-button"
            onClick={() => {
              setAddModalVisible(true);
            }}
          >
            {namingsMap.add_group_name}
          </AddButton>
        ) : null}
      </TopPlaceholder>

      <GroupsListPlaceholder>
        {groups.map((a, i) => {
          // console.log(a);
          let usingDeprecatedFrequencies = false;
          let body = "";
          const uiSettings = a?.uiSettings || {};
          const vital_parameters = uiSettings?.vital_parameters || {};
          const {
            activity,
            heart_rate,
            spo2,
            respiration_rate,
            body_temperature,
          } = vital_parameters;
          // console.log({ vital_parameters, code: a.code });

          if (
            activity == 1 ||
            activity == 2 ||
            spo2 == 1 ||
            spo2 == 4 ||
            respiration_rate == 1 ||
            respiration_rate == 2 ||
            heart_rate == 1 ||
            heart_rate == 2 ||
            body_temperature == 1 ||
            body_temperature == 2
          ) {
            usingDeprecatedFrequencies = true;
            body = composeEmail({
              activity,
              spo2,
              respiration_rate,
              heart_rate,
              body_temperature,
              code: a?.code,
              email: email,
            });
          }

          return (
            <GroupItem key={a.id} className="group-item">
              <ItemLeft>
                <ItemTopPlaceholder>
                  <NamePlaceholder
                    className="group-name"
                    onClick={() => {
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${a.code}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${a.code}`);
                      }
                    }}
                  >
                    {a.name}
                  </NamePlaceholder>

                  {isHCPAdmin ? (
                    <EditImg
                      className="group-info-icon"
                      src={pencil}
                      onClick={() => {
                        setEditId(a.id);
                      }}
                    />
                  ) : null}
                </ItemTopPlaceholder>
                {a.description == undefined || a.description == "" ? null : (
                  <ItemBottomPlaceholder
                    onClick={() => {
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${a.code}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${a.code}`);
                      }
                    }}
                  >
                    {a.description}
                  </ItemBottomPlaceholder>
                )}
              </ItemLeft>
              <ItemRight>
                <CodePlaceholder>
                  {usingDeprecatedFrequencies && (
                    <div
                      style={{
                        height: "1em",
                        width: "1em",
                        marginRight: "10px",
                        display:
                          window.location.href.indexOf("study.corsano.com") > 0
                            ? ""
                            : "none",
                      }}
                      onClick={() => {
                        setShowUsingDeprecatedFrequenciesModal(a.code);
                        setEmailBody(body);
                      }}
                    >
                      <img src={warningImg} />
                    </div>
                  )}
                  {namingsMap.vital_parameters_enabled == false ? null : (
                    <GearImg
                      className="group-info-icon"
                      src={vitalSettings}
                      onClick={() => {
                        setVitalCode(a.code);
                      }}
                    />
                  )}

                  {isHCPAdmin ? (
                    <>
                      <GearImg
                        className="group-info-icon"
                        src={trashImage}
                        onClick={() => {
                          if (isDemo == true) {
                            return window.alert(
                              "You can not delete the demo group."
                            );
                          }
                          setDeleteId(a.id);
                        }}
                      />
                    </>
                  ) : null}
                  <span
                    style={{ cursor: "pointer", width: "90px" }}
                    onClick={() => {
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${a.code}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${a.code}`);
                      }
                    }}
                  >
                    {a.code}
                  </span>
                </CodePlaceholder>
              </ItemRight>
            </GroupItem>
          );
        })}
      </GroupsListPlaceholder>

      {addModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setAddModalVisible(false);
          }}
        >
          <AddInnerPlaceholder>
            <UpdateGroupForm
              loading={loading}
              onSave={async (d) => {
                if (isDemo == true) {
                  window.alert(
                    "This is a demo. Please contact admin for full access."
                  );
                  return;
                }
                if (loading == true) {
                  return;
                }
                setLoading(true);
                await GroupsAPI.createGroup({
                  ...d,
                  alarmsEnabled: window.location.href.indexOf("portal") > -1,
                });
                const lastGetGroupsTime = Date.now();
                let arr = await GroupsAPI.getGroups();
                const sortedArr = arr.sort(
                  (a, b) => +b.timestamp - +a.timestamp
                );
                setGroups(sortedArr);
                ls.set(GROUP_CACHE_LS_KEY, sortedArr);
                ls.set(GROUP_CACHE_TIMESTAMP_LS_KEY, +lastGetGroupsTime);

                setLoading(false);
                setAddModalVisible(false);
              }}
            />
          </AddInnerPlaceholder>
        </NiceModal>
      )}

      {showUsingDeprecatedFrequenciesModal && (
        <NiceModal
          onClose={() => {
            setShowUsingDeprecatedFrequenciesModal("");
            setEmailBody("");
          }}
        >
          <div
            style={{
              width: "41vw",
              fontSize: "1em",
              fontWeight: 500,
              margin: "10px 50px",
            }}
          >
            <div
              style={{
                fontSize: "22px",
                fontWeight: 700,
                display: "flex",
                justifyContent: "center",
              }}
            >
              Update 1/sec and 1/10 sec
            </div>
            <p>Dear Principal Investigator,</p>
            <p>
              Corsano will deprecate 1/sec and 1/10 sec frequencies in January
              2025. Please adjust your research study settings to 1/min. By
              clicking the link below, an email with the proposed changes will
              be sent to us automatically.
            </p>
            <p>
              Corsano will review and respond to your request within 24 hours
              (on working days).
            </p>
            <p>
              <a
                href={`mailto:mburel@corsano.com?subject=${"Update 1/sec and 1/10 "}&body=${emailBody}`}
                target="_self"
              >
                [Send Request Email]
              </a>{" "}
            </p>
            <p>Thank you for your cooperation.</p>
            <p>
              Best regards, <br></br>The Corsano Team{" "}
            </p>
          </div>
          <Button
            style={{ width: "30%", margin: "0 auto", marginTop: "1rem" }}
            onClick={() => {
              setShowUsingDeprecatedFrequenciesModal("");
              setEmailBody("");
            }}
          >
            Close
          </Button>
        </NiceModal>
      )}

      {editGroup == undefined ? null : (
        <NiceModal
          onClose={() => {
            setEditId(undefined);
          }}
        >
          <AddInnerPlaceholder>
            <UpdateGroupForm
              {...editGroup}
              loading={loading}
              onSave={async (d) => {
                if (isDemo == true) {
                  window.alert(
                    "This is a demo. Please contact admin for full access."
                  );
                  return;
                }
                // console.log({ d, code: editGroup.code });

                if (loading == true) {
                  return;
                }
                setLoading(true);
                await GroupsAPI.updateGroup({ ...d, code: editGroup.code });
                let arr = await GroupsAPI.getGroups();
                const lastGetGroupsTime = Date.now();
                const sortedArr = arr.sort(
                  (a, b) => +b.timestamp - +a.timestamp
                );
                setGroups(sortedArr);
                ls.set(GROUP_CACHE_LS_KEY, sortedArr);
                ls.set(GROUP_CACHE_TIMESTAMP_LS_KEY, +lastGetGroupsTime);
                setLoading(false);
                setEditId(undefined);
              }}
            />
          </AddInnerPlaceholder>
        </NiceModal>
      )}

      {deletingGroup == undefined ? null : (
        <NiceConfirm
          heading={`Deleting study "${deletingGroup?.name}"`}
          subHeading={"Please confirm that you want to delete this study."}
          loading={deleting}
          onConfirm={async () => {
            if (deleting == true) {
              return;
            }
            setDeleting(true);
            let studyName = deletingGroup?.name;
            await GroupsAPI.deleteGroup(deletingGroup?.id);
            setDeleting(false);
            const lastGetGroupsTime = Date.now();
            const groupsAfterDeleting = groups.filter((x) => x.id != deleteId);
            setGroups(groupsAfterDeleting);
            ls.set(GROUP_CACHE_LS_KEY, groupsAfterDeleting);
            ls.set(GROUP_CACHE_TIMESTAMP_LS_KEY, +lastGetGroupsTime);
            setDeleteId(undefined);
            toast(`Study "${studyName}" has been deleted`);
          }}
          onCancel={() => {
            setDeleteId(undefined);
          }}
        ></NiceConfirm>
      )}

      {vitalCode == undefined ? null : (
        <NiceModal
          onClose={() => {
            setVitalCode(undefined);
          }}
        >
          <VitalInnerContent>
            <VitalSettingsHeading>
              <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                {`${vitalCode}`}
              </span>

              {/*{window.location.href.indexOf('portal') == -1 ? null :*/}
              {true == false ? null : (
                <div>
                  <div className="setting-label" style={{ padding: 10 }}>
                    <>
                      {`HCP Code:`}

                      <div
                        className="setting-label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {vitalGroup.hcpCode}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontStyle: "italic",
                          opacity: 0.7,
                        }}
                        className="setting-label-deep"
                      >
                        The HCP Code can be updated under Settings/Profile in
                        the Corsano App
                      </div>
                    </>

                    <div
                      style={{ marginTop: 20 }}
                      className="setting-label"
                    >{`Patient Code:`}</div>

                    <div
                      className="setting-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {vitalGroup.code}
                    </div>

                    <div
                      style={{
                        fontSize: "14px",
                        fontStyle: "italic",
                        opacity: 0.7,
                      }}
                      className="setting-label-deep"
                    >
                      Please ask the patient to enter the Patient Code under
                      Settings/Profile in the Corsano App
                    </div>
                  </div>

                  {/*{window.location.href.indexOf("portal") == -1 ? null : role !== "Patient" ? (*/}
                  {/*    <FDAMetricsSelectorTool code={vitalCode} role={role}/>) : null}*/}
                </div>
              )}
              {/*<br/>*/}
              {/*{'Vital parameters'}*/}
            </VitalSettingsHeading>

            {isHCPAdmin ? (
              <VitalPanelPlaceholder>
                <StudyVitalParametersSettingsPanel
                  isPatientPortal={window.location.href.indexOf("portal") > -1}
                  isHCPAdmin={isHCPAdmin}
                  code={vitalCode}
                  {...vitalGroup}
                  groupId={vitalGroup?.id}
                  onSaved={() => {
                    setVitalCode(undefined);
                  }}
                  hcpEmail={email}
                />
              </VitalPanelPlaceholder>
            ) : null}
          </VitalInnerContent>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const VitalPanelPlaceholder = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
`;

const VitalInnerContent = styled.div`
  width: 820px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
`;

const ItemBottomPlaceholder = styled.div`
  opacity: 0.8;
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
`;

const VitalSettingsHeading = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

const NamePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const CodePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GearImg = styled.img`
  height: 26px;
  width: 26px;
  margin-right: 20px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const HcpCodeImg = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 20px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const AddInnerPlaceholder = styled.div`
  width: 600px;
  box-sizing: border-box;
  padding: 20px;
`;

const EditImg = styled.img`
  cursor: pointer;
  opacity: 0.7;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div``;

const Heading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const AddButton = styled.div`
  background: #147aff;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLeft = styled.div`
  flex: 1;
`;

const ItemRight = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const GroupItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 2px solid rgba(135, 152, 173, 0.15);
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;

const GroupsListPlaceholder = styled.div``;

const newLineBreak = "%0D%0A";
function composeEmail(vitalParamsObj) {
  // straightforward to to generate email
  const {
    activity,
    spo2,
    respiration_rate,
    heart_rate,
    body_temperature,
    code,
    email,
  } = vitalParamsObj;
  let body = `
Study Code: ${code}${newLineBreak}
HCP email address: ${email}${newLineBreak}
  `;
  if (activity == 1) {
    body = `${body}${newLineBreak}
Activity 10/sec => 1/min${newLineBreak}
    `;
  }
  if (activity == 2) {
    body = `${body}${newLineBreak}
Activity 1/sec => 1/min${newLineBreak}
    `;
  }
  if (spo2 == 4) {
    body = `${body}${newLineBreak}
SpO2 10/sec => 1/min${newLineBreak}
    `;
  }
  if (spo2 == 1) {
    body = `${body}${newLineBreak}
SpO2 1/sec => 1/min${newLineBreak}
    `;
  }
  if (respiration_rate == 1) {
    body = `${body}${newLineBreak}
BRPM 10/sec => 1/min${newLineBreak}
    `;
  }
  if (respiration_rate == 2) {
    body = `${body}${newLineBreak}
BRPM 1/sec => 1/min${newLineBreak}
    `;
  }
  if (heart_rate == 1) {
    body = `${body}${newLineBreak}
Pulse Rate 10/sec => 1/min${newLineBreak}
    `;
  }
  if (heart_rate == 2) {
    body = `${body}${newLineBreak}
Pulse Rate 1/sec => 1/min${newLineBreak}
    `;
  }
  if (body_temperature == 1) {
    body = `${body}${newLineBreak}
Temperature 10/sec => 1/min${newLineBreak}
    `;
  }
  if (body_temperature == 2) {
    body = `${body}${newLineBreak}
Temperature 1/sec => 1/min${newLineBreak}
    `;
  }
  return body;
}
