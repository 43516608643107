import { isDebugMode } from "../../../../helpers/UiHelper";
import SimpleBpmSlotsChart from "../../../heart_rate/tools/SimpleBpmSlotsChart";
import PatientDaySpo2BaselineTool from "../../../baselines/PatientDaySpo2BaselineTool";
import PatientDaySpo2BaselineDebugsTool from "../../../baselines/PatientDaySpo2BaselineDebugsTool";
import VitalTrendsChartTool from "../../../trends/tools/VitalTrendsChartTool";
import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import { ComplianceHeading } from "../UiComponents";
import moment from "moment";
import { isSameDay } from "../../../baselines/BaselineUtils";

export default function Spo2Panel(props) {
  const {
    id,
    date,
    summary,
    theme,
    baselineWindow,
    allTypeBaselineMap,
    tz,
    vitalSummaryInRange,
    loadingAllTypeBaseline,
    motionMarkerTimestamps,
    loading,
    vitalParams = { spo2: undefined },
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    lowBatterySpans = [],
    externalMeasurements = [],
  } = props;

  const spo2FrequencyValue = vitalParams?.spo2;

  return (
    <div className="spo2-tab-container" style={{ width: "100%" }}>
      {summary === undefined ||
      summary?.spo2 === undefined ||
      summary?.spo2.slots === undefined ? (
        <SimpleNoDataMessageTool
          loading={loading}
          message={"Sorry, there is no SpO2 data for this day."}
        />
      ) : (
        <div className="spo2-baseline-area" style={{ height: 380 }}>
          <div
            className="spo2-baseline-top-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ComplianceHeading className="patient-statistics-heading">
              Spo2
            </ComplianceHeading>
            <InfoToolBadge type={"SPO2"} />
          </div>
          <PatientDaySpo2BaselineTool
            uuid={id}
            date={date}
            theme={theme}
            baselineWindow={baselineWindow}
            tz={tz}
            loading={loadingAllTypeBaseline}
            spo2FrequencyValue={spo2FrequencyValue}
            points={allTypeBaselineMap["spo2"]}
            hasNoData={
              summary === undefined ||
              summary?.spo2 === undefined ||
              summary?.spo2.slots === undefined
            }
            gapAndNotWearingSpans={gapAndNotWearingSpans}
            chargingSpans={chargingSpans}
            lowBatterySpans={lowBatterySpans}
            externalMeasurements={externalMeasurements.filter((x) =>
              isSameDay(x.timestamp, date)
            )}
          />
        </div>
      )}

      <div className="spo2-trend-area">
        <ComplianceHeading className="patient-statistics-heading">
          Trends
        </ComplianceHeading>
        <div
          style={{
            width: "100%",
            height: 400,
          }}
        >
          <VitalTrendsChartTool
            uuid={id}
            dataType={"spo2"}
            points={vitalSummaryInRange}
          />
        </div>
      </div>
      {isDebugMode() ? (
        <div
          className="spo2-test-area"
          style={{ height: 300, marginTop: "0rem" }}
        >
          <SimpleBpmSlotsChart
            yDoman={[60, 100]}
            slots={
              summary?.spo2?.slots
                ? summary?.spo2?.slots
                    .map((x) => ({ ...x, t: x.timestamp }))
                    .map((x) => ({
                      ...x,
                      spo2: x.spo2 === 0 ? null : x.spo2,
                    }))
                : []
            }
            dataKey={"spo2"}
            min={+moment(date).startOf("day")}
            max={+moment(date).startOf("day").add(1, "day").valueOf()}
          />
        </div>
      ) : null}
      {/* {isDebugMode() ? (
        <div className="spo2-baseline-area" style={{ height: 1 * 380 }}>
          <div
            className="spo2-baseline-top-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ComplianceHeading className="patient-statistics-heading">
              Spo2
            </ComplianceHeading>
          </div>
          <PatientDaySpo2BaselineDebugsTool
            // <PatientDaySpo2BaselineDebugsTool
            uuid={id}
            date={date}
            theme={theme}
            baselineWindow={baselineWindow}
            tz={tz}
            loading={loadingAllTypeBaseline}
            points={allTypeBaselineMap["spo2"]}
            hasNoData={
              summary === undefined ||
              summary?.spo2 === undefined ||
              summary?.spo2.slots === undefined
            }
            algorithm={4}
            motionMarkerTimestamps={motionMarkerTimestamps}
          />
        </div>
      ) : null} */}
    </div>
  );
}
